import { InputText } from 'primereact/inputtext';
import { memo, useCallback,  useEffect,  useState } from 'react';
import PropTypes from 'prop-types';

import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import useProveedoresFetch from '../../../hooks/useProveedoresFetch';
import { Dropdown } from 'primereact/dropdown';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Checkbox } from 'primereact/checkbox';
import {  quitarTarjetaService, savePersonalProveedorService } from '../../../services/reactivaService';
import { sumaDias } from '../../../services/commonHelpers';
import RegistrarTarjetaDlg from './RegistrarTarjetaDlg';

const PersonalInfo = ({ persona, onClose }) => {
	const [datos, setDatos] = useState({});
	const { proveedores } = useProveedoresFetch({});
	const [openRegistroTarjeta, setOpenRegistroTarjeta]=useState(null);
	const queryClient = useQueryClient();


	useEffect(()=>{
		setDatos({...persona,
			fechaEntrada: persona?.fechaEntrada? new Date(persona?.fechaEntrada):null,
			entradaObra: persona?.entradaObra ?? 0,
			habilitado: persona?.habilitado??0,
			autonomo: persona?.autonomo ??0
		})
	},[persona])

	const changeAttr = useCallback(
		(attr, val) => {
			const newD = { ...datos, nombre: datos?.nombre??persona?.nombre };
			newD[attr] = val;
			if (attr==='fechaEntrada' && val){
				newD.entradaObra=1;
			}
			setDatos(newD);
		},
		[datos, persona?.nombre]
	);

	const savePersona = useCallback(async () => {
		try {
			const resp = await savePersonalProveedorService(datos);
			if (!resp.error) {
				queryClient.invalidateQueries({ queryKey: ['personalProveedor'] });
				toast.success('Datos guardados correctamente');
				if (onClose){
					onClose();
				}
			} else {
				toast.error(resp.mensaje);
			}
		} catch (e) {
			toast.error('Error guardando datos.');
		}
	}, [datos, queryClient, onClose]);

	const guardaFecha = useCallback(async (fecha,tipoEntrada) => {
		try {
			if (fecha!=null){
				fecha.setHours(23,59,59);
			}
			const resp = await savePersonalProveedorService({...datos, 
				fechaEntrada: fecha, 
				tipoEntrada,
				entradaObra: fecha? 1:0 });
			if (!resp.error) {
				queryClient.invalidateQueries({ queryKey: ['personalProveedor'] });
				toast.success('Datos guardados correctamente');
			} else {
				toast.error('Error guardando datos');
			}
		} catch (e) {
			toast.error('Error guardando datos');
		}
	}, [datos, queryClient]);

	const desactivarTarjeta = useCallback(async()=>{
 			const resp = await quitarTarjetaService(datos?.id, datos?.tarjeta);
            if (!resp.error) {
				queryClient.invalidateQueries({ queryKey: ['personalProveedor'] });
				toast.success('Datos guardados correctamente');
			} else {
				toast.error(resp.mensaje);
			}
	},[datos?.id, datos?.tarjeta, queryClient]);

	return (
		<div className='grid formgrid'>
			<div className='field mb-1 col-12'>
				<label for='nombre' className='font-medium text-900 text-xs'>
					Nombre
				</label>
				<InputText
					className='p-inputtext w-full mb-1  p-1 text-sm'
					value={datos?.nombre}
					onChange={(e) => changeAttr('nombre', e.target.value)}
				/>
			</div>
			<div className='field mb-1 col-12'>
				<label for='proveedor' className='font-medium text-900 text-xs'>
					Proveedor
				</label>
				<Dropdown
					value={datos?.proveedor}
					disabled={persona?.id || persona?.proveedor}
					onChange={(e) => changeAttr('proveedor', e.value)}
					options={proveedores?.map((m) => ({ label: m.nombre, value: m.id }))}
					className=' w-full mb-1  p-1 text-sm'
				/>
			</div>
			<div className='field mb-1 col-2'>
				<label for='dni' className='font-medium text-900 text-xs'>
					DNI
				</label>
				<InputText
					className='p-inputtext w-full mb-1  p-1 text-sm'
					value={datos?.dni}
					onChange={(e) => changeAttr('dni', e.target.value)}
				/>
			</div>
			<div className='field mb-1 col-1'>
				<label for='autonomo' className='font-medium text-900 text-xs'>
					Autonomo
				</label>
				<br />
				<Checkbox checked={datos?.autonomo} onChange={(e) => changeAttr('autonomo', e.checked)} />
			</div>
			<div className='field mb-1 col-2'>
				<label for='activo' className='font-medium text-900 text-xs'>
					Activo
				</label>
				<br />
				<Checkbox checked={datos?.habilitado} onChange={(e) => changeAttr('habilitado', e.checked)} />
			</div>

			{persona.id && (
				<div className='field mb-1 col-4'>
					<label for='permisoEntrada' className='font-medium text-900  text-xs'>
						Permiso entrada
					</label>{' '}
					<br />
					<Button
						label='Sin permiso'
						className='mr-2 p-1'
						icon='pi pi-times'
						outlined
						onClick={() => guardaFecha(null,0)}
					/>
					<Button
						label='1 mes'
						className='mr-2 p-1'
						icon='pi pi-calendar'
						outlined
						onClick={() => guardaFecha(sumaDias(new Date(), 30),2)}
					/>
					<Button
						label='1 día'
						className='mr-2 p-1'
						icon='pi pi-clock'
						outlined
						onClick={() => guardaFecha(sumaDias(new Date(), 1),1)}
					/>
					{/* <Dropdown
					value={datos?.tipoEntrada}
					onChange={(e) => changeAttr('tipoEntrada', e.value)}
					options={[
						{ value: 0, label: 'Sin permiso' },
						{ value: 1, label: 'Entrada para 1 DIA' },
						{ value: 2, label: 'Entrada para 1 MES' },
					]}
					className=' w-full mb-1  p-1 text-sm'
				/> */}
				</div>
			)}
			<div className='field mb-1 col-3'>
				<label for='nombre' className='font-medium text-900  text-xs'>
					Fecha
				</label>
				<Calendar
					className=' mb-1 w-full  p-1 text-sm'
					value={datos?.fechaEntrada}
					locale='es'
					clearButton
					disabled={!!persona?.id}
					onChange={(e) => changeAttr('fechaEntrada', e.target.value)}
				/>
			</div>
			{datos?.id && (
				<div className='field mb-1 col-12'>
					<label for='nombre' className='font-medium text-900  text-xs'>
						Fichaje con tarjeta
					</label>
					<Button
						icon='pi pi-credit-card'
						label={!datos?.tarjeta ? 'Sin tarjeta, Registrar tarjeta' : 'Con tarjeta, asignar nueva tarjeta'}
						onClick={() => setOpenRegistroTarjeta(true)}
					/>
					{openRegistroTarjeta && <RegistrarTarjetaDlg personal={datos} onClose={() => setOpenRegistroTarjeta(null)} />}
					{datos?.tarjeta && (
						<Button
							icon='pi pi-credit-card'
							className='ml-4'
							label='Desactivar tarjeta'
							onClick={desactivarTarjeta}
						/>
					)}
				</div>
			)}
			<div className='col-12 align-content-end'>
				<Button label='Guardar' className='text-sm align-items-end' onClick={savePersona} />
			</div>
		</div>
	);
};

PersonalInfo.propTypes = {
	persona: PropTypes.object,
	onClose: PropTypes.func
};
export default memo(PersonalInfo);
