import PropTypes from 'prop-types';

import { memo, useCallback, useMemo, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import usePartesTrabajoFetch from '../../../hooks/usePartesTrabajoFetch';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import ParteTrabajoInfo from '../../partesTrabajo/ParteTrabajoInfo';
import useUsuariosFetch from '../../../hooks/useUsuariosFetch';

const PartesTrabajoObra = ({ obra }) => {
	const { partesTrabajoObra } = usePartesTrabajoFetch({ obra: obra?.id });
    const [preview, setPreview] = useState(null);

	const { usuarios } = useUsuariosFetch({});

    const renderFechaHora = useCallback((row, col) => {
			const fecha = new Date(row[col.field]);
			if (fecha) {
				return `${fecha.toLocaleDateString()}`;
			}
			return '---';
		}, []);

    const datos = useMemo(()=>(
        partesTrabajoObra?.map(po=>({
            ...po,
            trabajadorNombre: usuarios?.find((p) => p.id === po.usuarioCreacion)?.nombre,
        }))
    ),[partesTrabajoObra, usuarios]);

	console.log(datos);

        const renderPreview = useCallback(row=><Button icon='pi pi-file' onClick={()=>setPreview(row)}/>,[])
	return (
		<div className='col-12 p-0 '>
			<div className='col-12'>
				<DataTable
					value={datos}
					size='small'
					className='text-xs p-1'
					paginator
					rows={10}
					scrollable
					scrollHeight='flex'
					stripedRows
					rowsPerPageOptions={[10, 25, 50]}
					paginatorTemplate=' FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown'
					currentPageReportTemplate='{first} de {last} ({totalRecords})'
					selectionMode='single'
				>
					<Column field='trabajadorNombre' header='Trabajador'></Column>
					<Column field='fecha' header='Fecha' body={renderFechaHora}></Column>
					<Column  header='Ver fichero' body={renderPreview}></Column>
				</DataTable>
                {preview && <Dialog visible={true} header='Ver fichero' onHide={()=>setPreview(null)}>
                        <ParteTrabajoInfo selected={preview}/>
                    </Dialog>}
			</div>
		</div>
	);
};

PartesTrabajoObra.propTypes = {
	obra: PropTypes.object,
};
export default memo(PartesTrabajoObra);
