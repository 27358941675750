import AppLayout from '../components/layout/AppLayout';
import { memo, useEffect, useMemo, useState } from 'react';
import AppBreadcrumb from '../components/layout/AppBreadcrumb';
import ProveedoresList from '../components/proveedores/ProveedoresList';
import ProveedorSelected from '../components/proveedores/ProveedorSelected';
import useProveedoresFetch from '../hooks/useProveedoresFetch';
import { useParams } from 'react-router';
const ProveedoresPage = () => {
	const [selected, setSelected] = useState(null);
	const {proveedores} = useProveedoresFetch({});
	const params = useParams();
	
	useEffect(() => {
		if (params?.id) {
			setSelected(proveedores?.find((p) => p.id === parseInt(params?.id, 10)));
		}
	}, [params?.id, proveedores]);

	const items = useMemo(() => {
		const array = [{ label: 'Maestros' }, 
			{ label: 'Proveedores'}
		
		];
		if (selected) {
			array.push({ label: 
			<>
				{selected?.nombre }
			</>});
		}
		return array;
	}, [selected]);
	
	return (
		<AppLayout>
			<div
				className='flex flex-column surface-section p-1 h-full overflow-y-auto 
                overflow-x-hidden
                flex-shrink-0 flex-grow-1 md:flex-grow-0'
				style={{ width: '350px' }}
			>
				<ProveedoresList selected={selected} setSelected={setSelected} />
			</div>
			<div className='min-h-screen flex flex-column relative flex-auto' style={{ width: 'calc(100wv - 500px)' }}>
				<AppBreadcrumb items={items} />
				 {selected && <ProveedorSelected proveedor={selected} key={selected?.id} />} 
			</div>
		</AppLayout>
	);
};

export default memo(ProveedoresPage);
