import { Button } from 'primereact/button';
import AppLayout from '../components/layout/AppLayout';
import useGastoPersonalFetch from '../hooks/useGastoPersonalFetch';
import useObrasFetch from '../hooks/useObrasFetch';
import { useNavigate } from 'react-router';
import useDocumentosFetch from '../hooks/useDocumentosFetch';
import useProveedoresFetch from '../hooks/useProveedoresFetch';
import usePersonalFetch from '../hooks/usePersonalFetch';
import { useMemo } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

const InicioPage = ()=>{
	const {gastosPersonal} = useGastoPersonalFetch({estado: 'pendiente'});
	const {
		estadoDocumentosProveedor,
		isLoadingEstadoDocumentosProveedor,
		estadoDocumentosPersona,
		tipoDocumentos,
		isLoadingEstadoDocumentosPersona,
	} = useDocumentosFetch({});
	const {proveedores} = useProveedoresFetch();
	const { allPersonalProveedor } = usePersonalFetch();
	const { obras } = useObrasFetch({  });
	const navigate = useNavigate();
	const {documentosValidar} =useDocumentosFetch({});


	const datosPersonal = useMemo(() => {
		const out = {};
		estadoDocumentosPersona?.forEach((e) => {
			const persona = allPersonalProveedor?.find((p) => p.id === e.id);
			if (persona){
				if (!out[persona?.nombre]) {
					out[persona?.nombre] ={msgs: [], persona};
				}
				out[persona?.nombre]?.msgs.push(
					<span>
						<strong> {tipoDocumentos?.find((t) => t.id === e.tipoDoc)?.tipo}</strong>: &nbsp;&nbsp; {e.errores}
					</span>
				);
			}
		});
		return out;
	}, [estadoDocumentosPersona, allPersonalProveedor, tipoDocumentos]);
	
	const datosProveedores = useMemo(() => {
		const out = {};
		estadoDocumentosProveedor?.forEach((e) => {
			const proveedor = proveedores?.find((p) => p.id === e.id);
			if (!out[proveedor?.nombre]) {
				out[proveedor?.nombre] = { msgs: [], proveedor };
			}
			out[proveedor?.nombre].msgs.push(
				<span>
					<strong> {tipoDocumentos?.find((t) => t.id === e.tipoDoc)?.tipo}</strong>: &nbsp;&nbsp; {e.errores}
				</span>
			);
		});
		return out;
	}, [estadoDocumentosProveedor, proveedores, tipoDocumentos]);


    return (
			<AppLayout>
				<div className='grid p-4' style={{ maxHeight: '100vh' }}>
					<div className='col-2'>
						<div className='surface-card shadow-2 p-3 border-1 border-50 border-round'>
							<div className='flex justify-content-between mb-3'>
								<div>
									<span className='block text-500 font-medium mb-3'>Gastos pendientes</span>
									<div className='text-900 font-medium text-xl'>{gastosPersonal?.length} para revisar</div>
								</div>
								<div
									className='flex align-items-center justify-content-center bg-blue-100 border-round'
									style={{ width: '40px', height: '40px' }}
								>
									<i className='pi pi-ticket text-blue-500 text-xl'></i>
								</div>
							</div>
							<Button
								label='Validar'
								className='p-1'
								onClick={() => {
									navigate('/gastosPersonal');
								}}
							/>
						</div>
					</div>
					<div className='col-2'>
						<div className='surface-card shadow-2 p-3 border-1 border-50 border-round'>
							<div className='flex justify-content-between mb-3'>
								<div>
									<span className='block text-500 font-medium mb-3'>Docs. revisar</span>
									<div className='text-900 font-medium text-xl'>{documentosValidar?.length} para revisar</div>
								</div>
								<div
									className='flex align-items-center justify-content-center bg-blue-100 border-round'
									style={{ width: '40px', height: '40px' }}
								>
									<i className='pi pi-exclamation-circle text-red-500 text-xl'></i>
								</div>
							</div>
							<Button
								label='Validar'
								className='p-1'
								onClick={() => {
									navigate('/validarDocumentacion');
								}}
							/>
						</div>
					</div>
					<div className='col-2'>
						<div className='surface-card shadow-2 p-3 border-1 border-50 border-round'>
							<div className='flex justify-content-between mb-3'>
								<div>
									<span className='block text-500 font-medium mb-3'>Error doc. prov.</span>
									<div className='text-900 font-medium text-xl'>
										{Object.keys(datosProveedores)?.length} proveedores
									</div>
								</div>
								<div
									className='flex align-items-center justify-content-center bg-blue-100 border-round'
									style={{ width: '40px', height: '40px' }}
								>
									<i className='pi pi-clipboard text-red-500 text-xl'></i>
								</div>
							</div>
							<Button
								label='Revisar'
								className='p-1'
								onClick={() => {
									navigate('/proveedores');
								}}
							/>
						</div>
					</div>
					<div className='col-2'>
						<div className='surface-card shadow-2 p-3 border-1 border-50 border-round'>
							<div className='flex justify-content-between mb-3'>
								<div>
									<span className='block text-500 font-medium mb-3'>Error doc. trab</span>
									<div className='text-900 font-medium text-xl'>{Object.keys(datosPersonal)?.length}</div>
								</div>
								<div
									className='flex align-items-center justify-content-center bg-green-100 border-round'
									style={{ width: '40px', height: '40px' }}
								>
									<i className='pi pi-id-card text-red-500 text-xl'></i>
								</div>
							</div>
							<Button
								label='Revisar'
								className='p-1'
								onClick={() => {
									navigate('/personalObras');
								}}
							/>
						</div>
					</div>
					<div className='col-2'>
						<div className='surface-card shadow-2 p-3 border-1 border-50 border-round'>
							<div className='flex justify-content-between mb-3'>
								<div>
									<span className='block text-500 font-medium mb-3'>Obras</span>
									<div className='text-900 font-medium text-xl'>
										{obras?.filter((o) => !o.fechaFin)?.length} activas
									</div>
								</div>
								<div
									className='flex align-items-center justify-content-center bg-purple-100 border-round'
									style={{ width: '40px', height: '40px' }}
								>
									<i className='pi pi-building text-purple-500 text-xl'></i>
								</div>
							</div>
							<Button
								label='Ver'
								className='p-1'
								onClick={() => {
									navigate('/obras');
								}}
							/>
						</div>
					</div>

					<div className='col-6 vertical-align-top' style={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'scroll' }}>
						<div className='surface-card shadow-2 border-round p-3 vertical-align-top'>
							<div className='text-xl text-900 font-medium mb-5'>Documentación de personal</div>
							{isLoadingEstadoDocumentosPersona && <ProgressSpinner />}
							<ul className='list-none p-0 m-0'>
								{Object.keys(datosPersonal).map((d) => (
									<li className='pb-3 border-bottom-1 surface-border'>
										<div className='font-medium text-900 mb-2'>
											<Button
												icon='pi pi-search'
												className='p-1 mr-1'
												size='small'
												outlined
												onClick={() => navigate(`/personalObras/${datosPersonal[d]?.persona.id}`)}
											/>
											{d} ({proveedores?.find((pp) => pp.id === datosPersonal[d]?.persona?.proveedor)?.nombre})
										</div>
										<div className='line-height-3 text-600'>
											{datosPersonal[d]?.msgs.map((ms) => (
												<li key={ms}>{ms}</li>
											))}
										</div>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className='col-6 vertical-align-top' style={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'scroll' }}>
						<div className='surface-card shadow-2 border-round p-3 vertical-align-top'>
							<div className='text-xl text-900 font-medium mb-5'>Documentación de proveedor</div>
							{isLoadingEstadoDocumentosProveedor && <ProgressSpinner />}
							<ul className='list-none p-0 m-0'>
								{Object.keys(datosProveedores).map((d) => (
									<li className='pb-3 border-bottom-1 surface-border'>
										<div className='font-medium text-900 mb-2'>
											<Button
												icon='pi pi-search'
												className='p-1 mr-1'
												size='small'
												outlined
												onClick={() => navigate(`/proveedores/${datosProveedores[d]?.proveedor.id}`)}
											/>
											{d}
										</div>
										<div className='line-height-3 text-600'>
											{datosProveedores[d].msgs.map((ms) => (
												<li key={ms}>{ms}</li>
											))}
										</div>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</AppLayout>
		);
};

export default InicioPage;