import { memo, useCallback, useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { asignarTarjetaService } from "../../../services/reactivaService";
const RegistrarTarjetaDlg = ({ personal, onClose }) => {
    const [codeCard, setCodeCard]=useState();
    const inputEl = useRef(null);
    const queryClient = useQueryClient();

    useEffect(() => {
        if (inputEl?.current){
            inputEl.current.focus();
        }
    }, []);

    const asignarTarjeta= useCallback(async ()=>{
        if (codeCard){
            const resp = await asignarTarjetaService(personal?.id, codeCard);
            if (!resp.error) {
				queryClient.invalidateQueries({ queryKey: ['personalProveedor'] });
				toast.success('Datos guardados correctamente');
                onClose();
			} else {
				toast.error(resp.mensaje);
			}
        }else{
            toast.error('Debe tener número de tarjeta');
        }
    },[codeCard, personal?.id, queryClient, onClose]);


    const footerContent = (
        <div>
            <Button label='Cancelar' icon='pi pi-times' onClick={onClose} className='p-button-text' />
            <Button label='Guardar' icon='pi pi-check' onClick={() => asignarTarjeta()} autoFocus />
        </div>
    );

	return (
		<Dialog
			header={<h4> Registro de {personal?.nombre} </h4>}
			visible={true}
			style={{ width: '30vw', minHeight: '30vh' }}
			onHide={onClose}
			footer={footerContent}
		>
			<InputText
				autofocus='true'
				value={codeCard}
				onChange={(e) => setCodeCard(e.target.value)}
				ref={inputEl}
                className="w-full"
			/>
		</Dialog>
	);
};
RegistrarTarjetaDlg.propTypes={
    personal: PropTypes.object
}
export default memo(RegistrarTarjetaDlg);