import axios from 'axios';
import md5 from 'md5';
import { WS_URI } from './commonHelpers';

export const loginService = (user, password) => {
	const md5pass = md5(password);
	return axios.get(`${WS_URI}/login?user=${user}&password=${md5pass}`).then((response) => response?.data);
};

export const refreshTokenService = (user) => {
	return axios.get(`${WS_URI}/refreshToken?user=${user}`).then((response) => response?.data);
};

export const getObrasService = ()=>{
	return axios.get(`${WS_URI}/getAllObras`).then((response) => response?.data);
}

export const getObrasByProveedorService = proveedor =>{
	return axios.get(`${WS_URI}/getObrasByProveedor?proveedor=${proveedor}`).then((response) => response?.data);
}

export const saveObraService = obra =>{
	return axios.post(`${WS_URI}/saveObra`,obra).then((response) => response?.data);
}

export const getProveedoresService = () => {
	return axios.get(`${WS_URI}/getAllProveedores`).then((response) => response?.data);
};

export const getProveedoresObraService = obra => {
	return axios.get(`${WS_URI}/getProveedoresByObra?obra=${obra}`).then((response) => response?.data);
};

export const asignaProveedorObraService = (obra, proveedor, asignar) => {
	return axios.get(`${WS_URI}/asignaProveedorObra?obra=${obra}&proveedor=${proveedor}&asignado=${!asignar}`).then((response) => response?.data);
};

export const getSuministradoresService = () => {
	return axios.get(`${WS_URI}/getAllSuministradores`).then((response) => response?.data);
};

export const getSuministradoresObraService = (obra) => {
	return axios.get(`${WS_URI}/getSuministradoresByObra?obra=${obra}`).then((response) => response?.data);
};

export const getMarcasService = () => {
	return axios.get(`${WS_URI}/getMarcas`).then((response) => response?.data);
};

export const getPartesObraByObra = obra=>{
	return axios.get(`${WS_URI}/getPartesTrabajoByObra?obra=${obra}`).then((response) => response?.data);
}

export const getPartesObraByFecha = (fecha) => {
	return axios.get(`${WS_URI}/getPartesTrabajoByFecha?fecha=${fecha}`).then((response) => response?.data);
};

export const getPartesTrabajoByFecha = (fecha) => {
	return axios.get(`${WS_URI}/getDocParteObraDesdeFecha?fecha=${fecha}`).then((response) => response?.data);
};

export const getParteTrabajoByIdService = id =>{
	return axios.get(`${WS_URI}/getDocParteObraByID?id=${id}`).then((response) => response?.data);
}

export const getPartesTrabajoObraService = obra =>{
	return axios.get(`${WS_URI}/getDocParteObraByObra?obra=${obra}`).then((response) => response?.data);
}

export const getPartesObraByProveedor = proveedor =>{
	return axios.get(`${WS_URI}/getPartesTrabajoByProveedor?proveedor=${proveedor}`).then((response) => response?.data);
}

export const getPartesObraByPersona = persona =>{
	return axios.get(`${WS_URI}/getPartesTrabajoByPersona?persona=${persona}`).then((response) => response?.data);
}

export const getPersonalProveedor = () => {
	return axios.get(`${WS_URI}/getAllPersonalObra`).then((response) => response?.data);
};

export const getGastosPersonalInternoService = id=>{
	return axios.get(`${WS_URI}/getGastosByPersonalInterno?id=${id}`).then((response) => response?.data);
}
export const getMovimientosSaldoPersonalService = id=>{
	return axios.get(`${WS_URI}/getMovimientosSaldoByPersona?id=${id}`).then((response) => response?.data);
};

export const saveMovimientoPersonalService = (movimiento) => {
	return axios.post(`${WS_URI}/saveMovimientoSaldo`, movimiento).then((response) => response?.data);
};

export const savePersonalInternoService = (obra) => {
	return axios.post(`${WS_URI}/savePersonalInterno`, obra).then((response) => response?.data);
};

export const savePersonalProveedorService = persona =>{
	return axios.post(`${WS_URI}/savePersonal`, persona).then((response) => response?.data);
}

export const saveParteTrabajoService = parte =>{
	return axios.post(`${WS_URI}/saveParteTrabajo`, parte).then((response) => response?.data);
}

export const deleteParteTrabajoService = parte =>{
	return axios.get(`${WS_URI}/deleteParteTrabajo?id=${parte.id}`, parte).then((response) => response?.data);
}

export const saveProveedorService = proveedor=>{
	return axios.post(`${WS_URI}/saveProveedor`, proveedor).then((response) => response?.data);
}

export const generarPassService = proveedor =>{
	return axios.post(`${WS_URI}/generaPasswordProveedor`, proveedor).then((response) => response?.data);
}

export const saveUsuarioService = usuario =>{
	return axios.post(`${WS_URI}/saveUsuario`, usuario).then((response) => response?.data);
}

export const getGastosPersonalService = estado=>{
	return axios.get(`${WS_URI}/getGastosByEstado?estado=${estado}`).then((response) => response?.data);
};

export const getTiposGastosService = ()=>{
	return axios.get(`${WS_URI}/getTiposGastos`).then((response) => response?.data);
}

export const getGastoPersonalInfoService = (id) => {
	return axios.get(`${WS_URI}/getGastoPersonalInfo?id=${id}`).then((response) => response?.data);
};

export const saveGastoService = gasto =>{
	return axios.post(`${WS_URI}/saveGastoPersonal`, gasto).then((response) => response?.data);
}

export const getAllPersonalInterno = () => {
	return axios.get(`${WS_URI}/getAllPersonalInterno`).then((response) => {
		return response?.data;
	});
};

export const getAllUsuarios = () => {
	return axios.get(`${WS_URI}/getAllUsuarios`).then((response) => response?.data);
};

export const getAllRoles = () => {
	return axios.get(`${WS_URI}/getRoles`).then((response) => response?.data);
};

export const getObrasByUsuario = id =>{
	return axios.get(`${WS_URI}/getObrasAsignadasByUsuario?id=${id}`).then((response) => response?.data);
}

export const getObrasByPersonalInterno = id=>{
	return axios.get(`${WS_URI}/getObrasAsignadasPersonalInterno?id=${id}`).then((response) => response?.data);
}

export const asignaUsuarioObraService = (obra, usuario, asignado) => {
	return axios
		.get(`${WS_URI}/asignaUsuarioObra?usuario=${usuario}&obra=${obra}&asignado=${asignado}`)
		.then((response) => response?.data);
};

export const getDocumentosEmpresa = id =>{
	return axios
		.get(`${WS_URI}/getDocumentosEmpresa?prov=${id}`)
		.then((response) => response?.data);
}

export const getDocumentosPersona = (id) => {
	return axios.get(`${WS_URI}/getDocumentosPersona?persona=${id}`).then((response) => response?.data);
};

export const getEstadoDocumentosPersona=()=>{
	return axios.get(`${WS_URI}/getEstadoAllDocumentosPersona`).then((response) => response?.data);
}


export const getEstadoDocumentosProveedor=()=>{
	return axios.get(`${WS_URI}/getEstadoAllDocumentosProveedor`).then((response) => response?.data);
}

export const getTipoDocumentos = ()=>{
	return axios.get(`${WS_URI}/getTipoDocumentos`).then((response) => response?.data);
}

export const getDocumentosValidarPendientesService = ()=>{
	return axios.get(`${WS_URI}/getDocumentosPendientesValidar`).then((response) => response?.data);
}

export const getDocumentosValidarValidadosService = () => {
	return axios.get(`${WS_URI}/getDocumentosValidados`).then((response) => response?.data);
};

export const getDocumentosValidarRechazadosService = () => {
	return axios.get(`${WS_URI}/getDocumentosRechazados`).then((response) => response?.data);
};


export const getDocumentoValidarService = id =>{
	return axios.get(`${WS_URI}/getDocumentoPendienteValidarByID?id=${id}`).then((response) => response?.data);
}

export const getDocumento = id=>{
	return axios.get(`${WS_URI}/getDocumentoByID?documento=${id}`).then((response) => response?.data);
}

export const saveDocumentoInterno = fichero =>{
	return axios.post(`${WS_URI}/subeDocumentoInterno`,fichero).then((response) => response?.data);
}

export const validarDocumentoService = fichero =>{
	return axios.post(`${WS_URI}/validarDocumento`, fichero).then((response) => response?.data);
}

export const rechazarDocumentoService = (fichero) => {
	return axios.post(`${WS_URI}/rechazarDocumento`, fichero).then((response) => response?.data);
};

export const asignarTarjetaService = (persona, tarjeta)=>{
	return axios.get(`${WS_URI}/asignarTarjeta?persona=${persona}&tarjeta=${tarjeta}`).then((response) => response?.data);
}

export const quitarTarjetaService = (persona, tarjeta) => {
	return axios.get(`${WS_URI}/quitarTarjeta?persona=${persona}&tarjeta=${tarjeta}`).then((response) => response?.data);
};