
import { memo, useCallback,  useContext,  useEffect,  useMemo,  useState } from 'react';
import useObrasFetch from '../../hooks/useObrasFetch';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import useGastoPersonalFetch from '../../hooks/useGastoPersonalFetch';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { saveGastoService } from '../../services/reactivaService';
import GastoPersonalImagen from './GastoPersonalImagen';
import useUsuariosFetch from '../../hooks/useUsuariosFetch';
import AppContext from '../../contexts/AppContext';
const GastoPersonalInfo = ({ selected }) => {
	const [datos, setDatos]= useState({...selected});
    const {obras}= useObrasFetch({});
    const {tiposGastosPersonal}=useGastoPersonalFetch({estado: selected?.estado})
	const queryClient = useQueryClient();
	const { obrasPersonalInterno } = useUsuariosFetch({ idPersonal: selected?.persona });

	const {user} = useContext(AppContext);

    useEffect(()=>{
        setDatos({ ...selected });
    },[selected, selected.id]);

    const changeAttr = useCallback((attr, val)=>{
        const newD={...datos};
        newD[attr]=val;
        setDatos(newD);
    },[datos]);

	const obrasF = useMemo(
		() => obras?.filter((o) => obrasPersonalInterno?.find((op) => op.obra === o.id)),
		[obras, obrasPersonalInterno]
	);

	const saveGasto = useCallback(async ()=>{
		try{
			const resp = await saveGastoService({...datos, usuarioValidacion: user?.id, fechaValidacion: new Date()});
			if (!resp.error){
				queryClient.invalidateQueries({ queryKey: ['gastosPersonal'] });
                queryClient.invalidateQueries({ queryKey: ['gastoPersonalInfo'] });
				toast.success('Datos guardados correctamente');
			}else{
				toast.error('Error guardando datos');
			}
		}catch(e){
			toast.error('Error guardando datos');
		}
	},[datos, queryClient, user?.id]);	

    return (
			<>
				<div className='grid formgrid m-2  surface-card border-round-sm'>
					<div className='field mb-1 col-6'>
						<label for='nombre' className='font-medium text-900 text-xs'>
							Nombre
						</label>
						<InputText className='p-inputtext w-full mb-1  p-1 text-sm' value={datos?.trabajadorNombre} disabled />
					</div>
					<div className='field mb-1 col-6'>
						<label for='nombre' className='font-medium text-900 text-xs'>
							Obra
						</label>
						<Dropdown
							value={datos?.obra}
							onChange={(e) => changeAttr('obra', e.value)}
							options={obrasF?.map((m) => ({ label: m.nombre, value: m.id }))}
							className=' w-full mb-1  p-0 text-sm'
							panelClassName='text-sm'
							filter
						/>
					</div>
					<div className='field mb-1 col-12'>
						<label for='nombre' className='font-medium text-900 text-xs'>
							Proveedor
						</label>
						<InputText
							className='p-inputtext w-full mb-1  p-1 text-sm'
							value={datos?.proveedor}
							onChange={(e) => changeAttr('proveedor', e.target.value)}
						/>
					</div>
					<div className='field mb-1 col-6'>
						<label for='nombre' className='font-medium text-900 text-xs'>
							Importe
						</label>
						<InputNumber
							className='w-full mb-1  p-0 text-sm'
							locale='es-ES'
							minFractionDigits={2}
							value={datos?.importe}
							onChange={(e) => changeAttr('importe', e.value)}
						/>
					</div>
					<div className='field mb-1 col-6'>
						<label for='nombre' className='font-medium text-900  text-xs'>
							Tipo
						</label>
						<Dropdown
							value={datos?.tipoGasto}
							onChange={(e) => changeAttr('tipoGasto', e.value)}
							options={tiposGastosPersonal?.map((m) => ({ label: m.nombre, value: m.id }))}
							className=' w-full mb-1  p-0 text-sm'
							panelClassName='text-sm'
						/>
					</div>
					<div className='field mb-1 col-6'>
						<label for='nombre' className='font-medium text-900  text-xs'>
							Documento
						</label>
						<Dropdown
							value={datos?.tipoDocumento}
							onChange={(e) => changeAttr('tipoDocumento', e.value)}
							options={[
								{ label: 'Ticket', value: 'TICKET' },
								{ label: 'Albarán', value: 'ALBARAN' },
								{ label: 'Factura', value: 'FACTURA' },
							]}
							className=' w-full mb-1  p-0 text-sm'
							panelClassName='text-sm'
						/>
					</div>
					<div className='field mb-1 col-6'>
						<label for='nombre' className='font-medium text-900  text-xs'>
							Estado validación
						</label>
						<Dropdown
							value={datos?.estado}
							onChange={(e) => changeAttr('estado', e.value)}
							options={[
								{ label: 'Pendiente', value: 'pendiente' },
								{ label: 'Validado', value: 'validado' },
								{ label: 'Denegado', value: 'denegado' },
							]}
							className=' w-full mb-1  p-0 text-sm'
							panelClassName='text-sm'
						/>
					</div>
					<div className='field mb-1 col-12'>
						<label for='nombre' className='font-medium text-900 text-xs'>
							Observaciones
						</label>
						<InputText
							className='p-inputtext w-full mb-1  p-1 text-sm'
							value={datos?.observaciones}
							onChange={(e) => changeAttr('observaciones', e.target.value)}
						/>
					</div>
					<div className='col-12 align-content-end'>
						<Button label='Guardar' className='text-sm align-items-end' onClick={saveGasto} />
					</div>
				</div>
				<GastoPersonalImagen selected={selected} />
			</>
		);
    };

export default memo(GastoPersonalInfo);
