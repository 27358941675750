import PropTypes from 'prop-types';

import { memo } from 'react';
import usePartesObraFetch from '../../../hooks/usePartesObraFetch';
import TablaPartesObra from '../../partesObra/TablaPartesObra';
import NuevoParteObraButton from '../../partesObra/NuevoParteObraButton';

const PartesObraObra = ({ obra }) => {
	const { partesObra } = usePartesObraFetch({ obra: obra.id });
	

	
	return (
		<div className='col-12 p-0 '>
			<NuevoParteObraButton obra={obra?.id}/>
			<TablaPartesObra partesObra={partesObra} filtroPersona filtroProveedor altura={350} filtroFecha/>
		</div>
	);
};

PartesObraObra.propTypes = {
	obra: PropTypes.object,
};
export default memo(PartesObraObra);
