import { InputText } from 'primereact/inputtext';
import { memo, useCallback, useState, useMemo, useEffect} from 'react';
import PropTypes from 'prop-types';

import useUsuariosFetch from '../../../../hooks/useUsuariosFetch';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Message } from 'primereact/message';
import { Dropdown } from 'primereact/dropdown';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { saveUsuarioService } from '../../../../services/reactivaService';
import md5 from 'md5';

const DatosAccesoPersonalInterno = ({ persona }) => {
	const {usuarios,roles } = useUsuariosFetch({ idPersonal: persona?.id });
	
	const miUsuario = useMemo(() => usuarios?.find((u) => u.personainterno === persona?.id), [persona?.id, usuarios]);

	const [datos, setDatos] = useState();

	const queryClient = useQueryClient();

	const perfiles = useMemo(() => [
		// {
		// 	label: 'Administrador',
		// 	value: 'administrador',
		// },
		{
			label: 'Jefe de obra',
			value: 'JEFE_OBRA',
		},
		{
			label: 'Director técnico',
			value: 'DIRECTOR_TECNICO',
		},
		{
			label: 'Gerencia',
			value: 'GERENCIA',
		},
		{
			label: 'Administración',
			value: 'ADMINISTRACION',
		},
	],[]);

	useEffect(()=>{
		setDatos(miUsuario);
	},[miUsuario])

	const changeAttr = useCallback(
		(attr, val) => {
			const newD = { ...datos };
			newD[attr] = val;
			setDatos(newD);
		},
		[datos]
	);

	const saveUsuario = useCallback(async () => {
		try {
			const resp = await saveUsuarioService({
				...datos,
				nombre: datos?.nombre ?? persona?.nombre,
				personalInterno: persona?.id,
				userPassword:
					datos?.userPassword && datos?.userPassword?.toUpperCase() !== 'SI' ? md5(datos?.userPassword) : null,
			});
			if (!resp.error) {
				queryClient.invalidateQueries({ queryKey: ['usuarios'] });
				toast.success('Datos guardados correctamente');
			} else {
				toast.error('Error guardando datos');
			}
		} catch (e) {
			toast.error('Error guardando datos');
		}
	}, [datos, persona?.id, persona?.nombre, queryClient]);

	return (
		<div className='grid formgrid'>
			<div className='field mb-1 col-12'>
				{!miUsuario && (
					<Message
						severity='info'
						text='No tiene usuario de acceso, si guardas estos datos se creará un usuario'
						className='w-full p-2'
					/>
				)}
				<label for='nombre' className='font-medium text-900 text-xs'>
					Username *
				</label>
				<InputText
					className='p-inputtext w-full mb-1  p-1 text-sm'
					value={datos?.userName}
					onChange={(e) => changeAttr('userName', e.target.value)}
				/>
			</div>
			<div className='field mb-1 col-6'>
				<label for='nombre' className='font-medium text-900 text-xs'>
					Password
				</label>
				<InputText
					className='p-inputtext w-full mb-1  p-1 text-sm'
					value={datos?.userPassword}
					placeholder='Solo escribir para cambiarla'
					onChange={(e) => changeAttr('userPassword', e.target.value)}
				/>
			</div>
			<div className='field mb-1 col-6'>
				<label for='nombre' className='font-medium text-900 text-xs'>
					Rol *
				</label>
				<Dropdown
					value={datos?.rol}
					onChange={(e) => changeAttr('rol', e.value)}
					options={roles?.map((m) => ({ label: m.nombre, value: m.id }))}
					className=' w-full mb-1  p-0 text-sm'
					panelClassName='text-sm'
					filter
				/>
			</div>
			<div className='field mb-1 col-6'>
				<label for='nombre' className='font-medium text-900 text-xs'>
					Perfil pedidos *
				</label>
				<Dropdown
					value={datos?.perfilpedidos}
					onChange={(e) => changeAttr('perfilpedidos', e.value)}
					options={perfiles?.map((m) => ({ label: m.label, value: m.value }))}
					className=' w-full mb-1  p-0 text-sm'
					panelClassName='text-sm'
					filter
				/>
			</div>
			<div className='field mb-1 col-6'>
				<label for='nombre' className='font-medium text-900 text-xs'>
					Activo acceso apps
				</label>
				<br />
				<Checkbox checked={datos?.habilitado} onChange={(e) => changeAttr('habilitado', e.checked)} />
			</div>

			<div className='col-12 align-content-end'>
				<Button label='Guardar' className='text-sm align-items-end' onClick={saveUsuario} />
			</div>
		</div>
	);
};

DatosAccesoPersonalInterno.propTypes = {
	persona: PropTypes.object,
};
export default memo(DatosAccesoPersonalInterno);
