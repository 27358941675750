import { DataTable } from "primereact/datatable";

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';

import DialogPreviewDocumento from './DialogPreviewDocumento';
import { memo, useCallback, useState } from "react";

const TablaTipoDocumento = ({docs})=>{
    
	const [opened, setOpened]= useState(null);
    const renderBotones = useCallback(
		(rowData) => (
			<>
				<Button icon='pi pi-info-circle' severity='info' className='mr-2'
					 onClick={()=>{setOpened(rowData?.id)}}/>
				{/* <Button icon='pi pi-trash' onClick={() => borraSuministradorObra(rowData)} severity='danger' /> */}
			</>
		),
		[]
	);

	const renderFechaHora = useCallback((row, col) => {
		const fecha = row[col.field];
		if (fecha) {
			return `${new Date(fecha).toLocaleDateString()} `;
		}
		return '---';
	}, []);

	const renderBoolean = useCallback(
		(row, col) => {
			const tipo = row[col.field];
			return tipo ? (
				<span>
					<i className='pi pi-warning  mr-2' />
					Si
				</span>
			) : (
				<span>
					<i className='pi pi-check mr-2' />
					No
				</span>
			);
		},
		[]
	);

	const rowClassName= useCallback(r=>{
		if (r.caducado){
			return 'bg-red-200';
		}
		return null;
	},[]);

    return (
			<>
				<DataTable
					value={docs}
					tableStyle={{ minWidth: '50rem' }}
					size='small'
					className='text-sm p-1'
					paginator
					rows={5}
					stripedRows
					rowClassName={rowClassName}
					rowsPerPageOptions={[5, 10, 25, 50]}
					paginatorTemplate=' FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown'
					currentPageReportTemplate='{first} de {last} ({totalRecords})'
				>
					<Column field='tipoDocNombre' header='Documento'></Column>
					<Column field='fecha' header='Fecha' body={renderFechaHora}></Column>
					<Column field='caducidad' header='Fecha Caducidad' body={renderFechaHora}></Column>
					<Column field='caducado' header='Caducado' body={renderBoolean}></Column>
					<Column field='descripcion' header='Descripcion'></Column>
					<Column body={renderBotones}></Column>
				</DataTable>
				<DialogPreviewDocumento fichero={opened} setOpened={setOpened} />
			</>
		);
};

export default memo(TablaTipoDocumento);