import { TabPanel, TabView } from "primereact/tabview";
import { memo, useMemo } from "react";
import useProveedoresFetch from "../../hooks/useProveedoresFetch";
import ProveedorInfo from "./selected/ProveedorInfo";
import ObrasProveedor from "./selected/ObrasProveedor";
import PersonalProveedor from "./selected/PersonalProveedor";
import PartesProveedor from "./selected/PartesProveedor";
import DocumentosProveedor from "./selected/DocumentosProveedor";
const ProveedorSelected = ({proveedor})=>{
	const {proveedores}= useProveedoresFetch({});

	const lastProveedor = useMemo(() => proveedores?.find((o) => o.id === proveedor?.id), [proveedores, proveedor]);
    return (
			<div className='col-12'>
				<TabView>
					<TabPanel header={<span className='text-xs'>Información</span>}>
						<ProveedorInfo proveedor={lastProveedor} />
					</TabPanel>
					<TabPanel header={<span className='text-xs'>Obras</span>}>
						<ObrasProveedor proveedor={lastProveedor} />
					</TabPanel>
					{/* <TabPanel header={<span className='text-xs'>Suministradores</span>}>
						<SuministradoresObra obra={obra} />
					</TabPanel> */}
					<TabPanel header={<span className='text-xs'>Personal </span>}>
						<PersonalProveedor proveedor={lastProveedor} />
					</TabPanel>
					<TabPanel header={<span className='text-xs'>Documentación </span>}>
						<DocumentosProveedor proveedor={lastProveedor} />
					</TabPanel>
					<TabPanel header={<span className='text-xs'>Partes Trabajo </span>}>
						<PartesProveedor proveedor={lastProveedor} />
					</TabPanel>
					{/* <TabPanel header={<span className='text-xs'>Albaranes</span>}></TabPanel>
					<TabPanel header={<span className='text-xs'>Alquileres</span>}></TabPanel> */}
				</TabView>
			</div>
		);
}

export default memo(ProveedorSelected);