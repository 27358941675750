import { TabPanel, TabView } from 'primereact/tabview';
import { memo, useMemo } from 'react';
import usePersonalFetch from '../../hooks/usePersonalFetch';
import PersonalInfo from './selected/PersonalInfo';
import PersonalPartes from './selected/PersonalPartes';
import PersonalDocumentos from './selected/PersonalDocumentos';
const PersonalSelected = ({ persona,onClose }) => {
	const { allPersonalProveedor } = usePersonalFetch({});

	const lastPersona = useMemo(() => {
		return allPersonalProveedor?.find((o) => o.id === persona?.id);
	}, [allPersonalProveedor, persona]);
	
	return (
		<div className='col-12'>
			<TabView>
				<TabPanel header={<span className='text-xs'>Información</span>}>
					<PersonalInfo persona={lastPersona??persona} onClose={onClose} />
				</TabPanel>
				{persona?.id && <TabPanel header={<span className='text-xs'>Documentación</span>}>
					<PersonalDocumentos persona={lastPersona}/>
				</TabPanel>}
				{persona?.id && <TabPanel header={<span className='text-xs'>Partes de trabajo </span>}>                   
                   <PersonalPartes persona={lastPersona}/>
                </TabPanel>}
			</TabView>
		</div>
	);
};

export default memo(PersonalSelected);
