import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { memo, useCallback, useMemo } from "react";
import { useWizard } from "react-use-wizard";
import useDocumentosFetch from "../../../hooks/useDocumentosFetch";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import PropTypes from 'prop-types';
import PdfViewer from "../PdfViewer";
const InformacionFicheroStep = ({
	fichero,
	setFicheros,
	ficheros,
	proveedor,
	persona,
	personaInterno,
	guardarFicheros,
}) => {
	const { nextStep, previousStep, isLastStep } = useWizard();
	const { tipoDocumentos } = useDocumentosFetch({});

	const tiposValidos = useMemo(
		() =>
			tipoDocumentos?.filter((t) => {
				if (proveedor) {
					return t.pertenece === 'PROVEEDOR';
				} else if (persona) {
					return t.pertenece === 'PERSONAL';
				} else if (personaInterno) {
					return t.pertenece === 'PERSONALINTERNO';
				}
				return null;
			}),
		[persona, personaInterno, proveedor, tipoDocumentos]
	);

	const caducidad = useMemo(
		() => tiposValidos?.find((t) => t.id === fichero?.tipo)?.caducidad,
		[fichero?.tipo, tiposValidos]
	);

	const valido = useMemo(
		() => fichero?.tipo && fichero?.fecha && (caducidad ? fichero?.caducidad : true),
		[caducidad, fichero?.caducidad, fichero?.fecha, fichero?.tipo]
	);

	const changeAttr = useCallback(
		(attr, val) => {
			const newD = { ...fichero };
			newD[attr] = val;
			setFicheros(
				ficheros?.map((f) => {
					if (f.path === fichero.path) {
						return newD;
					}
					return { ...f };
				})
			);
		},
		[fichero, setFicheros, ficheros]
	);

	return (
		<div className='col-12'>
			<div className='grid'>
				<div className='col-12'>
					<Button label='Paso anterior' onClick={() => previousStep()} className='mr-3' />
					{!isLastStep && <Button label='Siguiente paso' disabled={!valido} onClick={() => nextStep()} />}
					{isLastStep && <Button label='Guardar todo' disabled={!valido} onClick={guardarFicheros} />}
					<hr />
				</div>

				<div className='col-4'>
					<div className='grid formgrid'>
						<div className='field mb-1 col-12'>
							<label for='nombre' className='font-medium text-900 text-xs'>
								Tipo Documento
							</label>
							<Dropdown
								value={fichero?.tipo}
								onChange={(e) => changeAttr('tipo', e.value)}
								options={tiposValidos?.map((m) => ({ label: m.tipo, value: m.id }))}
								className=' w-full mb-1  p-1 text-sm'
								filter
							/>
						</div>
						<div className='field mb-1 col-12'>
							<label for='nombre' className='font-medium text-900 text-xs'>
								Fecha Documento
							</label>
							<Calendar
								className='w-full mb-1  p-1 text-sm'
								value={fichero?.fecha}
								locale='es'
								onChange={(e) => changeAttr('fecha', e.target.value)}
							/>
						</div>
						{caducidad && (
							<div className='field mb-1 col-12'>
								<label for='nombre' className='font-medium text-900 text-xs'>
									Fecha caducidad
								</label>
								<Calendar
									className='w-full mb-1  p-1 text-sm'
									value={fichero?.caducidad}
									locale='es'
									onChange={(e) => changeAttr('caducidad', e.target.value)}
								/>
							</div>
						)}
						<div className='field mb-1 col-12'>
							<label for='nombre' className='font-medium text-900 text-xs'>
								Descripción
							</label>
							<InputTextarea
								className='p-inputtext w-full mb-1 p-1 text-xs'
								value={fichero?.descripcion}
								onChange={(e) => changeAttr('descripcion', e.target.value)}
							/>
						</div>
					</div>
				</div>
				<div className='col-8'>
					{fichero?.base64 && fichero?.path?.includes('pdf') && (
						// <embed
						// 	src={`data:application/pdf;base64,${fichero?.base64}`}
						// 	type='application/pdf'
						// 	height='100%'
						// 	width='100%'
						// ></embed>
						<PdfViewer base64={`data:application/pdf;base64,${fichero?.base64}`} />
					)}
					{!fichero?.path?.includes('pdf') && (
						<img src={`data:image/png;base64, ${fichero?.base64}`} height='100%' width='100%' alt='Fichero' />
					)}
				</div>
			</div>
		</div>
	);
};
InformacionFicheroStep.propTypes = {
	fichero: PropTypes.object,
	setFicheros: PropTypes.func,
	ficheros: PropTypes.array,
};


export default memo(InformacionFicheroStep);