import { memo, useEffect, useMemo, useRef } from "react";
import PropTypes from 'prop-types';
import useDocumentosFetch from "../../hooks/useDocumentosFetch";
import { Button } from "primereact/button";


const ProveedorCard = ({proveedor, setSelected, selected})=>{
	const ref= useRef();
	const { estadoDocumentosProveedor, tipoDocumentos } = useDocumentosFetch({});
    const cardStyle = useMemo(() => {
			const style = !proveedor.habilitado ? 'surface-200' : 'surface-card';
			if (selected?.id === proveedor?.id) {
				return `${style} border-blue-700 border-2`;
			}
			return style;
		}, [proveedor.habilitado, proveedor?.id, selected?.id]);

    const cardIcon = useMemo(() => (!proveedor.habilitado ? 'pi pi-lock' : 'pi pi-clipboard'), [proveedor]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const malito = useMemo(() => (
		estadoDocumentosProveedor?.find(e=>e?.id===proveedor?.id)
	),[estadoDocumentosProveedor, proveedor]);

	const malitoMotivo = useMemo(
		() =>
			estadoDocumentosProveedor
				?.filter((e) => e?.id === proveedor?.id)
				?.map((e) => (`${tipoDocumentos?.find((t) => t.id === e.tipoDoc)?.tipo}: ${e.errores}`)).join('\n'),
		[estadoDocumentosProveedor, proveedor?.id, tipoDocumentos]
	);

	const iconColor = useMemo(() => {
		if (!proveedor.habilitado) {
			return 'bg-bluegray-100';
		}
		return malito? 'bg-red-200' : 'bg-green-200';
	}, [malito, proveedor.habilitado]);

	useEffect(()=>{
		if (selected?.id === proveedor?.id) {
			ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	},[proveedor?.id, selected?.id]);

    return (
			<div className='col-12' onClick={() => setSelected(proveedor)} ref={ref}>
				<div className={`p-3 border-round shadow-2 flex align-items-center ${cardStyle}`}>
					<div
						className={`${iconColor} inline-flex align-items-center justify-content-center mr-3`}
						style={{ width: '48px', height: '48px', borderRadius: '10px' }}
					>
						<i className={`pi ${cardIcon} text-blue-600 text-xl`}></i>
					</div>
					<div>
						<span className='text-900 text-base font-medium mb-2'>{proveedor.nombre}</span>
						{/* <p className='mt-1 mb-0 text-600 font-medium text-sm'>{obra.marcaNombre}</p> */}
					</div>
					<div className='ml-auto'>
						{malito && <Button tooltip={malitoMotivo} severity='danger' outlined icon='pi pi-exclamation-triangle' />}
					</div>
				</div>
			</div>
		);
};

ProveedorCard.propTypes={
    proveedor: PropTypes.object,
    selected: PropTypes.object,
    setSelected: PropTypes.func
}

export default memo(ProveedorCard);