import { memo, useMemo, useState } from "react";
import AppBreadcrumb from "../../components/layout/AppBreadcrumb";
import AppLayout from "../../components/layout/AppLayout";
import GastoPersonalTabla from "../../components/gastosPersonal/GastoPersonalTabla";
import GastoPersonalInfo from '../../components/gastosPersonal/GastoPersonalInfo';

const GastosPersonalPage =()=>{
    const [selected, setSelected] = useState(null);
   
    const items = useMemo(() => {
        const array = [{ label: 'Desplaza' }, { label: 'Gastos Personal' }];
        if (selected) {
            array.push({
							label: `${selected?.trabajadorNombre} ${selected?.obraNombre} ${selected?.fecha?.toLocaleDateString()}`,
						});
        }
        return array;
    }, [selected]);
    return (
			<AppLayout>
				<div
					className='flex flex-column surface-section p-1 h-full overflow-y-auto 
                    overflow-x-hidden
                    flex-shrink-0 flex-grow-1 md:flex-grow-0'
					style={{ width: '40%' }}
				>
					<GastoPersonalTabla selected={selected} setSelected={setSelected} />
				</div>
				<div className='min-h-screen flex flex-column relative flex-auto' style={{ width: '60%' }}>
					<AppBreadcrumb items={items} />
					<div style={{ width: '100%', height: 'calc(100vh - 60px)', overflowY: 'scroll' }}>
						{selected?.id && <GastoPersonalInfo selected={selected} key={selected?.id}/>}						
					</div>
				</div>
			</AppLayout>
		);
    
};

export default memo(GastosPersonalPage);