import MenuItem from "./MenuItem";
import {memo, useMemo, useRef} from 'react';
import { Avatar } from 'primereact/avatar';
import { OverlayPanel } from 'primereact/overlaypanel';
import obras from '../../assets/obras.svg';
import { useAuthUser } from "../../hooks/useAuthUser";
import { Button } from "primereact/button";

const MenuBar = ()=>{
	const {user, logout} = useAuthUser();
	const ref = useRef();
    const items = useMemo(
			() => [
				{
					label: 'Inicio',
					icon: 'pi pi-home',
					url: '/',
				},
				{
					label: 'Validación documentos',
					icon: 'pi pi-file-check',
					url: '/validarDocumentacion',
				},
				{
					label: 'Maestros',
					icon: 'pi pi-briefcase',
					subitems: [
						{
							label: 'Obras',
							icon: 'pi pi-building',
							url: '/obras',
						},
						{
							label: 'Proveedores',
							icon: 'pi pi-clipboard',
							url: '/proveedores',
						},
						{
							label: 'Personal interno, usuarios y permisos',
							icon: 'pi pi-user',
							url: '/personalInterno',
						},
					],
				},
				{
					label: 'Obras',
					icon: 'pi pi-building',
					subitems: [
						{
							label: 'Partes diarios',
							icon: 'pi pi-briefcase',
							url: '/partesObra',
						},
						// 		{
						// 			label: 'Albaranes',
						// 			icon: 'pi pi-shopping-bag',
						// 		},
						// 		{
						// 			label: 'Alquileres',
						// 			icon: 'pi pi-hammer',
						// 		},
						{
							label: 'Personal obras',
							icon: 'pi  pi-id-card',
							url: '/personalObras',
						},
						{
							label: 'Partes de trabajo',
							icon: 'pi  pi-images',
							url: '/partesTrabajo',
						},
						// 		{
						// 			label: 'Suministradores',
						// 			icon: 'pi pi-truck',
						// 		},
					],
				},
				{
					label: 'Desplaza',
					icon: 'pi pi-car',
					subitems: [
						{
							label: 'Gastos personal',
							icon: 'pi pi-ticket',
							url: '/gastosPersonal',
						},
					],
				},
				// {
				// 	label: 'Informes',
				// 	icon: 'pi pi-chart-line',
				// },
			],
			[]
		);

    return (
			<div
				id='app-sidebar'
				className='h-full lg:h-auto hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border w-full md:w-auto'
			>
				<div className='flex h-full'>
					<div className='flex flex-column h-full bg-indigo-500 flex-shrink-0 select-none'>
						<div
							className='flex align-items-center justify-content-center flex-shrink-0 bg-indigo-600'
							style={{ height: '60px' }}
						>
							<img src={obras} alt='Obras' height='30' />
						</div>
						<div className='overflow-y-auto mt-3'>
							<ul className='list-none py-3 px-2 m-0'>
								{items?.map((i) => (
									<MenuItem item={i} key={i?.label} />
								))}
							</ul>
						</div>
						<div className='mt-auto'>
							<hr className='mb-3 mx-3 border-top-1 border-none border-indigo-300' />
							<div
								pripple=''
								className='p-ripple p-element m-3 flex align-items-center cursor-pointer py-1 px-0 justify-content-center hover:bg-indigo-400 border-round text-300 hover:text-0 transition-duration-150 transition-colors'
							>
								<Avatar
									label={user?.nombre?.substring(0, 1)}
									// size='large'
									style={{ backgroundColor: '#2196F3', color: '#ffffff' }}
									shape='circle'
									onClick={(e) => ref.current.toggle(e)}
								/>
								<OverlayPanel ref={ref} appendTo={document.body}>
									<p className='text-s'>
										<i className='pi pi-user mr-3' />
										{user?.nombre}
										<Button
											icon='pi pi-power-off'
											rounded
											
											
											severity='danger'
											size={'small'}
											className='p-3'
											aria-label='Bookmark'
											onClick={logout}
										/>
									</p>
								</OverlayPanel>
								<span className='p-ink'></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
};

export default memo(MenuBar);