import PropTypes from 'prop-types';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import { memo, useCallback, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import usePartesTrabajoFetch from '../../hooks/usePartesTrabajoFetch';
import PdfViewer from '../documentos/PdfViewer';

const ParteTrabajoImagen = ({ selected }) => {
	const { parteTrabajo, isLoadingParteTrabajo } = usePartesTrabajoFetch({ id: selected?.id });
	const [isPdf, setIsPdf] = useState(false);
	const [rotate, setRotate] = useState(0);

	useEffect(() => {
		setIsPdf(false);
	}, [selected?.id]);

	const errorOnImage = useCallback(() => {
		setIsPdf(true);
	}, []);

	const rotar = useCallback(() => {
		setRotate(rotate + 90);
	}, [rotate]);

	return (
		<div className='col-12 p-2 ' style={{ height: '100vh' }}>
			{isLoadingParteTrabajo && <ProgressSpinner />}
			{isPdf && parteTrabajo?.documento && !isLoadingParteTrabajo && (
				<PdfViewer base64={`data:application/pdf;base64,${parteTrabajo?.documento}`} />
				// <embed
				// 	src={`data:application/pdf;base64,${parteTrabajo?.documento}`}
				// 	type='application/pdf'
				// 	height='100%'
				// 	width='100%'
				// ></embed>
			)}
			{!isPdf && !isLoadingParteTrabajo && (
				<>
					<Button onClick={rotar} icon='pi pi-refresh' />
					<img
						src={
							parteTrabajo?.imagen?.includes('data:image')
								? parteTrabajo?.documento
								: `data:image/jpeg;base64,${parteTrabajo?.documento}`
						}
						height='100%'
						width='100%'
						alt='Imagen'
						style={{ transform: `rotate(${rotate}deg)` }}
						onError={errorOnImage}
					/>
				</>
			)}
			{!isLoadingParteTrabajo && !parteTrabajo?.documento && <Message text='Parte sin imagen' className='w-full' />}
		</div>
	);
};
ParteTrabajoImagen.propTypes = {
	selected: PropTypes.object,
};
export default memo(ParteTrabajoImagen);
