import { useCallback, useContext, useMemo, useState } from "react";
import useProveedoresFetch from "../../hooks/useProveedoresFetch";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import PropTypes from 'prop-types';
import useObrasFetch from "../../hooks/useObrasFetch";
import useGastoPersonalFetch from "../../hooks/useGastoPersonalFetch";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import useUsuariosFetch from "../../hooks/useUsuariosFetch";
import orderBy from 'lodash/orderBy';
import { Chip } from "primereact/chip";
import { Button } from "primereact/button";
import { saveAsExcelFile } from "../../services/commonHelpers";
import AppContext from "../../contexts/AppContext";
import { saveGastoService } from "../../services/reactivaService";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

const GastoPersonalTabla = ({selected, setSelected})=>{
	const [estado, setEstado] = useState('pendiente');
	const { gastosPersonal, isLoadingGastosPersonal, tiposGastosPersonal } = useGastoPersonalFetch({ estado });
	const [fecha, setFecha] = useState(null);
	const [persona, setPersona] = useState(null);
	const [tipo, setTipo] = useState(null);
	const {proveedores} = useProveedoresFetch();
	const { obras } = useObrasFetch();
	const { personalInterno } = useUsuariosFetch({});
    const { user } = useContext(AppContext);
	const queryClient = useQueryClient();

	const datos = useMemo(
		() =>
			gastosPersonal
				?.map((po) => ({
					...po,
					trabajadorNombre: personalInterno?.find((t) => t.id === po.persona)?.nombre,
					proveedorNombre: proveedores?.find((p) => p.id === po.proveedor)?.nombre,
					obraNombre: obras?.find((p) => p.id === po.obra)?.nombre,
					tipoGastoNombre: tiposGastosPersonal?.find((p) => p.id === po.tipoGasto)?.nombre,
				}))
				?.filter((g) => (tipo ? g.tipoGasto === tipo : true))
				?.filter((g) => (persona ? g.persona === persona : true))
				?.filter((g) => (fecha ? g.fecha >= fecha[0] && g?.fecha <=fecha[1] : true)),
		[gastosPersonal, personalInterno, proveedores, obras, tiposGastosPersonal, tipo, persona, fecha]
	);
	const datosExport = useMemo(()=>(
		datos?.map(d=>({
			trabajador: d?.trabajadorNombre,
			obra: d?.obraNombre,
			proveedor: d?.proveedorNombre,
			tipoGasto: d?.tipoGastoNombre,
			tipoDocumento: d?.tipoDocumento,
			estado: d?.estado,
			fecha: d?.fecha,
			importe: d?.importe,

		}))
	),[datos])
	
	const renderFechaHora = useCallback((row, col)=>{
		const fecha = row[col.field];;
		if (fecha){
			return `${fecha.toLocaleDateString()}`;
		}
		return '---';
	},[]);

	const renderEstado = useCallback((row, col) => {
		let clase='';
		switch(row[col.field]){
			case 'denegado': clase='bg-red-100'; break;
			case 'pendiente': clase='bg-yellow-100'; break;
			case 'validado': clase='bg-green-100'; break;
			default:
		}
		return <Chip label={row[col.field]} className={clase} />
	}, []);

	const handleSelection = useCallback(e=>{
		setSelected(e.value);
	},[setSelected]);

	 const exportExcel = () => {
		import('xlsx').then((xlsx) => {
			const worksheet = xlsx.utils.json_to_sheet(datosExport);
			const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
			const excelBuffer = xlsx.write(workbook, {
				bookType: 'xlsx',
				type: 'array',
			});

			saveAsExcelFile(excelBuffer, 'gastos_');
		});
	};

	const validarGasto = useCallback(
		async (gasto, valido) => {
			try {
				const toSave = {
					...gasto,
					estado: valido ? 'validado' : 'denegado',
					usuarioValidacion: user?.id,
					fechaValidacion: new Date(),
				};
				const resp = await saveGastoService(toSave);
				if (!resp.error) {
					queryClient.invalidateQueries({ queryKey: ['gastosPersonal'] });
					queryClient.invalidateQueries({ queryKey: ['gastoPersonalInfo'] });
					toast.success('Datos guardados correctamente');
				} else {
					toast.error('Error guardando datos');
				}
			} catch (e) {
				toast.error('Error guardando datos');
			}
		},
		[queryClient, user?.id]
	);

	const renderAcciones = useCallback(row=>{
		if (row?.estado==='pendiente'){
			return (
				<>
					<Button
						icon='pi pi-check'
						onClick={() => validarGasto(row, true)}
						severity='success'
						className='p-1 mb-1'
						tooltip="Validar"
						size='small'
					/>
					<Button
						icon='pi pi-times'
						onClick={() => validarGasto(row, false)}
						severity='danger'
						className='p-1'
						tooltip='Denegar'
						size='small'
					/>
				</>
			);
		}
	},[validarGasto]);

	

    return (
			<div className='grid p-1'>
				<div className='col-6'>
					Personal <br />
					<Dropdown
						value={persona}
						onChange={(e) => setPersona(e.value)}
						options={orderBy(
							personalInterno?.map((m) => ({ label: m.nombre, value: m.id, habilitado: m.habilitdo })),
							['habilitado', 'nombre']
						)}
						className=' w-full mb-1  p-0 text-sm'
						panelClassName='text-sm'
						filter
						showClear
					/>
				</div>
				<div className='col-6'>
					Estado <br />
					<Dropdown
						value={estado}
						onChange={(e) => setEstado(e.value)}
						options={[
							{ label: 'Pendiente', value: 'pendiente' },
							{ label: 'Validado', value: 'validado' },
							{ label: 'Denegado', value: 'denegado' },
						]}
						className=' w-full mb-1  p-0 text-sm'
						panelClassName='text-sm'
						filter
						showClear
					/>
				</div>
				<div className='col-6'>
					Tipo <br />
					<Dropdown
						value={tipo}
						onChange={(e) => setTipo(e.value)}
						options={tiposGastosPersonal?.map((m) => ({ label: m.nombre, value: m.id }))}
						className=' w-full mb-1  p-0 text-sm'
						panelClassName='text-sm'
						filter
						showClear
					/>
				</div>
				<div className='col-4'>
					Fecha desde <br />
					<Calendar
						className='w-full mb-1  p-0 text-sm'
						value={fecha}
						locale='es'
						selectionMode='range'
						readOnlyInput
						hideOnRangeSelection
						onChange={(e) => setFecha(e.target.value)}
					/>
				</div>
				<div className='col-2'>
					<Button onClick={exportExcel} icon='pi pi-file-excel' />
				</div>
				<div className='col-12'>
					<DataTable
						loading={isLoadingGastosPersonal}
						value={datos}
						size='small'
						className='text-xs p-1'
						paginator
						rows={10}
						scrollable
						scrollHeight='flex'
						stripedRows
						rowsPerPageOptions={[10, 25, 50]}
						paginatorTemplate=' FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown'
						currentPageReportTemplate='{first} de {last} ({totalRecords})'
						selectionMode='single'
						selection={selected}
						onSelectionChange={handleSelection}
					>
						<Column field='trabajadorNombre' header='Trabajador'></Column>
						<Column field='fecha' header='Fecha' body={renderFechaHora}></Column>
						<Column field='obraNombre' header='Obra'></Column>
						<Column field='tipoGastoNombre' header='Tipo'></Column>
						<Column field='importe' header='Cantidad'></Column>
						<Column field='estado' header='Estado' body={renderEstado} />
						<Column field='' header='' body={renderAcciones} />
					</DataTable>
				</div>
			</div>
		);
};

GastoPersonalTabla.propTypes = {
	selected: PropTypes.object,
	setSelected: PropTypes.func
};

export default GastoPersonalTabla;