import { useQuery } from '@tanstack/react-query';
import { getMarcasService, getObrasByProveedorService, getObrasService } from '../services/reactivaService';
import { useMemo } from 'react';
import orderBy from 'lodash/orderBy';

const useObrasFetch = ({ proveedor }={}) => {

	const { data: obras, isLoading: isLoadingObras } = useQuery({
		queryKey: ['obras'],
		queryFn: async () => await getObrasService(),
	});

	const { data: obrasProveedor, isLoading: isLoadingObrasProveedor } = useQuery({
		queryKey: ['obrasProveedor', proveedor?.id],
		queryFn: async () => await getObrasByProveedorService(proveedor?.id),
		enabled: !!proveedor,
	});


	const { data: marcas, isLoading: isLoadingMarcas } = useQuery({
		queryKey: ['marcas'],
		queryFn: async () => await getMarcasService(),
	});
	
	const marcasOut = useMemo(() => orderBy(marcas, 'nombre'), [marcas]);
	
	const obrasOut = useMemo(() => obras?.map(o=>({
		...o, 
		fechaInicio: new Date(o.fechaInicio),
		fechaFin: o.fechaFin? new Date(o.fechaFin):null,
		marcaNombre: marcas?.find(m=>m.id===o.marca)?.nombre
	})), [obras,marcas]);

	return { obras: obrasOut, isLoadingObras, marcas: marcasOut, isLoadingMarcas, obrasProveedor, isLoadingObrasProveedor };
};

export default useObrasFetch;
