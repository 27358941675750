import { useCallback, useMemo, useState } from "react";
import useProveedoresFetch from "../../hooks/useProveedoresFetch";
import usePersonalFetch from "../../hooks/usePersonalFetch";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import PropTypes from 'prop-types';
import ParteObraDialog from "./ParteObraDialog";
import useObrasFetch from "../../hooks/useObrasFetch";
import orderBy from 'lodash/orderBy';
import { deleteParteTrabajoService, saveParteTrabajoService } from "../../services/reactivaService";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import useUsuariosFetch from "../../hooks/useUsuariosFetch";
import { Checkbox } from "primereact/checkbox";

const TablaPartesObra = ({ partesObra, filtroObra, filtroPersona, filtroProveedor, filtroFecha, altura }) => {
	const queryClient = useQueryClient();
	const { proveedores } = useProveedoresFetch();
	const {usuarios} = useUsuariosFetch({});
	const { obras } = useObrasFetch();
	const { allPersonalProveedor: personal } = usePersonalFetch();
	const [openParte, setOpenParte] = useState(null);
	const [openDelete, setOpenDelete] = useState(null);
	const [proveedor, setProveedor] = useState(null);
	const [persona, setPersona] = useState(null);
	const [fechaInicio, setFechaInicio] = useState(null);
	const [validadoAdm, setValidadoAdm] = useState(null);
	const [validadoObra, setValidadoObra] = useState(null);

	const [obra, setObra] = useState(null);

	const datos = useMemo(
		() =>
			orderBy(
				partesObra?.map((po) => ({
					...po,
					trabajadorNombre: personal?.find((t) => t.id === po.persona)?.nombre,
					proveedorNombre:
						proveedores?.find((p) => p.id === po.proveedor)?.nombre ??
						proveedores?.find((p) => p.id === personal?.find((t) => t.id === po.persona)?.proveedor)?.nombre,
					obraNombre: obras?.find((p) => p.id === po.obra)?.nombre,
					validadoAdmin: usuarios?.find((u) => u.id === po.usuarioValidacionAdministracion)?.nombre,
					validadoObra: usuarios?.find((u) => u.id === po.usuarioValidacionObra),
				})),
				'horaEntrada',
				'desc'
			),
		[partesObra, personal, proveedores, obras, usuarios]
	);


	const guardaParteObra = useCallback(
		async (parte) => {
			try {
				const resp = await saveParteTrabajoService(parte);
				if (!resp.error) {
					queryClient.invalidateQueries({ queryKey: ['partesObraProveedor'] });
					queryClient.invalidateQueries({ queryKey: ['partesObraPersona'] });
					queryClient.invalidateQueries({ queryKey: ['partesObraObra'] });
					queryClient.invalidateQueries({ queryKey: ['partesObraFecha'] });
					toast.success('Datos guardados correctamente');
					setOpenParte(false);
				} else {
					toast.error('Error guardando datos');
				}
			} catch (e) {
				toast.error('Error guardando datos');
			}
		},
		[queryClient]
	);

	const borrarParteTrabajo = useCallback(async () => {
		try {
			const resp = await deleteParteTrabajoService(openDelete);
			if (!resp.error) {
				queryClient.invalidateQueries({ queryKey: ['partesObraProveedor'] });
				queryClient.invalidateQueries({ queryKey: ['partesObraPersona'] });
				queryClient.invalidateQueries({ queryKey: ['partesObraObra'] });
				toast.success('Datos borrados correctamente');
				setOpenParte(false);
			} else {
				toast.error('Error borrando datos');
			}
		} catch (e) {
			toast.error('Error guardando datos');
		}
	}, [openDelete, queryClient]);

	const renderBorrar = useCallback(
		(rowData) => (
			<>
				<Button icon='pi pi-info-circle' onClick={() => setOpenParte(rowData)} severity='info' className='mr-2' />
				<Button icon='pi pi-trash' onClick={() => setOpenDelete(rowData)} severity='danger' />
			</>
		),
		[]
	);

	const renderFechaHora = useCallback((row, col) => {
		const fecha = row[col.field];
		if (fecha) {
			return `${fecha.toLocaleDateString()} ${fecha.toLocaleTimeString('es-ES', {
				hour: '2-digit',
				minute: '2-digit',
			})}`;
		}
		return '---';
	}, []);
	const renderDesplazamiento = useCallback((row, col) => {
		const fecha = row[col.field];
		
		return fecha?'Si':'-';
	}, []);

	const proveedoresDatos = useMemo(() => orderBy([...new Set(datos?.map((d) => d.proveedorNombre))]), [datos]);

	const personaDatos = useMemo(() => orderBy([...new Set(datos?.map((d) => d.trabajadorNombre))]), [datos]);

	const obrasDatos = useMemo(() => orderBy([...new Set(datos?.map((d) => d.obraNombre))]), [datos]);

	const headerFiltros = useMemo(
		() => (
			<div className='grid'>
				{filtroObra && (
					<div className='col-2'>
					<Dropdown
						showClear
						value={obra}
						onChange={(e) => setObra(e.value)}
						placeholder='Filtrar por obra'
						options={obrasDatos?.map((m) => ({ label: m, value: m }))}
						className='w-full mr-1  p-1 text-sm'
						filter
					/>
					</div>
				)}
				{filtroProveedor && (
					<div className='col-2'>
					<Dropdown
						showClear
						value={proveedor}
						onChange={(e) => setProveedor(e.value)}
						placeholder='Filtrar por proveedor'
						options={proveedoresDatos?.map((m) => ({ label: m, value: m }))}
						className='w-full  mr-1  p-1 text-sm'
						filter
					/>
					</div>
				)}
				{filtroPersona && (
					<div className='col-2'>
					<Dropdown
						showClear
						value={persona}
						onChange={(e) => setPersona(e.value)}
						placeholder='Filtrar por persona'
						options={personaDatos?.map((m) => ({ label: m, value: m }))}
						className='w-full mr-1  p-1 text-sm'
						filter
					/>
					</div>
				)}
				{filtroFecha && (
					<div className='col-2'>
					<Calendar
						showClear
						value={fechaInicio}
						onChange={(e) => setFechaInicio(e.value)}
						placeholder='Filtrar por fecha'
						className='w-full mr-1  p-1 text-sm'
					/>
					</div>
				)}
				<div className='col-1'>
					<span className='mt-2'>Validado adm.</span>
					<Checkbox checked={validadoAdm} onChange={(e) => setValidadoAdm(e.checked)} />
				</div>
				<div className='col-1'>
					<span className='mt-2'>Validado obra</span>
					<Checkbox checked={validadoObra} onChange={(e) => setValidadoObra(e.checked)} />
				</div>
			</div>
		),
		[
			filtroObra,
			filtroPersona,
			filtroProveedor,
			obra,
			obrasDatos,
			persona,
			personaDatos,
			proveedor,
			proveedoresDatos,
			fechaInicio,
			setFechaInicio,
			filtroFecha,
			validadoAdm,
			validadoObra,
		]
	);

	const fitltados = useMemo(
		() =>
			datos
				?.filter((d) => (obra ? d.obraNombre?.toLowerCase()?.includes(obra?.toLowerCase()) : true))
				?.filter((d) => (proveedor ? d.proveedorNombre?.toLowerCase()?.includes(proveedor?.toLowerCase()) : true))
				?.filter((d) => (persona ? d.trabajadorNombre?.toLowerCase()?.includes(persona?.toLowerCase()) : true))
				?.filter((d) => (fechaInicio ? d.horaEntrada >= fechaInicio : true))
				?.filter((d) => (validadoAdm ? d.validadoAdmin : true))
				?.filter((d) => (validadoObra ? d.validadoObra : true)),
		[datos, obra, persona, proveedor, fechaInicio, validadoObra, validadoAdm]
	);

	const calcHeight = useMemo(() => document.body.clientHeight - altura, [altura]);

	const renderValidadoAdmin= useCallback(row=>{
		const usuarioValidacion = row?.validadoAdmin;
		return usuarioValidacion ? <span className='text-green-400' title={usuarioValidacion?.nombre}>SI</span> :
		 <span className='text-red-400'>NO</span>;
	},[]);

	const renderValidadoObra = useCallback(
		(row) => {
			const usuarioValidacion = row.validadoObra;
			return usuarioValidacion ? (
				<span className='text-green-400' title={usuarioValidacion?.nombre}>
					SI
				</span>
			) : (
				<span className='text-red-400'>NO</span>
			);
		},
		[]
	);

	return (
		<>
			<DataTable
				scrollHeight={calcHeight}
				header={headerFiltros}
				scrollable
				value={fitltados}
				tableStyle={{ minWidth: '50rem' }}
				size='small'
				className='text-sm p-1'
				paginator
				rows={25}
				stripedRows
				rowsPerPageOptions={[5, 10, 25, 50]}
				paginatorTemplate=' FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown'
				currentPageReportTemplate='{first} de {last} ({totalRecords})'
			>
				<Column field='trabajadorNombre' header='Trabajador'></Column>
				<Column field='proveedorNombre' header='Proveedor'></Column>
				<Column field='obraNombre' header='Obra'></Column>
				<Column field='horaEntrada' header='Inicio' body={renderFechaHora}></Column>
				<Column field='horaSalida' header='Fin' body={renderFechaHora}></Column>
				<Column field='horas' header='Horas'></Column>
				<Column field='desplazamiento' header='Desplaza' body={renderDesplazamiento}></Column>
				<Column header='Validado adm.' body={renderValidadoAdmin}></Column>
				<Column header='Validado obra.' body={renderValidadoObra}></Column>
				<Column body={renderBorrar} style={{ minWidth: '100px' }}></Column>
			</DataTable>
			{openParte && (
				<ParteObraDialog parte={openParte} onClose={() => setOpenParte(null)} editable onSave={guardaParteObra} />
			)}
			{openDelete && (
				<Dialog
					header='Atención'
					visible={openDelete}
					modal
					footer={
						<>
							<Button label='Borrar' icon='pi pi-check' onClick={borrarParteTrabajo} autoFocus />
							<Button label='Borrar' icon='pi pi-times' onClick={() => setOpenDelete(null)} autoFocus />
						</>
					}
					style={{ width: '25rem' }}
					onHide={() => {
						setOpenDelete(null);
					}}
				>
					<p className='m-0'>¿Quiere borrar este parte de trabajo?</p>
				</Dialog>
			)}
		</>
	);
};

TablaPartesObra.propTypes = {
	partesObra: PropTypes.array,
	filtroObra: PropTypes.bool,
	filtroPersona: PropTypes.bool,
	filtroProveedor: PropTypes.bool,
	filtroFecha: PropTypes.bool
};

export default TablaPartesObra;