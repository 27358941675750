import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

const PersonalInternoCard = ({ persona, setSelected, selected }) => {
	const cardStyle = useMemo(() => {
		const style = !persona.habilitado ? 'surface-200' : 'surface-card';
		if (selected?.id === persona?.id) {
			return `${style} border-blue-700 border-2`;
		}
		return style;
	}, [persona.habilitado, persona?.id, selected?.id]);

	const cardIcon = useMemo(() => (!persona.habilitado ? 'pi pi-lock' : 'pi pi-user'), [persona.habilitado]);

	const iconColor = useMemo(() => {
		if (!persona.habilitado) {
			return 'bg-bluegray-100';
		}
		return  'bg-green-200';
	}, [persona.habilitado]);
	
	return (
		<div className='col-12' onClick={() => setSelected(persona)}>
			<div className={`p-3 border-round shadow-2 flex align-items-center ${cardStyle}`}>
				<div
					className={`${iconColor} inline-flex align-items-center justify-content-center mr-3`}
					style={{ width: '48px', height: '48px', borderRadius: '10px' }}
				>
					<i className={`pi ${cardIcon} text-blue-600 text-xl`}></i>
				</div>
				<div>
					<span className='text-900 text-base font-medium mb-2'>{persona.nombre}</span>
					<p className='mt-1 mb-0 text-600 font-medium text-sm'>{persona.dni}</p>
				</div>
				<div className='ml-auto'></div>
			</div>
		</div>
	);
};

PersonalInternoCard.propTypes = {
	persona: PropTypes.object,
	selected: PropTypes.object,
	setSelected: PropTypes.func,
};

export default memo(PersonalInternoCard);
