import moment from "moment";
import cloneDeep from 'lodash/cloneDeep';

//export const WS_URI = 'http://192.168.1.73:8082/reactivaGestionWS';
export const WS_URI = 'https://ws.reactiva.es/reactivaGestionWS';

export const calculaDias = (fechaFin, fechaInicio)=>{
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
	return Math.round(Math.abs((fechaFin - fechaInicio) / oneDay));
}

export const sumaDias = (fecha, dias) => {
	const dd = cloneDeep(fecha); 
	dd.setDate(dd.getDate() + dias);
	return dd;
};

export const dateToString = (date) => moment(date).format('DD/MM/yy');

export const toJavaDate = (date) => moment(date).format('yyyy-MM-DD');

export const dateTimeToString = (date) => moment(date).format('DD/MM/yy HH:mm');

export const saveAsExcelFile = (buffer, fileName) => {
	import('file-saver').then((module) => {
		if (module && module.default) {
			let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
			let EXCEL_EXTENSION = '.xlsx';
			const data = new Blob([buffer], {
				type: EXCEL_TYPE,
			});

			module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
		}
	});
};