import PropTypes from 'prop-types';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import usePersonalFetch from '../../../hooks/usePersonalFetch';
import { memo,  useCallback,  useMemo, useState } from 'react';
import orderBy from 'lodash/orderBy';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import DialogoPersonal from '../../personal/DialogoPersonal';
import NuevoPersonalButton from '../../personal/NuevoPersonalButton';

const PersonalProveedor = ({ proveedor }) => {
	const { allPersonalProveedor : personal} = usePersonalFetch({});
	const [openPersonal, setOpenPersonal] = useState(null);

	const [buscar, setBuscar] = useState(null);

	const datos = useMemo(
		() =>
			orderBy(
				personal
                    ?.filter(p=>p.proveedor=== proveedor?.id)
					?.filter((p) => (buscar ? p.nombre.toLowerCase().includes(buscar.toLowerCase()) : true)),
				'nombre'
			),
		[buscar, personal, proveedor?.id]
	);

	const renderBotones = useCallback(
		(rowData) => (
			<>
				<Button icon='pi pi-info-circle' onClick={() => setOpenPersonal(rowData)} severity='info' className='mr-2' />
			</>
		),
		[]
	);

	const onClose = useCallback(()=>{
		setOpenPersonal(null);
	},[]);

	const renderHabilitado = useCallback(
		(rowData) => <>{rowData?.habilitado ? <i className='pi pi-check'></i> : <i className='pi pi-times'></i>}</>,
		[]
	);

	
	const calcHeight = useMemo(() => document.body.clientHeight - 260, []);

	return (
		<div className='grid'>
			<div className='col-3 p-0 '>
				<NuevoPersonalButton proveedor={proveedor?.id} />
			</div>
			<div className='col-9 p-0'>
				<InputText className='w-full' value={buscar} onChange={(e) => setBuscar(e.target.value)} />
			</div>
			<div className='col-12 p-0 '>
				<DataTable
					stripedRows
					value={datos}
					tableStyle={{ minWidth: '50rem' }}
					className='text-sm'
					scrollHeight={calcHeight}
					size='small'
					paginator
					rows={10}
					rowsPerPageOptions={[5, 10, 25, 50]}
					paginatorTemplate=' FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown'
					currentPageReportTemplate='{first} de {last} ({totalRecords})'
				>
					<Column field='nombre' header='Nombre'></Column>
					<Column field='dni' header='DNI'></Column>
					<Column field='habilitado' header='Habilitado' body={renderHabilitado}></Column>
					<Column body={renderBotones}></Column>
				</DataTable>
				{openPersonal && <DialogoPersonal personal={openPersonal} onClose={onClose} />}
			</div>
		</div>
	);
};

PersonalProveedor.propTypes = {
	obra: PropTypes.object,
};
export default memo(PersonalProveedor);
