import PropTypes from 'prop-types';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { memo, useCallback, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import useObrasFetch from '../../../../hooks/useObrasFetch';
import DialogoObras from '../../../obras/DialogoObras';
import useUsuariosFetch from '../../../../hooks/useUsuariosFetch';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { asignaUsuarioObraService } from '../../../../services/reactivaService';
import { Message } from 'primereact/message';

const ObrasPersonalInterno = ({ persona }) => {
	const { obras } = useObrasFetch({});
	const { obrasPersonalInterno, usuarios } = useUsuariosFetch({ idPersonal: persona?.id });
	const [openProveedor, setOpenProveedor] = useState(null);

	const queryClient = useQueryClient();

	const miUsuario = useMemo(() => usuarios?.find((u) => u.personainterno === persona?.id), [persona?.id, usuarios]);

	const datos = useMemo(
		() =>
			obrasPersonalInterno?.map((p) => {
				const prov = obras?.find((pp) => pp.id === p.obra);
				return prov;
			}),
		[obras, obrasPersonalInterno]
	);

	const guardaAsignacion = useCallback(
		async (obra, asignar) => {
			const response = await asignaUsuarioObraService(obra.id, miUsuario?.id, asignar);
			if (!response.error) {
				toast.success(`${obra.nombre} ${asignar ? 'asignado' : 'eliminado'} correctamente`);
			} else {
				toast.error(`${asignar ? 'Asignando' : 'Eliminando'} ${obra.nombre}  error.`);
			}
			queryClient.invalidateQueries({ queryKey: ['obrasPersonalInterno'] });
			queryClient.invalidateQueries({ queryKey: ['obrasUsuario'] });
		},
		[miUsuario?.id, queryClient]
	);

	const borraObraPersonal = useCallback(
		async (o) => {
			guardaAsignacion(o, false);
		},
		[guardaAsignacion]
	);

	const guardaObras = useCallback(
		async (proveedores) => {
			proveedores?.forEach((o) => {
				guardaAsignacion(o, true);
			});
			setOpenProveedor(false);
		},
		[guardaAsignacion]
	);

	const renderBorrar = useCallback(
		(rowData) => <Button icon='pi pi-trash' onClick={() => borraObraPersonal(rowData)} severity='danger' />,
		[borraObraPersonal]
	);

	const renderActivo = useCallback(
		(rowData) =>!rowData?.fechaFin? 'Si':'No',
		[]
	);

	const abreDialogoProveedorObra = useCallback(() => {
		setOpenProveedor(true);
	}, []);

	const cierraDialogoProvObra = useCallback(() => {
		setOpenProveedor(false);
	}, []);

	const seleccionados = useMemo(() => obrasPersonalInterno?.map((p) => p.obra), [obrasPersonalInterno]);

	return (
		<>
			{!miUsuario && (
				<Message
					severity='warn'
					text='Usuario sin acceso a app de obras, no puede tener obras'
					className='w-full p-2'
				/>
			)}
			{miUsuario && (
				<div className='col-12 p-0 '>
					<Button
						icon='pi pi-plus'
						onClick={abreDialogoProveedorObra}
						label='Asignar obras'
						size='small'
						className='mb-2'
						outlined
					/>
					<DataTable
						value={datos}
						tableStyle={{ minWidth: '50rem' }}
						size='small'
						stripedRows
						className='text-sm'
						paginator
						rows={10}
						rowsPerPageOptions={[5, 10, 25, 50]}
						paginatorTemplate=' FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown'
						currentPageReportTemplate='{first} de {last} ({totalRecords})'
					>
						<Column field='nombre' header='Obra'></Column>
						<Column header='Activo' body={renderActivo}></Column>
						{/* <Column field='cif' header='Cif'></Column> */}
						<Column body={renderBorrar}></Column>
					</DataTable>
					{openProveedor && (
						<DialogoObras seleccionados={seleccionados} onClose={cierraDialogoProvObra} onSave={guardaObras} />
					)}
				</div>
			)}
		</>
	);
};

ObrasPersonalInterno.propTypes = {
	persona: PropTypes.object,
};

export default memo(ObrasPersonalInterno);
