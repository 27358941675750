import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';
import { memo, useCallback, useMemo, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { toast } from 'react-toastify';
import useObrasFetch from '../../hooks/useObrasFetch';

const DialogoObras = ({seleccionados, onClose, onSave})=>{
    const {  obras } = useObrasFetch();
    const [searchT, setSearchT] = useState(null);
    const [activos, setActivos] = useState(true);
    const [misSeleccionados, setMisSeleccionados] = useState([]);

    const guardarObras = useCallback(() => {
			if (onSave) {
				onSave(misSeleccionados);
			}
		}, [misSeleccionados, onSave]);
    
    const footerContent = (
			<div>
				<Button label='Cancelar' icon='pi pi-times' onClick={onClose} className='p-button-text' />
				<Button label='Guardar' icon='pi pi-check' onClick={guardarObras} autoFocus />
			</div>
		);

    const obrasFiltered = useMemo(() => (
                obras?.filter((p) => !seleccionados?.includes(p.id))
                ?.filter(p=>searchT ? p.nombre.toLowerCase().includes(searchT?.toLowerCase()): true)
                ?.filter(p=> activos? !p.fechaFin: true)
    ),	[obras, searchT, seleccionados, activos]);

    const seleccionarObra = useCallback(p=>{
        if (!p.fechaFin){
            if (misSeleccionados?.includes(p)) {
                setMisSeleccionados(misSeleccionados?.filter((m) => m.id !== p.id));
            } else {
                setMisSeleccionados([...misSeleccionados, p]);
            }
        }else{
            toast.info('No se pueden seleccionar obras inactivss.')
        }
    },[misSeleccionados]);

    return (
			<Dialog
				header='Seleccione obra'
				visible={true}
				style={{ width: '50vw', minHeight: '80vh' }}
				onHide={onClose}
				footer={footerContent}
			>
				<InputText className='text-sm w-80' value={searchT} onChange={(e) => setSearchT(e.target.value)} />
				<Checkbox checked={activos} onChange={(e) => setActivos(e.checked)} className='mb-2' /> Ver solo activos
				<ul style={{ maxHeight: '70vh' }} className='list-none p-0 m-0'>
					{obrasFiltered?.map((p) => (
						<li key={p.id} className='pb-1 border-bottom-1 surface-border'>
							<div
								className={`border-2 border-dashed surface-border border-round surface-0 p-1
                                ${p.fechaFin ? 'surface-100 line-through' : ''}
                                ${misSeleccionados?.includes(p) ? 'bg-green-200' : ''}
                            `}
								onClick={() => seleccionarObra(p)}
							>
								{p.nombre}
							</div>
						</li>
					))}
				</ul>
			</Dialog>
		);
}

DialogoObras.propTypes = {
	seleccionados: PropTypes.array,
	onClose: PropTypes.func,
	onSave: PropTypes.func,
	multi: PropTypes.bool,
};
export default memo(DialogoObras);
