import { memo, useMemo } from "react";
import PropTypes from 'prop-types';

const ObraCard = ({obra, setSelected, selected})=>{
    const cardStyle = useMemo(()=>{
        const style = obra.fechaFin ? 'surface-200' : 'surface-card';
        if (selected?.id ===obra?.id){
            return `${style} border-blue-700 border-2`;
        }
        return style;
    },[obra.fechaFin, obra?.id, selected?.id]);

    const cardIcon = useMemo(()=>(
        obra.fechaFin ? 'pi pi-lock' : 'pi pi-building'
    ),[obra.fechaFin]);

	const iconColor = useMemo(() => {
		if (obra.fechaFin) {
			return 'bg-bluegray-100';
		}
		return  'bg-green-200';
	}, [obra.fechaFin]);

    return (
			<div className='col-12' onClick={() => setSelected(obra)}>
				<div className={`p-3 border-round shadow-2 flex align-items-center ${cardStyle}`}>
					<div
						className={`${iconColor} inline-flex align-items-center justify-content-center mr-3`}
						style={{ width: '48px', height: '48px', borderRadius: '10px' }}
					>
						<i className={`pi ${cardIcon} text-blue-600 text-xl`}></i>
					</div>
					<div>
						<span className='text-900 text-base font-medium mb-2'>{obra.nombre}</span>
						<p className='mt-1 mb-0 text-600 font-medium text-sm'>{obra.marcaNombre}</p>
					</div>
					<div className='ml-auto'></div>
				</div>
			</div>
		);
};

ObraCard.propTypes={
    obra: PropTypes.object,
    selected: PropTypes.object,
    setSelected: PropTypes.func
}

export default memo(ObraCard);