import AppLayout from '../../components/layout/AppLayout';
import ObrasList from '../../components/obras/ObrasList';
import { memo, useMemo, useState } from 'react';
import ObraSelected from '../../components/obras/ObraSelected';
import AppBreadcrumb from '../../components/layout/AppBreadcrumb';

const ObrasPage = () => {
	const [selected, setSelected] = useState(null);
	const items = useMemo(()=>{
		const array = [
			{label : 'Maestros'},
			{label: 'Obras'},
		];
		if (selected){
			array.push({label: selected?.nombre});
		}
		return array;
	},[selected]);
	return (
		<AppLayout>
			<div
				className='flex flex-column surface-section p-1 h-full overflow-y-auto 
                overflow-x-hidden border-right-2 border-indigo-200
                flex-shrink-0 flex-grow-1 md:flex-grow-0'
				style={{ width: '350px' }}
			>
				<ObrasList selected={selected} setSelected={setSelected} />
			</div>
			<div className='min-h-screen flex flex-column relative flex-auto' style={{ width: 'calc(100wv - 500px)' }}>
				<AppBreadcrumb items={items} />
				{selected && <ObraSelected obra={selected} key={selected?.id} />}
			</div>
		</AppLayout>
	);
};

export default memo(ObrasPage);
