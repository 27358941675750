import { useContext } from "react";
import { useLocalStorage } from "./useLocalStorage";
import AppContext from "../contexts/AppContext";
import { loginService } from "../services/reactivaService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

export const useAuthUser = () => {
	const { setItem } = useLocalStorage();
	const {user, setUser} = useContext(AppContext);
	
	const navigate = useNavigate();

	const login = (user,password) => {
		loginService(user, password).then((data) => {
			if (!data?.id){
				toast.error('¡Usuario Incorrecto!');
			}else{
				setItem('user', JSON.stringify(data));
				setUser(data);
				toast.error(`Bienvenido ${data.nombre}!`);
				navigate('/');
			}
		});
		
	};

	const logout = () => {
		setItem('user', null);
		setUser(null);
	};

	return { user, login, logout };
};
