import { useQuery } from '@tanstack/react-query';
import { getPartesObraByFecha, getPartesObraByObra, getPartesObraByPersona, getPartesObraByProveedor } from '../services/reactivaService';
import { useMemo } from 'react';
import { toJavaDate } from '../services/commonHelpers';

const usePartesObraFetch = ({ obra, proveedor, persona,fecha } = {}) => {
	const { data: partesObraFecha, isLoading: isLoadingPartesObraFecha } = useQuery({
		queryKey: ['partesObraFecha', toJavaDate(fecha)],
		queryFn: async () => await getPartesObraByFecha(toJavaDate(fecha)),
		enabled: !!fecha,
	});

	const { data: partesObra, isLoading: isLoadingPartesObra } = useQuery({
		queryKey: ['partesObraObra', obra],
		queryFn: async () => await getPartesObraByObra(obra),
		enabled: !!obra,
	});

    const { data: partesObraProveedor } = useQuery({
            queryKey: ['partesObraProveedor', proveedor],
            queryFn: async () => await getPartesObraByProveedor(proveedor),
            enabled: !!proveedor,
    });

    const { data: partesObraPersona } = useQuery({
			queryKey: ['partesObraPersona', persona],
			queryFn: async () => await getPartesObraByPersona(persona),
			enabled: !!persona,
		});

    const partesFinal = useMemo(()=>(
        partesObra?.map(p=>({
            ...p, 
            horaEntrada: new Date(p.horaEntrada),
             horaSalida: p.horaSalida? new Date(p.horaSalida):null})
            )
    ),[partesObra])

     const partesProvfinal = useMemo(
				() =>
					partesObraProveedor?.map((p) => ({
						...p,
						horaEntrada: new Date(p.horaEntrada),
						horaSalida: p.horaSalida ? new Date(p.horaSalida) : null,
					})),
				[partesObraProveedor]
			);
    
	const partesObraPersonaFinal = useMemo(
					() =>
						partesObraPersona?.map((p) => ({
							...p,
							horaEntrada: new Date(p.horaEntrada),
							horaSalida: p.horaSalida ? new Date(p.horaSalida) : null,
						})),
					[partesObraPersona]
				);
	
	const partesObraFechaFinal = useMemo(
		() =>
			partesObraFecha?.map((p) => ({
				...p,
				horaEntrada: new Date(p.horaEntrada),
				horaSalida: p.horaSalida ? new Date(p.horaSalida) : null,
			})),
		[partesObraFecha]
	);

	return {
		partesObra: partesFinal,
		isLoadingPartesObra,
		partesObraProveedor: partesProvfinal,
		partesObraPersona: partesObraPersonaFinal,
		partesObraFecha: partesObraFechaFinal,
		isLoadingPartesObraFecha,
	};
};

export default usePartesObraFetch;
 