import MenuBar from "./MenuBar";

import PropTypes from  'prop-types';
const AppLayout = ({children}) => {
	return (
		<>
			<div className='block-content min-h-screen max-h-screen custom-scrollbar'>
				<div className='ng-star-inserted' style={{ height: '100vh' }}>
					<div className='min-h-screen max-h-screen flex relative lg:static surface-ground'>
						<MenuBar />
						<div className='min-h-screen flex relative flex-auto'>{children}</div>
					</div>
				</div>
			</div>
		</>
	);
};
AppLayout.propTypes={
	children: PropTypes.any
}
export default AppLayout;