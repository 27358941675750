import { useCallback, useMemo, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import PropTypes from 'prop-types';
import useObrasFetch from "../../hooks/useObrasFetch";
import { Calendar } from "primereact/calendar";
import useUsuariosFetch from "../../hooks/useUsuariosFetch";
import usePartesTrabajoFetch from "../../hooks/usePartesTrabajoFetch";
import { sumaDias } from "../../services/commonHelpers";
import { Dropdown } from "primereact/dropdown";
import orderBy from 'lodash/orderBy';

const PartesTrabajoTabla = ({selected, setSelected})=>{
		const [fecha, setFecha] = useState(sumaDias(new Date(), -180));
	const { partesTrabajo, isLoadingPartesTrabajo } = usePartesTrabajoFetch({ fecha });

	const [obra, setObra] = useState(null);
	const { obras } = useObrasFetch();
	const { usuarios } = useUsuariosFetch({});
    
	const datos = useMemo(() =>(
			partesTrabajo?.map((po) => ({
					...po,
					obraNombre: obras?.find((p) => p.id === po.obra)?.nombre,
					trabajadorNombre: usuarios?.find((p) => p.id === po.usuarioCreacion)?.nombre,
				}))
			),[partesTrabajo, obras, usuarios]);
	// const datosExport = useMemo(()=>(
	// 	datos?.map(d=>({
	// 		trabajador: d?.trabajadorNombre,
	// 		obra: d?.obraNombre,
	// 		proveedor: d?.proveedorNombre,
	// 		tipoGasto: d?.tipoGastoNombre,
	// 		tipoDocumento: d?.tipoDocumento,
	// 		estado: d?.estado,
	// 		fecha: d?.fecha,
	// 		importe: d?.importe,

	// 	}))
	// ),[datos])
	
	const renderFechaHora = useCallback((row, col)=>{
		const fecha = new Date(row[col.field]);
		if (fecha){
			return `${fecha.toLocaleDateString()}`;
		}
		return '---';
	},[]);

	

	const handleSelection = useCallback(e=>{
		setSelected(e.value);
	},[setSelected]);

	//  const exportExcel = () => {
	// 	import('xlsx').then((xlsx) => {
	// 		const worksheet = xlsx.utils.json_to_sheet(datosExport);
	// 		const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
	// 		const excelBuffer = xlsx.write(workbook, {
	// 			bookType: 'xlsx',
	// 			type: 'array',
	// 		});

	// 		saveAsExcelFile(excelBuffer, 'gastos_');
	// 	});
	// };

	console.log(datos);

    return (
			<div className='grid p-1'>
				<div className='col-6'>
					Obra <br />
					<Dropdown
						value={obra}
						onChange={(e) => setObra(e.value)}
						options={orderBy(
							obras?.map((m) => ({ label: m.nombre, value: m.id})),
							[ 'nombre']
						)}
						className=' w-full mb-1  p-0 text-sm'
						panelClassName='text-sm'
						filter
						showClear
					/>
				</div> 
				<div className='col-4'>
					Fecha desde <br />
					<Calendar
						className='w-full mb-1  p-0 text-sm'
						value={fecha}
						locale='es'
						selectionMode='range'
						readOnlyInput
						hideOnRangeSelection
						onChange={(e) => setFecha(e.target.value)}
					/>
				</div>
				{/* <div className='col-2'>
					<Button onClick={exportExcel} icon='pi pi-file-excel' />
				</div> */}
				<div className='col-12'>
					<DataTable
						loading={isLoadingPartesTrabajo}
						value={datos}
						size='small'
						className='text-xs p-1'
						paginator
						rows={10}
						scrollable
						scrollHeight='flex'
						stripedRows
						rowsPerPageOptions={[10, 25, 50]}
						paginatorTemplate=' FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown'
						currentPageReportTemplate='{first} de {last} ({totalRecords})'
						selectionMode='single'
						selection={selected}
						onSelectionChange={handleSelection}
					>
						<Column field='trabajadorNombre' header='Trabajador'></Column>
						<Column field='fecha' header='Fecha' body={renderFechaHora}></Column>
						<Column field='obraNombre' header='Obra'></Column>
					</DataTable>
				</div>
			</div>
		);
};

PartesTrabajoTabla.propTypes = {
	selected: PropTypes.object,
	setSelected: PropTypes.func,
};

export default PartesTrabajoTabla;