import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { memo, useCallback, useContext, useState } from "react";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { useQueryClient } from "@tanstack/react-query";
import { saveMovimientoPersonalService } from "../../services/reactivaService";
import { toast } from "react-toastify";
import AppContext from "../../contexts/AppContext";

const NuevoIngresoDlg = ({onClose, persona})=>{
    const [datos, setDatos] = useState({ persona: persona?.id});    
    const queryClient = useQueryClient();
	const {user} = useContext(AppContext);

    const onSave = useCallback(async ()=>{
         try {
			const datosToSave = {
				...datos,
				ingreso: true,
				persona: persona?.id,
				usuarioIngreso: user?.id,
				fechaIngreso: new Date()
			}
			const resp = await saveMovimientoPersonalService(datosToSave);
            if (!resp.error) {
                queryClient.invalidateQueries({ queryKey: ['movimientosSaldoPersonal'] });
                toast.success('Datos guardados correctamente');
                onClose();
            } else {
                toast.error('Error guardando datos');
            }
        } catch (e) {
            toast.error('Error guardando datos');
        }
    },[datos, onClose, persona?.id, queryClient, user?.id]);
    
    const footerContent = (
        <div>
            <Button label='Cancelar' icon='pi pi-times' onClick={onClose} className='p-button-text' />
            <Button label='Guardar' icon='pi pi-check' onClick={() => onSave(datos)} autoFocus />
        </div>
    );

const changeAttr = useCallback(
	(attr, val) => {
		const newD = { ...datos };
		newD[attr] = val;
		setDatos(newD);
	},
	[datos]
);

return (
	<Dialog
		header='Nuevo ingreso'
		visible={true}
		style={{ width: '50vw', minHeight: '80vh' }}
		onHide={onClose}
		footer={footerContent}
	>
		<div className='grid formgrid'>
			<div className='field mb-1 col-12'>
				<label for='nombre' className='font-medium text-900 text-xs'>
					Fecha
				</label>
				<Calendar
					className='w-full mb-1  p-1 text-sm'
					value={datos?.fecha}
					locale='es'
					onChange={(e) => changeAttr('fecha', e.target.value)}
				/>
			</div>
			<div className='field mb-1 col-12'>
				<label for='nombre' className='font-medium text-900  text-xs'>
					Importe
				</label>
				<InputNumber
					className='w-full mb-1  p-1 text-sm'
					value={datos?.importe}
					onValueChange={(e) => changeAttr('importe', e.target.value)}
					minFractionDigits={2}
				/>
			</div>

			<div className='field mb-1 col-12'>
				<label for='nombre' className='font-medium text-900 text-xs'>
					Observaciones
				</label>
				<InputTextarea
					className='p-inputtext w-full mb-1 p-1 text-sm'
					value={datos?.observaciones}
					onChange={(e) => changeAttr('observaciones', e.target.value)}
				/>
			</div>
		</div>
	</Dialog>
);
};

export default memo(NuevoIngresoDlg);