import PropTypes from 'prop-types';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import useProveedoresFetch from '../../../hooks/useProveedoresFetch';
import { memo, useCallback, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import DialogoProveedores from '../../proveedores/DialogoProveedores';
import { toast } from 'react-toastify';
import { asignaProveedorObraService, saveProveedorService } from '../../../services/reactivaService';
import { useQueryClient } from '@tanstack/react-query';
import orderBy from 'lodash/orderBy';
import { InputText } from 'primereact/inputtext';

const ProveedoresObra = ({obra})=>{
    const {proveedoresObra, proveedores} = useProveedoresFetch({obra: obra.id});
    const [openProveedor, setOpenProveedor] = useState(null);

    const [buscar, setBuscar] = useState(null);
    const queryClient= useQueryClient();

    const datos = useMemo(()=>(
        orderBy(proveedoresObra?.map(p=>{
            const prov = proveedores?.find(pp=>pp.id===p.proveedor);
            return prov;
        })?.filter(p=>buscar? p.nombre.toLowerCase().includes(buscar.toLowerCase()):true),'nombre')
    ),[proveedores, proveedoresObra,buscar]);

    const guardaProveedor = useCallback(async (obra, p, asignar) => {
        const response = await asignaProveedorObraService(obra.id, p.id, asignar);
        if (!response.error) {
            toast.success(`${p.nombre} ${asignar ? 'asignado': 'eliminado'} correctamente`);
            queryClient.invalidateQueries({ queryKey: ['proveedoresObra', obra.id] });  
        } else {
            toast.error(`${asignar ? 'Asignando' : 'Eliminando'} ${p.nombre}  error.`);
        }
    },[queryClient]);

    const borraProveedorObra= useCallback(async p=>{
        guardaProveedor(obra, p, false);
    },[guardaProveedor, obra]);


    const guardaProveedores = useCallback(async (proveedores)=>{
        proveedores?.forEach(p=>{
           guardaProveedor(obra, p, true);
        });    
          
        setOpenProveedor(false);
    },[guardaProveedor, obra]);

    const renderBorrar = useCallback(
			(rowData) => <Button icon='pi pi-trash' onClick={() => borraProveedorObra(rowData)} severity='danger' />,
			[borraProveedorObra]
		);
	
	const cambiaProveedor = useCallback(async prov=>{
		if (prov.email && prov.userPasswd ==='SI') {
			const newProv = { ...prov, alertaDocumentacion: !prov.alertaDocumentacion };
			try{
					const resp = await saveProveedorService(newProv);
					if (!resp.error){
						queryClient.invalidateQueries({ queryKey: ['proveedores'] });
						toast.success('Datos guardados correctamente');
					}else{
						toast.error('Error guardando datos');
					}
			}catch(e){
				toast.error('Error guardando datos');
			}
			
		} else{
			toast.error('El proveedor NO tiene e-mail/contraseña configurado, no se puede activar alertas');
		}
	},[queryClient]);

	const renderAlertaDoc = useCallback(rowData=>(
		<Button icon={!rowData?.alertaDocumentacion ? 'pi pi-times' : 'pi pi-check'}
			severity={rowData?.alertaDocumentacion ? 'success':null}
			 onClick={()=>cambiaProveedor(rowData)} tooltip='Click para cambiar' />
	),[cambiaProveedor]);

    const abreDialogoProveedorObra = useCallback(()=>{
        setOpenProveedor(true);
    },[]);

     const cierraDialogoProvObra = useCallback(() => {
				setOpenProveedor(false);
			}, []);

    const seleccionados= useMemo(()=>(
        proveedoresObra?.map(p=> p.proveedor)
    ),[proveedoresObra]);

	const calcHeight = useMemo(() => document.body.clientHeight - 350, []);


    return (
			<div className='col-12 p-0 '>
				<Button
					icon='pi pi-plus'
					onClick={abreDialogoProveedorObra}
					label='Asignar nuevo proveedor'
					size='small'
					className='mb-2'
					outlined
				/>
				<InputText className='w-full' value={buscar} onChange={(e) => setBuscar(e.target.value)} />
				<DataTable
					value={datos}
					tableStyle={{ minWidth: '50rem' }}
					size='small'
					paginator
					rows={10}
					scrollHeight={calcHeight}
					stripedRows
					rowsPerPageOptions={[5, 10, 25, 50]}
					paginatorTemplate=' FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown'
					currentPageReportTemplate='{first} de {last} ({totalRecords})'
					className='text-sm'
				>
					<Column field='nombre' header='Proveedor'></Column>
					<Column field='cif' header='Cif'></Column>
					<Column field='alertaDocumentacion' header='Alerta documentacion' body={renderAlertaDoc}></Column>
					<Column body={renderBorrar}></Column>
				</DataTable>
				{openProveedor && (
					<DialogoProveedores
						seleccionados={seleccionados}
						onClose={cierraDialogoProvObra}
						onSave={guardaProveedores}
					/>
				)}
			</div>
		);
};

ProveedoresObra.propTypes={
    obra: PropTypes.object
}
export default memo(ProveedoresObra);