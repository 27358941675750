import { InputText } from "primereact/inputtext";
import ParteTrabajoImagen from "./ParteTrabajoImagen";
import { memo } from "react";

const ParteTrabajoInfo = ({selected})=>{

    return (
			<>
				<div className='grid formgrid m-2  surface-card border-round-sm'>
					<div className='field mb-1 col-6'>
						<label for='nombre' className='font-medium text-900 text-xs'>
							Nombre
						</label>
						<InputText className='p-inputtext w-full mb-1  p-1 text-sm' disabled value={selected?.trabajadorNombre} />
					</div>
					<div className='field mb-1 col-6'>
						<label for='nombre' className='font-medium text-900 text-xs'>
							Obra
						</label>
						<InputText className='p-inputtext w-full mb-1  p-1 text-sm' disabled value={selected?.obraNombre} />
					</div>
					<div className='field mb-1 col-12'>
						<label for='nombre' className='font-medium text-900 text-xs'>
							Fecha
						</label>
						<InputText className='p-inputtext w-full mb-1  p-1 text-sm' disabled value={new Date(selected?.fecha)?.toLocaleDateString()} />
					</div>
				</div>
				<ParteTrabajoImagen selected={selected} />
			</>
		);
};

export default memo(ParteTrabajoInfo);