import { useCallback, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import ParteObraDialog from "./ParteObraDialog";
import { Button } from "primereact/button";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { saveParteTrabajoService } from "../../services/reactivaService";

const NuevoParteObraButton = ({ obra, proveedor, persona, desplazamiento }) => {
	const [opened, setOpened] = useState(null);
    const queryClient = useQueryClient();

	const parte = useMemo(() => ({
		proveedor, obra, persona, desplazamiento
	}),[obra, persona, proveedor, desplazamiento]);

    const onClose= useCallback(()=>{
        setOpened(false);
    },[]);

    const guardarParte = useCallback(async (parte)=>{       
        try {
			const resp = await saveParteTrabajoService({...parte, desplazamiento:0});
            if (!resp.error) {
                queryClient.invalidateQueries({ queryKey: ['partesObraProveedor'] });
                queryClient.invalidateQueries({ queryKey: ['partesObraPersona'] });
                queryClient.invalidateQueries({ queryKey: ['partesObraObra'] });
                toast.success('Datos guardados correctamente');
                setOpened(false);
            } else {
                toast.error('Error guardando datos');
            }
        } catch (e) {
            toast.error('Error guardando datos');
        }
    },[queryClient]);

    const openParte = useCallback(() => {
			setOpened(true);
		}, []); 

	return<>
    <Button label='Nuevo parte de obra' icon='pi pi-plus' outlined onClick={openParte}/> 
    {opened && <ParteObraDialog parte={parte} editable={true} onClose={onClose} onSave={guardarParte} />}
    </>;
};

NuevoParteObraButton.propTypes = {
	obra: PropTypes.number,
	proveedor: PropTypes.number,
	persona: PropTypes.number,
    desplazamiento: PropTypes.number,
};

export default NuevoParteObraButton;