import PropTypes from 'prop-types';

import { memo } from 'react';
import usePartesObraFetch from '../../../hooks/usePartesObraFetch';
import NuevoParteObraButton from '../../partesObra/NuevoParteObraButton';
import TablaPartesObra from '../../partesObra/TablaPartesObra';

const PartesProveedor = ({ proveedor }) => {
	const { partesObraProveedor } = usePartesObraFetch({ proveedor: proveedor.id });

	return (
		<div className='col-12 p-0 '>
			<NuevoParteObraButton proveedor={proveedor?.id}/>
			<TablaPartesObra partesObra={partesObraProveedor} filtroObra filtroPersona filtroFecha altura={350}/>
		</div>
	);
};

PartesProveedor.propTypes = {
	proveedor: PropTypes.object,
};
export default memo(PartesProveedor);
