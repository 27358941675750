import { TabPanel, TabView } from 'primereact/tabview';
import { memo, useMemo } from 'react';
import useUsuariosFetch from '../../hooks/useUsuariosFetch';
import PersonalInternoInfo from './selected/selected/PersonalInternoInfo';
import ObrasPersonalInterno from './selected/selected/ObrasPersonalInterno';
import GastosPersonalInterno from './selected/selected/GastosPersonalInterno';
import DatosAccesoPersonalInterno from './selected/selected/DatosAccesoPersonalInterno';
import SaldoPersonalInterno from './selected/selected/SaldoPersonalInterno';
//import IngresosPersonalInterno from './selected/selected/IngresosPersonalInterno';
const PersonalInternoSelected = ({ persona }) => {
	const { personalInterno } = useUsuariosFetch({});

	const lastPersona = useMemo(() => personalInterno?.find((o) => o.id === persona?.id), [personalInterno, persona]);

	return (
		<>
			<TabView>
				<TabPanel header={<span className='text-xs'>Información</span>}>
					<PersonalInternoInfo persona={lastPersona} />
				</TabPanel>
				<TabPanel header={<span className='text-xs'>Obras asignadas</span>}>
					<ObrasPersonalInterno persona={lastPersona} />
				</TabPanel>
				<TabPanel header={<span className='text-xs'>Gastos </span>}>
					<GastosPersonalInterno persona={lastPersona} />
				</TabPanel>
				<TabPanel header={<span className='text-xs'>Saldo </span>}>
				 <SaldoPersonalInterno persona={lastPersona} /> 
				</TabPanel>
				<TabPanel header={<span className='text-xs'>Datos acceso </span>}>
					<DatosAccesoPersonalInterno persona={persona} />
				</TabPanel>
			</TabView>
		</>
	);
};

export default memo(PersonalInternoSelected);
