import PropTypes from 'prop-types';

import { memo, useMemo } from 'react';
import useDocumentosFetch from '../../../hooks/useDocumentosFetch';
import TablaDocumentos from '../../documentos/TablaDocumentos';
import SubeDocumento from '../../documentos/SubeDocumento';

const PersonalDocumentos = ({ persona }) => {
	const { documentosPersona, isLoadingDocumentosPersona } = useDocumentosFetch({ idPersona: persona.id });

	const { estadoDocumentosPersona, tipoDocumentos } = useDocumentosFetch({});

	const malitoMotivo = useMemo(
		() =>
			estadoDocumentosPersona
				?.filter((e) => e?.id === persona?.id)
				?.map((e) => `${tipoDocumentos?.find((t) => t.id === e.tipoDoc)?.tipo}: ${e.errores}`)
				?.map((d) => <li>{d}</li>),
		[estadoDocumentosPersona, persona?.id, tipoDocumentos]
	);

	const tiposConErrores = useMemo(() =>
		estadoDocumentosPersona
			?.filter((e) => e?.id === persona?.id)
			?.map((e) => `${tipoDocumentos?.find((t) => t.id === e.tipoDoc)?.tipo}`)
	,[estadoDocumentosPersona, persona?.id, tipoDocumentos]);
	

	return (
		<div className='col-12 p-0 ' style={{ height: 'calc(100vh - 200px)', overflowY: 'auto' }}>
			{malitoMotivo && <ul className='bg-red-200 border-round'>{malitoMotivo}</ul>}
			<SubeDocumento persona={persona} />
			<TablaDocumentos
				documentos={documentosPersona}
				isLoading={isLoadingDocumentosPersona}
				tiposConErrores={tiposConErrores}
			/>
		</div>
	);
};

PersonalDocumentos.propTypes = {
	persona: PropTypes.object,
};
export default memo(PersonalDocumentos);
