import PropTypes from 'prop-types';

import { memo, useCallback, useMemo, useState } from 'react';
import useGastoPersonalFetch from '../../../../hooks/useGastoPersonalFetch';
import useObrasFetch from '../../../../hooks/useObrasFetch';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import NuevoIngresoDlg from '../../../gastosPersonal/NuevoIngresoDlg';
import useUsuariosFetch from '../../../../hooks/useUsuariosFetch';

const SaldoPersonalInterno = ({ persona }) => {
	const { movimientosSaldoPersonal, tiposGastosPersonal } = useGastoPersonalFetch({ persona: persona?.id });
	const { obras } = useObrasFetch({});
	const {usuarios} = useUsuariosFetch({});
	const [nuevoIngreso, setNuevoIngreso] = useState();

	const datos = useMemo(
		() =>
			movimientosSaldoPersonal?.map((po) => ({
				...po,
				fecha: new Date(po.fecha),
				obraNombre: obras?.find((p) => p.id === po.obra)?.nombre,
				tipoGastoNombre: tiposGastosPersonal?.find((p) => p.tipo === po.tipo)?.nombre,
				usuarioC: usuarios?.find(u=>u.id=== po.usuarioCreacion)?.nombre
			})),
		[movimientosSaldoPersonal, obras, tiposGastosPersonal, usuarios]
	);

	const renderFechaHora = useCallback((row, col) => {
		const fecha = row[col.field];
		if (fecha) {
			return `${fecha.toLocaleDateString()}`;
		}
		return '---';
	}, []);

	const renderTipo = useCallback(row=>{
		if (row?.ingreso){
			return 'INGRESO';
		}
		return row?.tipoGastoNombre;
	},[]);

	const saldoActual = useMemo(()=>{
		let total =0;
		datos?.forEach(d=>{
			if (d.ingreso){
				total+=d.importe;
			}else{
				total -= d.importe;
			}
		});
		return total;
	},[datos]);

	return (
		<div className='col-12 p-0 '>
			<div className='grid'>
				<div className='col-2'>
					<Button
						label='Nuevo ingreso'
						outlined
						className='p-1'
						icon='pi pi-plus'
						onClick={() => setNuevoIngreso(true)}
					/>
				</div>
				<div className='col-6'>
					<h4 className={saldoActual < 0 ? 'text-red-600 p-0' : 'text-green-600 p-0'}> Saldo actual: {saldoActual} € </h4>
				</div>
			</div>
			<DataTable
				value={datos}
				size='small'
				className='text-xs p-1'
				paginator
				rows={10}
				scrollable
				scrollHeight='flex'
				stripedRows
				rowsPerPageOptions={[5, 10, 25, 50]}
				paginatorTemplate=' FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown'
				currentPageReportTemplate='{first} de {last} ({totalRecords})'
			>
				<Column field='fecha' header='Fecha' body={renderFechaHora}></Column>
				<Column field='obraNombre' header='Obra'></Column>
				<Column field='tipo' header='Tipo ' body={renderTipo}></Column>
				<Column field='observaciones' header='Observaciones '></Column>
				<Column field='importe' header='Cantidad'></Column>
				<Column field='usuarioC' header='Usuario'></Column>
			</DataTable>
			{nuevoIngreso && <NuevoIngresoDlg onClose={() => setNuevoIngreso(false)} persona={persona} />}
		</div>
	);
};

SaldoPersonalInterno.propTypes = {
	persona: PropTypes.object,
};
export default memo(SaldoPersonalInterno);
