import { useQuery } from '@tanstack/react-query';
import { getPartesTrabajoByFecha, getPartesTrabajoObraService, getParteTrabajoByIdService } from '../services/reactivaService';
import { toJavaDate } from '../services/commonHelpers';

const usePartesTrabajoFetch = ({ fecha,id, obra} = {}) => {
	const { data: partesTrabajo, isLoading: isLoadingPartesTrabajo } = useQuery({
		queryKey: ['partesTrabajo', toJavaDate(fecha)],
		queryFn: async () => await getPartesTrabajoByFecha(toJavaDate(fecha)),
		enabled: !!fecha,
	});
	const { data: partesTrabajoObra, isLoading: isLoadingPartesTrabajoObra } = useQuery({
		queryKey: ['docParteTrabajoObra',obra],
		queryFn: async () => await getPartesTrabajoObraService(obra),
		enabled: !!obra,
	});
	 const { data: parteTrabajoInfo, isLoading: isLoadingParteTrabajo } = useQuery({
			queryKey: ['parteTrabajoInfo', id],
			queryFn: async () => await getParteTrabajoByIdService(id),
			enabled: !!id,
		}); 

	return {
		partesTrabajo,
		isLoadingPartesTrabajo,
		partesTrabajoObra,
		isLoadingPartesTrabajoObra,
		parteTrabajo: parteTrabajoInfo,
		isLoadingParteTrabajo,
	};
};

export default usePartesTrabajoFetch;
