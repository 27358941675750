import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import useDocumentosFetch from '../../hooks/useDocumentosFetch';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { rechazarDocumentoService, validarDocumentoService } from '../../services/reactivaService';
import PropTypes from 'prop-types';

const DialogoValidarDocumento = ({ accion, documento, setAccion, setSelected }) => {
	const { tipoDocumentos } = useDocumentosFetch({});
	const [data, setData] = useState();
	const queryClient = useQueryClient();
	const { documentoValidarCompleto, isLoadingDocumentosValidarCompleto } = useDocumentosFetch({
		documentoValidar: documento?.id,
	});

	const tiposValidos = useMemo(
		() => tipoDocumentos?.filter((t) => t.pertenece === documento?.perteneceA),
		[documento?.perteneceA, tipoDocumentos]
	);

	useEffect(() => {
		setData({
			...documento,
			fecha: new Date(documento?.fecha),
			caducidad: documento?.caducidad ? new Date(documento?.caducidad) : null,
		});
	}, [documento]);

	const onClose = useCallback(() => {
		setAccion(false);
	}, [setAccion]);

	const tieneCaducidad = useMemo(
		() => tipoDocumentos?.find((t) => t.id === data?.tipo)?.caducidad,
		[data?.tipo, tipoDocumentos]
	);

	const changeAttr = useCallback(
		(attr, val) => {
			const newD = { ...data };
			newD[attr] = val;
			setData(newD);
		},
		[data]
	);

	const validaDocumento = useCallback(async () => {
		const back = {
			...data,
			rechazado: accion === 'rechazar',
			rechazadoFecha: accion === 'rechazar' ? new Date() : null,
			validado: accion === 'validar',
			validadoFecha: accion === 'validar' ? new Date() : null,
			documento: documentoValidarCompleto?.documento,
		};
		let res = '';
		if (accion === 'rechazar') {
			res = await rechazarDocumentoService(back);
		} else {
			res = await validarDocumentoService(back);
		}
		if (!res.error) {
			toast(`Documento ${accion === 'rechazar' ? 'rechazado' : 'validado'}`);
			setSelected({ ...documento, validado: accion !== 'rechazar', rechazado: accion === 'rechazar' });
			queryClient.invalidateQueries({ queryKey: ['documentosValidar'], exact: false });
			queryClient.invalidateQueries({ queryKey: ['documentosProveedor'], exact: false });
			queryClient.invalidateQueries({ queryKey: ['documentosPersona'], exact: false });
		} else {
			toast.error(`Error ${accion === 'rechazar' ? 'rechazado' : 'validando'}`);
		}
		onClose();
	}, [accion, data, documentoValidarCompleto?.documento, onClose, queryClient]);

	const footerContent = useMemo(
		() => (
			<div>
				<Button label='Cerrar' icon='pi pi-times' onClick={onClose} className='p-button-text' />
				<Button
					label={accion === 'validar' ? 'Validar documento' : 'Rechazar Documento'}
					icon='pi pi-check'
					onClick={validaDocumento}
					autoFocus
					disabled={isLoadingDocumentosValidarCompleto}
				/>
			</div>
		),
		[accion, isLoadingDocumentosValidarCompleto, onClose, validaDocumento]
	);

	return (
		<>
			<Dialog
				header={accion === 'validar' ? 'Validar documento' : 'Rechazar Documento'}
				visible={accion}
				style={{ width: '80vw', minHeight: '80vh' }}
				onHide={onClose}
				footer={footerContent}
			>
				<div className='col-12'>
					<div className='grid formgrid'>
						<div className='field mb-1 col-12'>
							<label for='nombre' className='font-medium text-900 text-xs'>
								Tipo Documento
							</label>
							<Dropdown
								value={data?.tipo}
								onChange={(e) => changeAttr('tipo', e.value)}
								options={tiposValidos?.map((m) => ({ label: m.tipo, value: m.id }))}
								className=' w-full mb-1  p-1 text-sm'
								filter
							/>
						</div>
						<div className='field mb-1 col-12'>
							<label for='nombre' className='font-medium text-900 text-xs'>
								Fecha Documento
							</label>
							<Calendar
								className='w-full mb-1  p-1 text-sm'
								value={data?.fecha}
								locale='es'
								onChange={(e) => changeAttr('fecha', e.target.value)}
							/>
						</div>
						{tieneCaducidad && (
							<div className='field mb-1 col-12'>
								<label for='nombre' className='font-medium text-900 text-xs'>
									Fecha caducidad
								</label>
								<Calendar
									className='w-full mb-1  p-1 text-sm'
									value={data?.caducidad}
									locale='es'
									onChange={(e) => changeAttr('caducidad', e.target.value)}
								/>
							</div>
						)}
						<div className='field mb-1 col-12'>
							<label for='nombre' className='font-medium text-900 text-xs'>
								Motivo
							</label>
							<InputTextarea
								className='p-inputtext w-full mb-1 p-1 text-xs'
								value={data?.rechazadoMotivo}
								onChange={(e) => changeAttr('rechazadoMotivo', e.target.value)}
							/>
						</div>
					</div>
				</div>
			</Dialog>
		</>
	);
};

DialogoValidarDocumento.propTypes = {
	accion: PropTypes.bool,
	documento: PropTypes.object,
	setAccion: PropTypes.func,
	setSelected: PropTypes.func,
};

export default memo(DialogoValidarDocumento);
