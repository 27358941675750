import PropTypes from 'prop-types';
import useGastoPersonalFetch from '../../hooks/useGastoPersonalFetch';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import { useCallback, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import PdfViewer from '../documentos/PdfViewer';

const GastoPersonalImagen = ({selected})=>{
    const { gastoPersonal, isLoadingGastoPersonal } = useGastoPersonalFetch({ id: selected?.id });
	const [isPdf, setIsPdf] = useState(false);
	const [rotate, setRotate]=useState(0);

	useEffect(()=>{
		setIsPdf(false)
	},[selected?.id]);

	const errorOnImage = useCallback(()=>{
		setIsPdf(true);
	},[]);

	const rotar = useCallback(() => {
		setRotate(rotate+90);
	}, [rotate]);


	return (
		<div className='col-12 p-2 ' style={{ height: '100vh' }}>
			{isLoadingGastoPersonal && <ProgressSpinner />}
			{isPdf && gastoPersonal?.documentoBase64 && !isLoadingGastoPersonal && (
				<PdfViewer base64={`data:application/pdf;base64,${gastoPersonal?.documentoBase64}`} />
			)}
			{!isPdf && !isLoadingGastoPersonal && (
				<>
					<Button onClick={rotar} icon='pi pi-refresh' />
					<img
						src={
							gastoPersonal?.imagen?.includes('data:image')
								? gastoPersonal?.documentoBase64
								: `data:image/jpeg;base64,${gastoPersonal?.documentoBase64}`
						}
						height='100%'
						width='100%'
						alt='Imagen'
						style={{ transform: `rotate(${rotate}deg)` }}
						onError={errorOnImage}
					/>
				</>
			)}
			{!isLoadingGastoPersonal && !gastoPersonal?.documentoBase64 && (
				<Message text='Gasto sin imagen' className='w-full' />
			)}
		</div>
	);
};
GastoPersonalImagen.propTypes={
    selected: PropTypes.object
}
export default GastoPersonalImagen;