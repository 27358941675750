import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';
import { memo, useCallback, useMemo, useState } from 'react';
import AddFicherosStep from './wizard/AddFicherosStep';
import { Wizard } from 'react-use-wizard';
import InformacionFicheroStep from './wizard/InformacionFicheroStep';
import { Steps } from 'primereact/steps';
import { saveDocumentoInterno } from '../../services/reactivaService';

import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

const SubeDocumento = ({proveedor, persona, personaInterno})=>{
    const [opened, setOpened]= useState(null);
	const [index, setIndex] = useState(0);
    const [ficheros, setFicheros]= useState([]);
	const queryClient = useQueryClient();

    const onClose = useCallback(()=>{
		setFicheros([]);
		setIndex(0);
		setOpened(false);
    },[]);

	const items=useMemo(()=>{
		const items = [
			{label: 'Subida ficheros'}		
		];
		ficheros?.forEach(f=>{
			items.push({
				label: f?.path
			});
		})
		return items;
	},[ficheros]);

	const guardaFichero = useCallback(async f=>{
		let pertenece= proveedor?.id;
		let perteneceTipo='PROVEEDOR';
		if (persona){
			pertenece = persona?.id;
			perteneceTipo = 'PERSONAL';
		}else if(personaInterno){
			pertenece = personaInterno?.id;
			perteneceTipo = 'PERSONALINTERNO';
		}
		const ficheroServidor = {
			tipo: f.tipo,
			caducidad: f.caducidad,
			nombre: f?.path,
			fecha: f?.fecha,
			descripcion: f?.descripcion?? '--',
			pertenece,
			caducado: false,
			perteneceTipo,
			documento: f?.base64,
			fechaCreacion: new Date()
		};
		try{
			const resp= await saveDocumentoInterno(ficheroServidor);
			if (!resp.error) {
					queryClient.invalidateQueries({ queryKey: ['documentosProveedor'] });
					queryClient.invalidateQueries({ queryKey: ['documentosPersona'] });
					queryClient.invalidateQueries({ queryKey: ['estadoDocumentosPersona'] });
					queryClient.invalidateQueries({ queryKey: ['estadoDocumentosProveedor'] });
					toast.success('Datos guardados correctamente');
					setOpened(false);
				} else {
					toast.error('Error guardando datos');
				}
			} catch (e) {
				toast.error('Error guardando datos');
			}
	},[persona, personaInterno, proveedor?.id, queryClient]);

	const guardarFicheros = useCallback(async ()=>{
		ficheros?.forEach(f=>{
			guardaFichero(f);
		});
	},[ficheros, guardaFichero]);

    return (
			<>
				<Button label='Nuevo documento' icon='pi pi-plus' outlined onClick={() => setOpened(true)} />
				<Dialog
					header='Añadir documentos'
					visible={opened}
					style={{ width: '80vw', minHeight: '80vh' }}
					onHide={onClose}
					
					//footer={footerContent}
				>
					<div className='col-12'>
						<Steps model={items} activeIndex={index} />
					</div>
					<Wizard startIndex={0} onStepChange={setIndex}>
						<AddFicherosStep ficheros={ficheros} setFicheros={setFicheros} />
						{ficheros?.length &&
							ficheros?.map((f) => (
								<InformacionFicheroStep
									fichero={f}
									key={f?.name}
									ficheros={ficheros}
									setFicheros={setFicheros}
									proveedor={proveedor}
									persona={persona}
									personaInterno={personaInterno}
									guardarFicheros={guardarFicheros}
								/>
							))}
					</Wizard>
				</Dialog>
			</>
		);
};

SubeDocumento.propTypes = {
	persona: PropTypes.object,
    proveedor: PropTypes.object
};

export default memo(SubeDocumento);