import PropTypes from 'prop-types';

import { memo, useMemo } from 'react';
import useDocumentosFetch from '../../../hooks/useDocumentosFetch';
import TablaDocumentos from '../../documentos/TablaDocumentos';
import SubeDocumento from '../../documentos/SubeDocumento';

const DocumentosProveedor = ({ proveedor }) => {
	const { documentosProveedor, isLoadingDocumentosProveedor } = useDocumentosFetch({
		idProveedor: proveedor.id,
	});

	const { estadoDocumentosProveedor,isLoadingEstadoDocumentosProveedor, tipoDocumentos } = useDocumentosFetch({});

	const malitoMotivo = useMemo(
		() =>(
			estadoDocumentosProveedor
				?.filter((e) => e?.id === proveedor?.id)
				?.map((e) => `${tipoDocumentos?.find((t) => t.id === e.tipoDoc)?.tipo}: ${e.errores}`)
				?.map(d=><li>{d}</li>)),
		[estadoDocumentosProveedor, proveedor?.id, tipoDocumentos]
	);
	
	const tiposConErrores = useMemo(
		() =>
			estadoDocumentosProveedor
				?.filter((e) => e?.id === proveedor?.id)
				?.map((e) => `${tipoDocumentos?.find((t) => t.id === e.tipoDoc)?.tipo}`),
		[estadoDocumentosProveedor, proveedor?.id, tipoDocumentos]
	);
	
	return (
		<div className='col-12 p-0 ' style={{ height: 'calc(100vh - 200px)', overflowY: 'auto' }}>
			{malitoMotivo && <ul className='bg-red-200 border-round'>{malitoMotivo}</ul>}
			{isLoadingEstadoDocumentosProveedor && <div className='text-center'>Cargando...</div>}
			<SubeDocumento proveedor={proveedor} />
			<TablaDocumentos
				documentos={documentosProveedor}
				isLoading={isLoadingDocumentosProveedor}
				tiposConErrores={tiposConErrores}
			/>
		</div>
	);
};

DocumentosProveedor.propTypes = {
	proveedor: PropTypes.object,
};
export default memo(DocumentosProveedor);
