import AppLayout from '../../components/layout/AppLayout';
import { memo, useMemo, useState } from 'react';
import AppBreadcrumb from '../../components/layout/AppBreadcrumb';
import TablaPartesObra from '../../components/partesObra/TablaPartesObra';
import usePartesObraFetch from '../../hooks/usePartesObraFetch';
import { sumaDias } from '../../services/commonHelpers';
import { Calendar } from 'primereact/calendar';
import NuevoParteObraButton from '../../components/partesObra/NuevoParteObraButton';

const ObrasPage = () => {
	const [selected, setSelected] = useState(null);
    const [fecha, setFecha] = useState(sumaDias(new Date(), -180));
    const { partesObraFecha } = usePartesObraFetch({ fecha });
	const items = useMemo(() => {
		const array = [{ label: 'Obras' }, { label: 'Partes de Obra' }];
		if (selected) {
			array.push({ label: selected?.nombre });
		}
		return array;
	}, [selected]);
	return (
		<AppLayout>
			<div className='min-h-screen flex flex-column relative flex-auto w-full'>
				<AppBreadcrumb items={items} />
				<div className='col-12'>
					<div class='surface-card p-5 shadow-2 border-round'>
						<div class='font-medium text-3xl text-900 mb-3'>
							Partes de Obra Diario
							<Calendar value={fecha} className='ml-3' onChange={(e) => setFecha(e.value)} locale='es' />
							<NuevoParteObraButton />
						</div>

						<TablaPartesObra
							selected={selected}
							setSelected={setSelected}
							partesObra={partesObraFecha}
							filtroObra
							filtroPersona
							filtroProveedor
							altura={370}
						/>
					</div>
				</div>
			</div>
		</AppLayout>
	);
};

export default memo(ObrasPage);
