import AppLayout from '../components/layout/AppLayout';
import { memo, useEffect, useMemo, useState } from 'react';
import AppBreadcrumb from '../components/layout/AppBreadcrumb';
import PersonalObrasList from '../components/personal/PersonalObrasList';
import PersonalSelected from '../components/personal/PersonalSelected';
import { useParams } from 'react-router';
import usePersonalProveedorFetch from '../hooks/usePersonalFetch';

const PersonalObrasPage = () => {
	
	const { allPersonalProveedor: personal } = usePersonalProveedorFetch({});
	const [selected, setSelected] = useState(null);
	const params = useParams();

	useEffect(()=>{
		if (params?.id){
			setSelected(personal?.find(p=>p.id===parseInt(params?.id,10)));
		}
	},[params?.id, personal]);

	const items = useMemo(() => {
		const array = [{ label: 'Obras' }, { label: 'Personal Obras' }];
		if (selected) {
			array.push({ label: selected?.nombre });
		}
		return array;
	}, [selected]);
	return (
		<AppLayout>
			<div
				className='flex flex-column surface-section p-1 h-full overflow-y-auto 
                overflow-x-hidden
                flex-shrink-0 flex-grow-1 md:flex-grow-0'
				style={{ width: '350px' }}
			>
				<PersonalObrasList selected={selected} setSelected={setSelected} />
			</div>
			<div className='min-h-screen flex flex-column relative flex-auto' style={{ width: 'calc(100wv - 500px)' }}>
				<AppBreadcrumb items={items} />
				{selected && <PersonalSelected persona={selected} key={selected?.id} />}
			</div>
		</AppLayout>
	);
};

export default memo(PersonalObrasPage);
