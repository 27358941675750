import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { memo, useCallback, useEffect, useState } from "react";
import DialogoValidarDocumento from "./DialogoValidarDocumento";
import useDocumentosFetch from "../../hooks/useDocumentosFetch";
import PdfViewer from "./PdfViewer";
import PropTypes from 'prop-types';

const DocumentoValidarInfo = ({selected, setSelected})=>{
 	const [isPdf, setIsPdf] = useState(false);
	const [rotate, setRotate]=useState(0);
    const [openValidar, setOpenValidar] = useState(null);

	const { documentoValidarCompleto, isLoadingDocumentosValidarCompleto } = useDocumentosFetch({documentoValidar: selected?.id});

	useEffect(()=>{
		setIsPdf(false)
	},[selected?.id]);

	const errorOnImage = useCallback(()=>{
		setIsPdf(true);
	},[]);

	const rotar = useCallback(() => {
		setRotate(rotate+90);
	}, [rotate]);

	return (
		<>
			<div className='col-12 p-2 p-3 m-0 surface-card flex align-items-center overflow-x-auto w-full'>
				<div className='grid'>
					<div className='col-3 p-1'>
						<strong>TipoDocumento </strong>
						<br />
						{selected?.tipoNombre}
					</div>
					<div className='col-9 p-1'>
						<strong>Pertenece </strong> <br />
						{selected?.perteneceNombre}
					</div>
					<div className='col-3 p-1'>
						<strong>Fecha </strong> <br />
						{new Date(selected?.fecha)?.toLocaleDateString()}
					</div>
					<div className='col-2 p-1'>
						<strong>Validado </strong> <br />
						{selected?.validado ? 'SI' : 'NO'}
					</div>
					<div className='col-2 p-1'>
						<strong>Rechazado </strong> {selected?.rechazado ? 'SI' : 'NO'}
					</div>
					<div className='col-5 p-1'>
						<strong>Motivo </strong> <br />
						{selected?.rechazadoMotivo}
					</div>
					<div className='col-12'>
						{!selected?.validado && !selected.rechazado && (
							<Button
								label='Aceptar'
								className='mr-2 p-2'
								outlined
								onClick={() => {
									setOpenValidar('validar');
								}}
							/>
						)}
						{!selected?.validado && !selected.rechazado && (
							<Button
								label='Rechazar'
								className='mr-2 p-2'
								outlined
								severity='danger'
								onClick={() => {
									setOpenValidar('rechazar');
								}}
							/>
						)}
					</div>
				</div>
			</div>
			<div className='col-12 p-2 ' style={{ height: 'calc(100vh - 250px)' }}>
				{isLoadingDocumentosValidarCompleto && <Message text='Cargando documento' className='w-full' />}
				{isPdf && documentoValidarCompleto?.documento && !isLoadingDocumentosValidarCompleto && (
					<>
						<PdfViewer base64={`data:application/pdf;base64,${documentoValidarCompleto?.documento}`} />
						{/* <embed
							src={`data:application/pdf;base64,${documentoValidarCompleto?.documento}`}
							type='application/pdf'
							height='100%'
							width='100%'
						></embed> */}
					</>
				)}
				{!isPdf && !isLoadingDocumentosValidarCompleto && (
					<>
						<Button onClick={rotar} icon='pi pi-refresh' />
						<img
							src={`data:image/jpeg;base64,${documentoValidarCompleto?.documento}`}
							height='100%'
							width='100%'
							alt='Imagen'
							style={{ transform: `rotate(${rotate}deg)` }}
							onError={errorOnImage}
						/>
					</>
				)}
				{!documentoValidarCompleto?.documento && !isLoadingDocumentosValidarCompleto && (
					<Message text='Documento sin imagen' className='w-full' />
				)}
				{openValidar && (
					<DialogoValidarDocumento
						accion={openValidar}
						documento={selected}
						setAccion={setOpenValidar}
						setSelected={setSelected}
					/>
				)}
			</div>
		</>
	);

};

DocumentoValidarInfo.propTypes={
	selected: PropTypes.object,
	setSelected: PropTypes.func,
}

export default memo(DocumentoValidarInfo);