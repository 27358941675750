import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import PropTypes from 'prop-types';
import useProveedoresFetch from "../../hooks/useProveedoresFetch";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import useObrasFetch from "../../hooks/useObrasFetch";
import { InputText } from "primereact/inputtext";
import usePersonalFetch from "../../hooks/usePersonalFetch";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import AppContext from "../../contexts/AppContext";

const ParteObraDialog = ({parte, editable,  onClose, onSave})=>{
	const {user} = useContext(AppContext);
const {proveedores}=useProveedoresFetch();
const { obras } = useObrasFetch();

const [datos, setDatos] = useState(null);
const { personalProveedor, allPersonalProveedor } = usePersonalFetch({ id: datos?.proveedor });


useEffect(()=>{
	setDatos({
		...parte,
		proveedor: !parte.proveedor
			? proveedores?.find((p) => p.id === allPersonalProveedor?.find((t) => t.id === parte.persona)?.proveedor)?.id
			: parte.proveedor,
	});
},[allPersonalProveedor, parte, proveedores]);

const validadoAdm = useMemo(() => parte?.validadoAdmin, [parte]);
const validadoObra = useMemo(() => parte?.validadoObra, [parte]);
// rol 2 - oficina
//rol 3- jefe de obra

const validaAdm = useCallback(()=>{
const newD = { ...datos, usuarioValidacionAdministracion: user?.id, fechaValidacionAdministracion: new Date() };
setDatos(newD);
onSave(newD);
},[datos, user?.id, onSave]);

const validaObra = useCallback(()=>{

const newD = { ...datos, usuarioValidacionObra: user?.id, fechaValidacionObra: new Date() };
setDatos(newD);
onSave(newD);
},[datos, onSave, user?.id])

 
const footerContent = (
	<div className='w-full'>
		<div className='text-left'>
			<Button
				label={!validadoAdm ? 'Validar adm.' : `Validado por ${validadoAdm?.nombre}`}
				disabled={validadoAdm || (user?.rol !== 2 && user?.rol !== 1)}
				onClick={validaAdm}
			/>
			<Button
				label={!validadoObra ? 'Validar jef. obra ' : `Validado por ${validadoObra?.nombre}`}
				disabled={validadoObra || (user?.rol !== 3 && user?.rol !== 1)}
				onClick={validaObra}
			/>
		</div>
		<div className='text-right'>
			<Button label='Cancelar' icon='pi pi-times' onClick={onClose} className='p-button-text' />
			{editable && <Button label='Guardar' icon='pi pi-check' onClick={() => onSave(datos)} autoFocus />}
		</div>
	</div>
);

const changeAttr = useCallback(
	(attr, val) => {
		const newD = { ...datos };
		newD[attr] = val;
		setDatos(newD);
	},
	[datos]
);


return (
	<Dialog
		header='Parte de Obra'
		visible={true}
		style={{ width: '50vw', minHeight: '80vh' }}
		onHide={onClose}
		footer={footerContent}
	>
		<div className='grid formgrid'>
			<div className='field mb-1 col-12'>
				<label for='nombre' className='font-medium text-900 text-xs'>
					Obra
				</label>
				<Dropdown
					value={datos?.obra}
					onChange={(e) => changeAttr('obra', e.value)}
					options={obras?.map((m) => ({ label: m.nombre, value: m.id }))}
					className=' w-full mb-1  p-1 text-sm'
					disabled={!editable}
					filter
				/>
			</div>
			<div className='field mb-1 col-12'>
				<label for='nombre' className='font-medium text-900 text-xs'>
					Proveedor
				</label>
				<Dropdown
					value={datos?.proveedor}
					onChange={(e) => changeAttr('proveedor', e.value)}
					options={proveedores?.map((m) => ({ label: m.nombre, value: m.id }))}
					className=' w-full mb-1  p-1 text-sm'
					disabled={!editable}
					filter
				/>
			</div>
			<div className='field mb-1 col-12'>
				<label for='nombre' className='font-medium text-900 text-xs'>
					Trabajador
				</label>
				<Dropdown
					value={datos?.persona}
					onChange={(e) => changeAttr('persona', e.value)}
					options={personalProveedor?.map((m) => ({ label: m.nombre, value: m.id }))}
					className=' w-full mb-1  p-1 text-sm'
					disabled={!editable}
					filter
				/>
			</div>
			<div className='field mb-1 col-6'>
				<label for='nombre' className='font-medium text-900 text-xs'>
					Fecha inicio
				</label>
				<Calendar
					className='w-full mb-1  p-1 text-sm'
					value={datos?.horaEntrada}
					locale='es'
					showTime hourFormat="24" 
					disabled={!editable}
					onChange={(e) => changeAttr('horaEntrada', e.target.value)}
				/>
			</div>
			<div className='field mb-1 col-6'>
				<label for='nombre' className='font-medium text-900 text-xs'>
					Fecha fin
				</label>
				<Calendar
					className='w-full mb-1  p-1 text-sm'
					value={datos?.horaSalida}
					locale='es'
					showTime hourFormat="24" 
					disabled={!editable}
					onChange={(e) => changeAttr('horaSalida', e.target.value)}
				/>
			</div>
			<div className='field mb-1 col-12'>
				<label for='nombre' className='font-medium text-900  text-xs'>
					Horas trabajadas
				</label>
				<InputText
					className='p-inputtext w-full mb-1 p-1 text-xs'
					value={datos?.horas}
					onChange={(e) => changeAttr('horas', e.target.value)}
					disabled={!editable}
				/>
			</div>
			<div className='field mb-1 col-12'>
				<label for='nombre' className='font-medium text-900 text-xs'>
					Trabajo realizado
				</label>
				<InputTextarea
					className='p-inputtext w-full mb-1 p-1 text-xs'
					value={datos?.trabajoRealizado}
					onChange={(e) => changeAttr('trabajoRealizado', e.target.value)}
					disabled={!editable}
				/>
			</div>
			<div className='field mb-1 col-12'>
				<label for='nombre' className='font-medium text-900 text-xs'>
					Observaciones
				</label>
				<InputTextarea
					className='p-inputtext w-full mb-1 p-1 text-sm'
					value={datos?.observaciones}
					disabled={!editable}
					onChange={(e) => changeAttr('observaciones', e.target.value)}
				/>
			</div>
		</div>
	</Dialog>
);
};

ParteObraDialog.propTypes = {
	parte: PropTypes.object,
	editable: PropTypes.bool,
	visible: PropTypes.bool,
	onClose: PropTypes.func
};
export default ParteObraDialog;