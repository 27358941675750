import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import ObraCard from "./ObraCard";
import { memo, useMemo, useState } from "react";
import useObrasFetch from "../../hooks/useObrasFetch";
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';

const ObrasList = ({selected, setSelected})=>{
    const [searchText, setSearchText] = useState(null);
    const [activas, setActivas] = useState(true);

     const {obras,marcas} =useObrasFetch({});

	const finalObras = useMemo(() => {
		const f = obras?.map(o=>({...o, marcaNombre: marcas?.find(m=>m.id=== o.marca)?.nombre}))
			?.filter((o) => (activas ? !o.fechaFin : true))
			?.filter((o) =>
				searchText
					? o.nombre?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
						o.marcaNombre?.toLowerCase()?.includes(searchText?.toLowerCase())
					: true
			)
		return orderBy(f, ['codMurano'], 'desc');
		},[obras, activas, searchText,marcas]	);

            return (
							<>
								<h2 className='mt-1 mb-1'> Obras</h2>
								<div className='grid'>
									<div className='col-12 p-1'>
										<InputText className='p-1' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
									</div>
									<div className='col-12 p-1'>
										<Checkbox
											checked={activas}
											onChange={(e) => {
												setActivas(e.checked);
											}}
										/>{' '}
										Solo activas
									</div>
								</div>
								<hr />
								<div className='grid'>
									{finalObras?.map((o) => (
										<ObraCard key={o.nombre} obra={o} selected={selected} setSelected={setSelected} />
									))}
								</div>
							</>
						);;
};

ObrasList.propTypes={
	selected: PropTypes.object,
	setSelected: PropTypes.func
}

export default memo(ObrasList);
