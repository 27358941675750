import AppLayout from '../../components/layout/AppLayout';
import { memo, useMemo, useState } from 'react';
import AppBreadcrumb from '../../components/layout/AppBreadcrumb';
import PersonalInternoList from '../../components/personalInterno/PersonalInternoList';
import PersonalInternoSelected from '../../components/personalInterno/PersonalInternoSelected';

const PersonalInternoPage = () => {
	const [selected, setSelected] = useState(null);
	const items = useMemo(() => {
		const array = [{ label: 'Obras' }, { label: 'Personal interno' }];
		if (selected) {
			array.push({ label: selected?.nombre });
		}
		return array;
	}, [selected]);
	return (
		<AppLayout>
			<div
				className='flex flex-column surface-section p-1 h-full overflow-y-auto 
                overflow-x-hidden
                flex-shrink-0 flex-grow-1 md:flex-grow-0'
				style={{ width: '350px' }}
			>
				<PersonalInternoList selected={selected} setSelected={setSelected} />
			</div>
			<div className='min-h-screen flex flex-column relative flex-auto'>
				<AppBreadcrumb items={items} />
				<div style={{ width: 'calc(100wv - 500px)', height: 'calc(100vh - 50px)', overflowY: 'scroll' }}>
					{selected && <PersonalInternoSelected persona={selected} key={selected?.id} />}
				</div>
			</div>
		</AppLayout>
	);
};

export default memo(PersonalInternoPage);
