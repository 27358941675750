import { memo, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import useDocumentosFetch from '../../hooks/useDocumentosFetch';
import { Button } from 'primereact/button';


const PersonaCard = ({ persona, setSelected, selected }) => {
	const ref= useRef();
	const { estadoDocumentosPersona ,tipoDocumentos } = useDocumentosFetch({});
	const noEntrada = useMemo(() => new Date(persona?.fechaEntrada) < new Date(), [persona?.fechaEntrada]);

	const cardStyle = useMemo(() => {
		const style = !persona.habilitado ? 'surface-200' : 'surface-card';
		if (selected?.id === persona?.id) {
			return `${style} border-blue-700 border-2`;
		}
		return style;
	}, [persona.habilitado, persona?.id, selected?.id]);

	const cardIcon = useMemo(() => {
		if (!persona.habilitado){
			return 'pi pi-lock';
		}
		return (noEntrada ? 'pi pi-ban' : 'pi  pi-id-card')
	}, [noEntrada, persona.habilitado]);

	const iconColor = useMemo(()=>{
		if (!persona.habilitado) {
			return 'bg-bluegray-100';
		}
		return noEntrada ? 'bg-red-200' : 'bg-green-200';
	},[noEntrada, persona.habilitado]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const malito = useMemo(
		() => estadoDocumentosPersona?.find((e) => e?.id === persona?.id),
		[estadoDocumentosPersona, persona]
	);

	const malitoMotivo = useMemo(
		() =>
			estadoDocumentosPersona
				?.filter((e) => e?.id === persona?.id)
				?.map((e) => `${tipoDocumentos?.find((t) => t.id === e.tipoDoc)?.tipo}: ${e.errores}`)
				.join('\n'),
		[estadoDocumentosPersona, persona?.id, tipoDocumentos]
	);

	useEffect(() => {
		if (selected?.id === persona?.id) {
			ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}, [persona?.id, selected?.id]);

	return (
		<div className='col-12' onClick={() => setSelected(persona)} ref={ref}>
			<div className={`p-3 border-round shadow-2 flex align-items-center ${cardStyle}`}>
				<div
					className={`${iconColor} inline-flex align-items-center justify-content-center mr-3`}
					style={{ width: '48px', height: '48px', borderRadius: '10px' }}
				>
					<i className={`pi ${cardIcon} text-blue-600 text-xl`}></i>
				</div>
				<div>
					<span className='text-900 text-base font-medium mb-2'>{persona.nombre}</span>
					<p className='mt-1 mb-0 text-600 font-medium text-sm'>{persona.proveedorNombre}</p>
					<p className='mt-1 mb-0 text-600 font-medium text-xs'>{persona.dni}</p>
				</div>
				<div className='ml-auto'>
					{/* {isLoadingEstadoDocumentosPersona && <ProgressSpinner data-pr-tooltip='Cargando estado'/>} */}
					{malito && <Button tooltip={malitoMotivo} severity='danger' outlined icon='pi pi-exclamation-triangle' />}
				</div>
			</div>
		</div>
	);
};

PersonaCard.propTypes = {
	persona: PropTypes.object,
	selected: PropTypes.object,
	setSelected: PropTypes.func,
};

export default memo(PersonaCard);
