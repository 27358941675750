import PropTypes from 'prop-types';

const PdfViewer = ({ base64 }) => {
	const blob = base64ToBlob(base64, 'application/pdf');
	const url = URL.createObjectURL(blob);
	

	function base64ToBlob(base64, type = 'application/octet-stream') {
		// Remove any prefix from the base64 string
		const cleanBase64 = base64.split(',')[1];
		const binStr = atob(cleanBase64);
		const len = binStr.length;
		const arr = new Uint8Array(len);
		for (let i = 0; i < len; i++) {
			arr[i] = binStr.charCodeAt(i);
		}
		return new Blob([arr], { type: type });
	}

	return <iframe width='100%' height='100%' src={url} title='PDF Preview'></iframe>;
};

PdfViewer.propTypes = {
	url: PropTypes.string.isRequired,
};
export default PdfViewer;
