import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { memo, useMemo, useState } from 'react';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import useUsuariosFetch from '../../hooks/useUsuariosFetch';
import PersonalInternoCard from './PersonalInternoCard';
import { ProgressSpinner } from 'primereact/progressspinner';

const ObrasList = ({ selected, setSelected }) => {
	const [searchText, setSearchText] = useState(null);
	const [activas, setActivas] = useState(true);

	const { personalInterno, isLoadingPersonalInterno } = useUsuariosFetch({});
	const finalObras = useMemo(() => {
		const f = personalInterno
			?.filter((o) => (searchText ? o.nombre?.toLowerCase()?.includes(searchText?.toLowerCase()) : true))
			?.filter((o) => (activas ? o.habilitado : true));
		return orderBy(f, ['nombre'], 'asc');
	}, [activas, searchText, personalInterno]);

	console.log(finalObras);

	return (
		<>
			<h2 className='mt-1 mb-1'> Personal interno</h2>
			<div className='grid'>
				<div className='col-12 p-1'>
					<InputText className='p-1' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
				</div>
				<div className='col-12 p-1'>
					<Checkbox
						checked={activas}
						onChange={(e) => {
							setActivas(e.checked);
						}}
					/>{' '}
					Solo activos
				</div>
			</div>
			<hr />
			<div className='grid'>
				{isLoadingPersonalInterno && <ProgressSpinner />}
				{finalObras?.map((o) => (
					<PersonalInternoCard key={o.id} persona={o} selected={selected} setSelected={setSelected} />
				))}
			</div>
		</>
	);
};

ObrasList.propTypes = {
	selected: PropTypes.object,
	setSelected: PropTypes.func,
};

export default memo(ObrasList);
