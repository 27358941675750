import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import {  useWizard } from 'react-use-wizard';
import FicheroCard from './FicheroCard';
const AddFicherosStep = ({ficheros, setFicheros})=>{
    const {  nextStep } = useWizard();

    const arrayBufferToBase64 = (buffer) => {
        // Create a Uint8Array view on the ArrayBuffer
        var binary = '';
        var bytes = new Uint8Array(buffer);

        for (var i = 0; i < bytes.byteLength; i++) {
            binary += String.fromCharCode(bytes[i]);
        }

        return btoa(binary);
    };

    const onDrop = useCallback((acceptedFiles) => {
        const myFiles=[...ficheros]??[];
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();

            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = (ev) => {
                // Do whatever you want with the file contents
                const binaryStr = ev.target.result;
                const str = arrayBufferToBase64(binaryStr); 
                myFiles.push({ ...file, base64: str });   
                setFicheros([...myFiles]);
            };
            reader.readAsArrayBuffer(file);
        });
    }, [setFicheros, ficheros]);

	const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 10 });

		
    return (
			<div className='col-12'>
				<Button label='Siguiente paso' disabled={ficheros?.length < 1} onClick={() => nextStep()} />
                <hr/>
				<div {...getRootProps()} style={{ border: '1px dashed black', margin: 5, borderRadius: '5px', height: '30px' }}>
					<input {...getInputProps()} />
					<p style={{ margin: 0, padding: 0 }}>Arrastre algún fichero o haga click para buscar en su pc</p>
				</div>
				<div className='grid'>
					{ficheros?.map((file) => (
						<FicheroCard fichero={file} setFicheros={setFicheros} ficheros={ficheros} />
					))}
				</div>
			</div>
		);
};

AddFicherosStep.propTypes={
    ficheros: PropTypes.array,
    setFicheros: PropTypes.func
}

export default AddFicherosStep;