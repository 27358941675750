import { useQuery } from "@tanstack/react-query";
import { getGastoPersonalInfoService, getGastosPersonalInternoService, getGastosPersonalService, getMovimientosSaldoPersonalService, getTiposGastosService } from "../services/reactivaService";
import { useMemo } from "react";

const useGastoPersonalFetch= ({estado,id, persona})=>{
    const { data: gastosPersonal, isLoading: isLoadingGastosPersonal } = useQuery({
        queryKey: ['gastosPersonal', estado],
        queryFn: async () => await getGastosPersonalService(estado??'')
    });

    const { data: tiposGastosPersonal } = useQuery({
        queryKey: ['tiposGastosPersonal'],
        queryFn: async () => await getTiposGastosService(estado),
    });

     const { data: gastoPersonal, isLoading: isLoadingGastoPersonal } = useQuery({
            queryKey: ['gastoPersonalInfo', id],
            queryFn: async () => await getGastoPersonalInfoService(id),
            enabled: !!id,
        }); 
    
    const { data: gastosPersonalInterno } = useQuery({
			queryKey: ['gastosPersonalInterno', persona],
			queryFn: async () => await getGastosPersonalInternoService(persona),
			enabled: !!persona,
		}); 

     const { data: movimientosSaldoPersonal } = useQuery({
            queryKey: ['movimientosSaldoPersonal', persona],
            queryFn: async () => await getMovimientosSaldoPersonalService(persona),
            enabled: !!persona,
        }); 

    const gastosPersonalF = useMemo(()=>(
        gastosPersonal?.map(g=>({...g, fecha: new Date(g.fecha)}))
    ),[gastosPersonal]);

    return {
			gastosPersonal: gastosPersonalF,
			isLoadingGastosPersonal,
			tiposGastosPersonal,
			gastoPersonal,
			isLoadingGastoPersonal,
			gastosPersonalInterno,
			movimientosSaldoPersonal,
		};
};

export default useGastoPersonalFetch;