import {  useMemo } from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import useDocumentosFetch from '../../hooks/useDocumentosFetch';
import { Accordion, AccordionTab } from 'primereact/accordion';
import TablaTipoDocumento from './TablaTipoDocumento';

const TablaDocumentos = ({ documentos, tiposConErrores}) => {
	const { tipoDocumentos } = useDocumentosFetch({});
	const datos = useMemo(
		() =>
			orderBy(
				documentos?.map((po) => ({
					...po,
					fecha: new Date(po.fecha),
					fechaCaducidad: po.fechaCaducidad ? new Date(po.fechaCaducidad) : null,
					tipoDocNombre: tipoDocumentos?.find((t) => t.id === po.tipo)?.tipo,
				})),
				['caducidad', 'tipo'],
				'desc'
			),
		[documentos, tipoDocumentos]
	);

	const tipos = useMemo(() => orderBy(uniqBy(datos?.map((d) => d.tipoDocNombre))), [datos]);

	return (
		<>
			<Accordion>
				{tipos?.map((t) => (
					<AccordionTab
						 header={<span className={tiposConErrores?.includes(t) ? 'text-red-200' : null}>{t}</span>}>
						<TablaTipoDocumento docs={datos?.filter((d) => d.tipoDocNombre === t)} />
					</AccordionTab>
				))}
			</Accordion>
		</>
	);
};

TablaDocumentos.propTypes = {
	documentos: PropTypes.array,
    erreores: PropTypes.bool
};

export default TablaDocumentos;
