import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';
import PersonalSelected from './PersonalSelected';
import { memo, useMemo } from 'react';

const DialogoPersonal = ({ personal, onClose }) => {
    const titulo = useMemo(() => personal?.id ?`Información de ${personal?.nombre}`: 'Nuevo trabajador', [personal?.nombre, personal?.id]);
	
	return (
		<Dialog header={titulo} visible={true} style={{ width: '90vw', minHeight: '90vh' }} onHide={onClose}>
			<PersonalSelected persona={personal} onClose ={onClose} />
		</Dialog>
	);
};

DialogoPersonal.propTypes = {
	personal: PropTypes.object
};
export default memo(DialogoPersonal);
