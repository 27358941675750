import { useQuery } from "@tanstack/react-query";
import { getDocumento, getDocumentosEmpresa, getDocumentosPersona, getDocumentosValidarPendientesService, getDocumentosValidarRechazadosService, getDocumentosValidarValidadosService, getDocumentoValidarService, getEstadoDocumentosPersona, getEstadoDocumentosProveedor, getTipoDocumentos } from "../services/reactivaService";

const useDocumentosFetch = ({ idProveedor, idPersona, id, documentoValidar }) => {
	const { data: tipoDocumentos, isLoading: isLoadingTipoDocumentos } = useQuery({
		queryKey: ['tipoDocumentos'],
		queryFn: async () => await getTipoDocumentos(),
	});

	const { data: documentosValidar, isLoading: isLoadingDocumentosValidar } = useQuery({
		queryKey: ['documentosValidar', idProveedor],
		queryFn: async () => await getDocumentosValidarPendientesService(),
	});

		const { data: documentosValidados, isLoading: isLoadingDocumentosValidados } = useQuery({
			queryKey: ['documentosValidados', idProveedor],
			queryFn: async () => await getDocumentosValidarValidadosService(),
		});

		const { data: documentosRechazados, isLoading: isLoadingDocumentosRechazados } = useQuery({
			queryKey: ['documentosRechazados', idProveedor],
			queryFn: async () => await getDocumentosValidarRechazadosService(),
		});

	const { data: documentosProveedor, isLoading: isLoadingDocumentosProveedor } = useQuery({
		queryKey: ['documentosProveedor', idProveedor],
		queryFn: async () => await getDocumentosEmpresa(idProveedor),
		enabled: !!idProveedor,
	});

	const { data: documento, isLoading: isLoadingDocumento } = useQuery({
		queryKey: ['documento', id],
		queryFn: async () => await getDocumento(id),
		enabled: !!id,
	});
		const { data: documentoValidarCompleto, isLoading: isLoadingDocumentosValidarCompleto } = useQuery({
			queryKey: ['documentoValidar', documentoValidar],
			queryFn: async () => await getDocumentoValidarService(documentoValidar),
			enabled: !!documentoValidar,
		});

	const { data: documentosPersona, isLoading: isLoadingDocumentosPersona } = useQuery({
		queryKey: ['documentosPersona', idPersona],
		queryFn: async () => await getDocumentosPersona(idPersona),
		enabled: !!idPersona,
	});
	const { data: estadoDocumentosPersona, isLoading: isLoadingEstadoDocumentosPersona } = useQuery({
		queryKey: ['estadoDocumentosPersona'],
		queryFn: async () => await getEstadoDocumentosPersona(),
	});

	const { data: estadoDocumentosProveedor, isLoading: isLoadingEstadoDocumentosProveedor } = useQuery({
		queryKey: ['estadoDocumentosProveedor'],
		queryFn: async () => await getEstadoDocumentosProveedor(),
	});

	return {
		documentosProveedor,
		isLoadingDocumentosProveedor,
		documentosPersona,
		isLoadingDocumentosPersona,
		tipoDocumentos,
		isLoadingTipoDocumentos,
		documento,
		isLoadingDocumento,
		estadoDocumentosPersona,
		isLoadingEstadoDocumentosPersona,
		estadoDocumentosProveedor,
		isLoadingEstadoDocumentosProveedor,
		documentosValidar,
		isLoadingDocumentosValidar,
		documentoValidarCompleto,
		isLoadingDocumentosValidarCompleto,
		documentosValidados,
		documentosRechazados	};
};

export default useDocumentosFetch;