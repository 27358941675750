import { memo, useCallback, useMemo, useState } from "react";
import useDocumentosFetch from "../../hooks/useDocumentosFetch";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import useProveedoresFetch from "../../hooks/useProveedoresFetch";
import usePersonalProveedorFetch from "../../hooks/usePersonalFetch";
import orderBy from 'lodash/orderBy';


const TablaDocumentosPendientesValidar = ({ selected, setSelected }) => {
	const { documentosValidar,documentosRechazados, documentosValidados, tipoDocumentos } = useDocumentosFetch({});
    const { allPersonalProveedor } = usePersonalProveedorFetch({});
    const {proveedores} = useProveedoresFetch();

    const [proveedor, setProveedor] = useState(null);
    const [estado, setEstado] = useState('pendiente');

	const renderFecha = useCallback((row, col, la) => {
		const value = row[col.field] ? new Date(row[col.field]) : null;
		return `${value ? value?.toLocaleDateString('es-ES') : ''}  `;
	}, []);

	const renderSINO = useCallback((row, col) => {
		const value = row[col.field];
		return value ? 'SI' : 'NO';
	}, []);

	const datos = useMemo(
		() => {
			let datosAUsar = documentosValidar;
			if (estado === 'validado'){
				datosAUsar = documentosValidados;
			}
			if (estado === 'denegado'){
				datosAUsar = documentosRechazados;
			}
            let datos1 = datosAUsar?.map((d) => {
							const tipo = tipoDocumentos?.find((t) => t.id === d.tipo);
							let perteneceNombre = '';
							let proveedor = 0;
							let perteneceA = 'PROVEEDOR';
							if (tipo?.pertenece === 'PROVEEDOR') {
								const prov = proveedores?.find((p) => p.id === d.pertenece);
								perteneceNombre = prov?.nombre;
								proveedor = prov?.id;
							} else {
								const persona = allPersonalProveedor?.find((p) => p.id === d.pertenece);
								const prov = proveedores?.find((p) => p.id === persona?.proveedor);
								perteneceNombre = `${persona?.nombre} - ${prov?.nombre}`;
								proveedor = prov?.id;
								perteneceA = 'PERSONAL';
							}
							return { ...d, tipoNombre: tipo?.tipo, perteneceNombre, proveedor, perteneceA };
						});
            datos1= proveedor ? datos1?.filter(d=>d.proveedor===proveedor):datos1;
            return datos1;
        },
		[allPersonalProveedor, documentosRechazados, documentosValidados, documentosValidar, estado, proveedor, proveedores, tipoDocumentos]
	);

    const handleSelection = useCallback(
			(e) => {
				setSelected(e.value);
			},
			[setSelected]
		);

	return (
		<div className='grid p-1'>
			<div className='col-6'>
				Proveedor <br />
				<Dropdown
					value={proveedor}
					onChange={(e) => setProveedor(e.value)}
					options={orderBy(
						proveedores?.map((m) => ({ label: m.nombre, value: m.id, habilitado: m.habilitdo })),
						['habilitado', 'nombre']
					)}
					className=' w-full mb-1  p-0 text-sm'
					panelClassName='text-sm'
					filter
					showClear
				/>
			</div>
			<div className='col-6'>
				Estado <br />
				<Dropdown
					 value={estado}
					 onChange={(e) => setEstado(e.value)}
					options={[
						{ label: 'Pendiente', value: 'pendiente' },
						{ label: 'Validado', value: 'validado' },
						{ label: 'Denegado', value: 'denegado' },
					]}
					className=' w-full mb-1  p-0 text-sm'
					panelClassName='text-sm'
					filter
					showClear
				/>
			</div>
			{/* <div className='col-6'>
				Tipo <br />
				<Dropdown
					// value={tipo}
					// onChange={(e) => setTipo(e.value)}
					// options={tiposGastosPersonal?.map((m) => ({ label: m.nombre, value: m.id }))}
					className=' w-full mb-1  p-0 text-sm'
					panelClassName='text-sm'
					filter
					showClear
				/>
			</div>
			<div className='col-4'>
				Fecha desde <br />
				<Calendar
					className='w-full mb-1  p-0 text-sm'
					//	value={fecha}
					locale='es'
					selectionMode='range'
					readOnlyInput
					hideOnRangeSelection
					//	onChange={(e) => setFecha(e.target.value)}
				/>
			</div> */}
			<div className='col-2'>{/* <Button onClick={exportExcel} icon='pi pi-file-excel' /> */}</div>
			<div className='col-12 p-0'>
				<DataTable
					value={datos}
					stripedRows
					size='small'
					className='text-xs p-1'
					paginator
					rows={10}
					scrollable
					scrollHeight='flex'
					rowsPerPageOptions={[10, 25, 50]}
					paginatorTemplate=' FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown'
					currentPageReportTemplate='{first} de {last} ({totalRecords})'
					selectionMode='single'
					selection={selected}
					onSelectionChange={handleSelection}
				>
					<Column field='tipoNombre' header='Documento'></Column>
					<Column field='perteneceNombre' header='pertenece'></Column>
					<Column field='nombre' header='Nombre'></Column>
					<Column field='fecha' header='Fecha' body={renderFecha}></Column>
					<Column field='validado' header='Validado' body={renderSINO}></Column>
					<Column field='rechazado' header='Rechazado' body={renderSINO}></Column>
				</DataTable>
			</div>
		</div>
	);
};

export default memo(TablaDocumentosPendientesValidar);