import { TabPanel, TabView } from "primereact/tabview";
import { memo, useMemo } from "react";
import ObraInfo from "./selected/ObraInfo";
import ProveedoresObra from "./selected/ProveedoresObra";
import PartesObraObra from "./selected/PartesObraObra";
import useObrasFetch from "../../hooks/useObrasFetch";
import PartesTrabajoObra from "./selected/PartesTrabajoObra";
const ObraSelected = ({obra})=>{
	const {obras}= useObrasFetch({});

	const lastObra = useMemo(()=>( obras?.find(o=>o.id===obra?.id)),[obras, obra])
    
    return (
			<>
				<TabView>
					<TabPanel header={<span className='text-xs'>Información</span>}>
						<ObraInfo obra={lastObra} />
					</TabPanel>
					<TabPanel header={<span className='text-xs'>Proveedores</span>}>
						<ProveedoresObra obra={lastObra} />
					</TabPanel>
					{/* <TabPanel header={<span className='text-xs'>Suministradores</span>}>
						<SuministradoresObra obra={obra} />
					</TabPanel> */}
					<TabPanel header={<span className='text-xs'>Partes Obra </span>}>
						<PartesObraObra obra={lastObra} />
					</TabPanel>
					<TabPanel header={<span className='text-xs'>Partes Trabajo </span>}>
						<PartesTrabajoObra obra={lastObra} />
					</TabPanel>
					{/* <TabPanel header={<span className='text-xs'>Albaranes</span>}></TabPanel>
					<TabPanel header={<span className='text-xs'>Alquileres</span>}></TabPanel> */}
				</TabView>
			</>
		);
}

export default memo(ObraSelected);