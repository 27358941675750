import { InputText } from 'primereact/inputtext';
import { memo, useCallback,  useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {  savePersonalInternoService } from '../../../../services/reactivaService';

const PersonalInternoInfo = ({ persona }) => {
	const [datos, setDatos] = useState({ ...persona});
	const queryClient = useQueryClient();

	const changeAttr = useCallback(
		(attr, val) => {
			const newD = { ...datos };
			newD[attr] = val;
			setDatos(newD);
		},
		[datos]
	);

	const savePersona = useCallback(async () => {
		try {
			const resp = await savePersonalInternoService(datos);
			if (!resp.error) {
				queryClient.invalidateQueries({ queryKey: ['personalInterno'] });
				toast.success('Datos guardados correctamente');
			} else {
				toast.error('Error guardando datos');
			} 
		} catch (e) {
			toast.error('Error guardando datos');
		}
	}, [datos, queryClient]);

	return (
		<div className='grid formgrid'>
			<div className='field mb-1 col-12'>
				<label for='nombre' className='font-medium text-900 text-xs'>
					Nombre
				</label>
				<InputText
					className='p-inputtext w-full mb-1  p-1 text-sm'
					value={datos?.nombre}
					onChange={(e) => changeAttr('nombre', e.target.value)}
				/>
			</div>
			<div className='field mb-1 col-6'>
				<label for='nombre' className='font-medium text-900 text-xs'>
					Dni
				</label>
				<InputText
					className='p-inputtext w-full mb-1  p-1 text-sm'
					value={datos?.dni}
					onChange={(e) => changeAttr('dni', e.target.value)}
				/>
			</div>
			<div className='field mb-1 col-6'>
				<label for='nombre' className='font-medium text-900 text-xs'>
					Código contable
				</label>
				<InputText
					className='p-inputtext w-full mb-1  p-1 text-sm'
					value={datos?.codContableMurano}
					onChange={(e) => changeAttr('codContableMurano', e.target.value)}
				/>
			</div>
			<div className='field mb-1 col-6'>
				<label for='nombre' className='font-medium text-900 text-xs'>
					Activo
				</label>
				<br />
				<Checkbox checked={datos?.habilitado} onChange={(e) => changeAttr('habilitado', e.checked)} />
			</div>

			<div className='col-12 align-content-end'>
				<Button label='Guardar' className='text-sm align-items-end' onClick={savePersona} />
			</div>
		</div>
	);
};

PersonalInternoInfo.propTypes = {
	persona: PropTypes.object,
};
export default memo(PersonalInternoInfo);
