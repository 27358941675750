import RequireAuth from './components/auth/RequireAuth';
import LoginPage from './pages/LoginPage';
import { Route, Routes } from 'react-router';
import InicioPage from './pages/InicioPage';
import ObrasPage from './pages/obras/ObrasPage';
 import { useAuthUser } from './hooks/useAuthUser';
import ProveedoresPage from './pages/ProveedoresPage';
import PersonalObrasPage from './pages/PersonalObrasPage';
import GastosPersonalPage from './pages/desplaza/GastosPersonalPage';
import PersonalInternoPage from './pages/obras/PersonalInternoPage';
import PartesObraPage from './pages/obras/PartesObraPage';
import DocumentosValidar from './pages/documentacion/DocumentosValidar';
import PartesTrabajoPage from './pages/obras/PartesTrabajoPage';

const Main = () => {
	useAuthUser();

	return (
		<Routes>
			<Route exact path='/login' element={<LoginPage />} />
			<Route
				exact
				path='/'
				element={
					<RequireAuth>
						<InicioPage />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/obras'
				element={
					<RequireAuth>
						<ObrasPage />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/proveedores'
				element={
					<RequireAuth>
						<ProveedoresPage />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/proveedores/:id'
				element={
					<RequireAuth>
						<ProveedoresPage />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/personalObras'
				element={
					<RequireAuth>
						<PersonalObrasPage />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/personalObras/:id'
				element={
					<RequireAuth>
						<PersonalObrasPage />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/gastosPersonal'
				element={
					<RequireAuth>
						<GastosPersonalPage />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/personalInterno'
				element={
					<RequireAuth>
						<PersonalInternoPage />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/partesObra'
				element={
					<RequireAuth>
						<PartesObraPage />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/validarDocumentacion'
				element={
					<RequireAuth>
						<DocumentosValidar />
					</RequireAuth>
				}
			/>
			<Route
				exact
				path='/partesTrabajo'
				element={
					<RequireAuth>
						<PartesTrabajoPage />
					</RequireAuth>
				}
			/>
		</Routes>
	);
};

export default Main;
