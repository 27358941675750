import { useQuery } from '@tanstack/react-query';
import { getObrasByUsuario, getAllPersonalInterno, getObrasByPersonalInterno, getAllUsuarios, getAllRoles } from '../services/reactivaService';

const useUsuariosFetch = ({id, idPersonal}) => {
	const { data: personalInterno, isLoading: isLoadingPersonalInterno } = useQuery({
		queryKey: ['personalInterno'],
		queryFn: async () => await getAllPersonalInterno(),
	});

	const { data: usuarios } = useQuery({
		queryKey: ['usuarios'],
		queryFn: async () => await getAllUsuarios(),
	});

	const { data: roles } = useQuery({
		queryKey: ['roles'],
		queryFn: async () => await getAllRoles(),
	});

	const { data: obrasUsuario } = useQuery({
		queryKey: ['obrasUsuario', id],
		queryFn: async () => await getObrasByUsuario(id),
		enabled: !!id,
	});

	const { data: obrasPersonalInterno } = useQuery({
		queryKey: ['obrasPersonalInterno', idPersonal],
		queryFn: async () => await getObrasByPersonalInterno(idPersonal),
		enabled: !!idPersonal,
	});

	return { usuarios, personalInterno, isLoadingPersonalInterno, obrasUsuario, obrasPersonalInterno, roles };
};

export default useUsuariosFetch;
