
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
const AppBreadcrumb = ({ items }) => {    
 
	return (
		<div className='flex lg:px-2 md:px-1 px-1 py-2 ng-star-inserted'>
			<ul className='list-none p-3 m-0 surface-card flex align-items-center font-medium overflow-x-auto w-full'
				style={{borderRadius:'3px'}}>
				<li className='pr-3' data-pc-section='home'>
					<Link
						to='/'
						className='cursor-pointer '
						data-pc-section='action'
						style={{
							borderRadius: 30,
						}}
					>
						<span className='pi pi-home'></span>
					</Link>
				</li>
				{items?.map((it, idx) => (
					<>
						<li class='px-2'>
							<span class='text-400'>/</span>
						</li>
						<li className='px-2 '>
							<Link
								to={it.url}
								className={`cursor-pointer  white-space-nowrap
									 ${idx === items?.length - 1 ? 'text-900' : 'text-cyan-500'}
									 `}
							>
								{it.label}
							</Link>
						</li>
					</>
				))}
			</ul>
		</div>
	);
};

AppBreadcrumb.propTypes = {
	items: PropTypes.array,
};

export default AppBreadcrumb;