import { Button } from "primereact/button";
import { memo, useCallback } from "react";
import PropTypes from 'prop-types';

const FicheroCard = ({fichero, ficheros, setFicheros})=>{

    const borraFichero = useCallback(()=>{
        setFicheros(ficheros?.filter(f=>f.path!== fichero.path))
    },[fichero, ficheros, setFicheros]);

    return (
			<div className='surface-card shadow-2 border-round m-3 col-2'>
				<div className='flex flex-column align-items-center border-bottom-1 surface-border pb-3'>
					<i className='pi pi-file' style={{ fontSize: '2.5rem' }} />
					<span className='text-sm text-900 font-medium mb-2'>{fichero?.path}</span>
				</div>
				<div className='flex pt-3'>
					<div className='w-6 pr-2'>
						<Button icon='pi pi-trash' severity='danger' onClick={borraFichero}/>
					</div>
				</div>
			</div>
		);
};
FicheroCard.propTypes={
    fichero: PropTypes.object,
    setFicheros: PropTypes.func,
    ficheros: PropTypes.array
}
export default memo(FicheroCard);