import { Button } from "primereact/button";
import { useCallback, useMemo, useState } from "react";
import DialogoPersonal from "./DialogoPersonal";

const NuevoPersonalButton = ({proveedor})=>{
    const [abre, setAbre]= useState(null);

    const abreDialog = useCallback(()=>{
        setAbre(true);
    },[]);

     const cierraDialog = useCallback(() => {
				setAbre(null);
	}, []);

    const persona=useMemo(()=>({proveedor}),[proveedor]);
    
    return (
			<>
				<Button label='Nueva persona' icon='pi pi-plus' onClick={abreDialog} outlined />
				{abre && <DialogoPersonal personal={persona} onClose={cierraDialog} />}
			</>
		);
};

export default NuevoPersonalButton;