import PropTypes from 'prop-types';

import { memo, useCallback, useMemo } from 'react';
import useGastoPersonalFetch from '../../../../hooks/useGastoPersonalFetch';
import useObrasFetch from '../../../../hooks/useObrasFetch';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chip } from 'primereact/chip';

const GastosPersonalInterno = ({ persona }) => {
	const { gastosPersonalInterno, tiposGastosPersonal } = useGastoPersonalFetch({ persona: persona?.id });
    const {obras} = useObrasFetch({});
    const datos = useMemo(
		() =>
			gastosPersonalInterno
				?.map((po) => ({
					...po,
                    fecha: new Date(po.fecha),
					obraNombre: obras?.find((p) => p.id === po.obra)?.nombre,
					tipoGastoNombre: tiposGastosPersonal?.find((p) => p.tipo === po.tipo)?.nombre,
				})),
		[gastosPersonalInterno, obras, tiposGastosPersonal]);

    const renderFechaHora = useCallback((row, col) => {
			const fecha = row[col.field];
			if (fecha) {
				return `${fecha.toLocaleDateString()}`;
			}
			return '---';
		}, []);

	const renderEstado = useCallback((row, col) => {
		let clase = '';
		switch (row[col.field]) {
			case 'denegado':
				clase = 'bg-red-100';
				break;
			case 'pendiente':
				clase = 'bg-yellow-100';
				break;
			case 'validado':
				clase = 'bg-green-100';
				break;
			default:
		}
		return <Chip label={row[col.field]} className={clase} />;
	}, []);

	return (
		<div className='col-12 p-0 '>
			<DataTable
				value={datos}
				size='small'
				className='text-xs p-1'
				paginator
				rows={10}
				scrollable
				scrollHeight='flex'
				stripedRows
				rowsPerPageOptions={[5, 10, 25, 50]}
				paginatorTemplate=' FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown'
				currentPageReportTemplate='{first} de {last} ({totalRecords})'
				
			>
				<Column field='fecha' header='Fecha' body={renderFechaHora}></Column>
				<Column field='obraNombre' header='Obra'></Column>
				<Column field='tipoGastoNombre' header='Tipo'></Column>
				<Column field='cantidad' header='Cantidad'></Column>
				<Column field='estado' header='Estado' body={renderEstado}  />
			</DataTable>
		</div>
	);
};

GastosPersonalInterno.propTypes = {
	persona: PropTypes.object,
};
export default memo(GastosPersonalInterno);
