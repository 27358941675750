import { Dialog } from "primereact/dialog";
import { memo, useCallback, useEffect, useState } from "react";
import useDocumentosFetch from "../../hooks/useDocumentosFetch";
import PropTypes from 'prop-types';
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import PdfViewer from "./PdfViewer";

import { Image } from 'primereact/image';

const DialogPreviewDocumento= ({fichero,  setOpened})=>{
    const { documento, isLoadingDocumento } = useDocumentosFetch({ id: fichero });

    const onClose= useCallback(()=>{
        setOpened(false);
    },[setOpened]);

	const [isPdf, setIsPdf] = useState(false);
	const [rotate, setRotate]=useState(0);

	useEffect(()=>{
		setIsPdf(false)
	},[fichero]);

	const errorOnImage = useCallback(()=>{
		setIsPdf(true);
	},[]);

	const rotar = useCallback(() => {
		setRotate(rotate+90);
	}, [rotate]);

		

    return (
			<Dialog
				header='Añadir documentos'
				visible={fichero}
				style={{ width: '80vw', minHeight: '80vh' }}
				onHide={onClose}

				//footer={footerContent}
			>
				<div className='col-12 p-2 ' style={{ height: '75vh' }}>
					{isLoadingDocumento && <ProgressSpinner />}
					{isPdf && documento?.documentoBase64 && !isLoadingDocumento && (
						// <embed
						// 	src={`data:application/pdf;base64,${documento?.documentoBase64}`}
						// 	type='application/pdf'
						// 	height='100%'
						// 	width='100%'
						// ></embed>
						<PdfViewer base64={`data:application/pdf;base64,${documento?.documentoBase64}`} />
					)}
					{!isPdf && !isLoadingDocumento && (
						<>
							<Button onClick={rotar} icon='pi pi-refresh' style={{marginLeft: '50%'}}/> <br />
							<Image
								src={`data:image/jpeg;base64,${documento?.documentoBase64}`}
								alt='Image'
								width='100%'
								style={{ transform: `rotate(${rotate}deg)` , marginLeft: '25%'}}
								onError={errorOnImage}
								preview
							/>
						</>
					)}
					{!isLoadingDocumento && !documento?.documentoBase64 && <Message text='Sin imagen' className='w-full' />}
				</div>
			</Dialog>
		);
}

DialogPreviewDocumento.propTypes = {
	fichero: PropTypes.object,
	setOpened: PropTypes.func,
};

export default memo(DialogPreviewDocumento);