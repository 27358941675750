import PropTypes from 'prop-types';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { memo, useCallback, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import useObrasFetch from '../../../hooks/useObrasFetch';
import DialogoObras from '../../obras/DialogoObras';
 import { toast } from 'react-toastify';
 import { asignaProveedorObraService } from '../../../services/reactivaService';
import { useQueryClient } from '@tanstack/react-query';

const ObrasProveedor = ({proveedor})=>{
    const { obrasProveedor, obras } = useObrasFetch({ proveedor: proveedor });
     const [openProveedor, setOpenProveedor] = useState(null);

     const queryClient= useQueryClient();

    const datos = useMemo(
			() =>
				obrasProveedor?.map((p) => {
					const prov = obras?.find((pp) => pp.id === p.obra);
					return prov;
				}),
			[obras, obrasProveedor]
		);

    const guardaProveedor = useCallback(async (obra, p, asignar) => {
        const response = await asignaProveedorObraService(obra.id, p.id, asignar);
        if (!response.error) {
            toast.success(`${p.nombre} ${asignar ? 'asignado': 'eliminado'} correctamente`);
        } else {
            toast.error(`${asignar ? 'Asignando' : 'Eliminando'} ${p.nombre}  error.`);
        }
           queryClient.invalidateQueries({ queryKey: ['obrasProveedor', proveedor.id] });    
     
    },[proveedor.id, queryClient]);

    const borraProveedorObra= useCallback(async o=>{
       guardaProveedor(o, proveedor, false);
    },[guardaProveedor, proveedor]);
    
    const guardaObras = useCallback(async (proveedores)=>{
        proveedores?.forEach(o=>{
           guardaProveedor(o, proveedor, true);
        });    
        setOpenProveedor(false);
    },[guardaProveedor, proveedor]);

    const renderBorrar = useCallback(
			(rowData) => <Button icon='pi pi-trash' onClick={() => borraProveedorObra(rowData)} severity='danger' />,
			[borraProveedorObra]
		);

    const abreDialogoProveedorObra = useCallback(()=>{
        setOpenProveedor(true);
    },[]);

     const cierraDialogoProvObra = useCallback(() => {
				setOpenProveedor(false);
			}, []);

    const seleccionados = useMemo(() => obrasProveedor?.map((p) => p.obra), [obrasProveedor]);

	const calcHeight = useMemo(() => document.body.clientHeight - 250, []);


    return (
			<div className='col-12 p-0 '>
				<Button
					icon='pi pi-plus'
					onClick={abreDialogoProveedorObra}
					label='Asignar obras'
					size='small'
					className='mb-2'
					outlined
				/>
				<DataTable
					value={datos}
					tableStyle={{ minWidth: '50rem' }}
					size='small'
					stripedRows
					className='text-sm'
					scrollHeight={calcHeight}
				>
					<Column field='nombre' header='Proveedor'></Column>
					{/* <Column field='cif' header='Cif'></Column> */}
					<Column body={renderBorrar}></Column>
				</DataTable>
				{openProveedor && (
					<DialogoObras seleccionados={seleccionados} onClose={cierraDialogoProvObra} onSave={guardaObras} />
				)}
			</div>
		);
};

ObrasProveedor.propTypes={
    proveedor: PropTypes.object
}

export default memo(ObrasProveedor);