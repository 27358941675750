import { Checkbox } from "primereact/checkbox";
import { memo, useMemo, useState } from "react";
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import usePersonalFetch from "../../hooks/usePersonalFetch";
import useProveedoresFetch from "../../hooks/useProveedoresFetch";
import PersonaCard from "./PersonaCard";
import NuevoPersonalButton from "./NuevoPersonalButton";
import { DebounceInput } from 'react-debounce-input';

const PersonalObrasList = ({ selected, setSelected }) => {
	const [searchText, setSearchText] = useState(null);
	const [activas, setActivas] = useState(true);

	const { allPersonalProveedor: personal } = usePersonalFetch({});
	const { proveedores } = useProveedoresFetch({});

	const personalConProv = useMemo(
		() =>
			personal
				?.filter((o) => (activas ? o.habilitado : true))
				?.map((o) => ({
					...o,
					proveedorNombre: proveedores?.find((m) => m.id === o.proveedor)?.nombre,
					proveedorHabilitado: proveedores?.find((m) => m.id === o.proveedor)?.habilitado,
				})),
		[activas, personal, proveedores]
	);
	
	const finalObras = useMemo(() => {
		const sT= searchText?.toLowerCase();
		const f = personalConProv?.filter((o) =>
			sT
				? o.nombre?.toLowerCase()?.includes(sT?.toLowerCase()) ||
			//	  o.proveedorNombre?.toLowerCase().includes(sT?.toLowerCase()) ||
				  o.dni?.toLowerCase()?.includes(sT?.toLowerCase())
				: true
		);
		const res = orderBy(f, ['nombre'], 'asc');
		return res;
	}, [personalConProv, searchText]);

	return (
		<>
			<h2 className='mt-1 mb-1'> Personal</h2>

			<div className='grid'>
				<div className='col-12 p-1'>
					<NuevoPersonalButton />
					<DebounceInput
						type='text'
						debounceTimeout={500}
						value={searchText}
						className='p-inputtext p-component p-1 w-full mt-2'
						onChange={(e) => setSearchText(e.target.value)}
					/>
				</div>
				<div className='col-12 p-1'>
					<Checkbox
						checked={activas}
						onChange={(e) => {
							setActivas(e.checked);
						}}
					/>{' '}
					Solo activos
				</div>
			</div>
			<hr />
			<div className='grid'>
				{finalObras?.map((o) => (
					<PersonaCard key={o.id} persona={o} selected={selected} setSelected={setSelected} />
				))}
			</div>
		</>
	);
};

PersonalObrasList.propTypes = {
	selected: PropTypes.object,
	setSelected: PropTypes.func,
};

export default memo(PersonalObrasList);
