import { InputText } from "primereact/inputtext";
import { memo, useCallback, useMemo, useState } from "react";
import PropTypes from 'prop-types';

import { Calendar } from 'primereact/calendar';
import { calculaDias } from "../../../services/commonHelpers";
import { Button } from "primereact/button";
import useObrasFetch from "../../../hooks/useObrasFetch";
import { Dropdown } from "primereact/dropdown";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import { saveObraService } from "../../../services/reactivaService";

const ObraInfo=({obra})=>{
    const [datos, setDatos]= useState({...obra});
    const {marcas}= useObrasFetch({});
	const queryClient = useQueryClient();

    const changeAttr = useCallback((attr, val)=>{
        const newD={...datos};
        newD[attr]=val;
        setDatos(newD);
    },[datos]);

    const duracion = useMemo(()=>{
        if (datos.fechaInicio && datos.fechaFin){
             return calculaDias(datos.fechaFin, datos.fechaInicio);
        }
        return calculaDias(new Date(), datos.fechaInicio);
    },[datos.fechaFin, datos.fechaInicio]);

	const saveObra = useCallback(async ()=>{
		try{
			const resp = await saveObraService(datos);
			if (!resp.error){
				queryClient.invalidateQueries({ queryKey: ['obras'] });
				toast.success('Datos guardados correctamente');
			}else{
				toast.error('Error guardando datos');
			}
		}catch(e){
			toast.error('Error guardando datos');
		}
	},[datos, queryClient]);	

    return (
			<div className='grid formgrid'>
				<div className='field mb-1 col-6'>
					<label for='nombre' className='font-medium text-900 text-xs'>
						Nombre
					</label>
					<InputText
						className='p-inputtext w-full mb-1  p-1 text-sm'
						value={datos?.nombre}
						disabled
						onChange={(e) => changeAttr('nombre', e.target.value)}
					/>
				</div>
				<div className='field mb-1 col-6'>
					<label for='nombre' className='font-medium text-900 text-xs'>
						Marca
					</label>
					<Dropdown
						value={datos?.marca}
						onChange={e=>changeAttr('marca', e.value)}
						options={marcas?.map((m) => ({ label: m.nombre, value: m.id }))}
						className=' w-full mb-1  p-1 text-sm'
					/>
				</div>
				<div className='field mb-1 col-12'>
					<label for='nombre' className='font-medium text-900 text-xs'>
						Dirección
					</label>
					<InputText
						className='p-inputtext w-full mb-1  p-1 text-sm'
						value={datos?.direccion}
						onChange={(e) => changeAttr('direccion', e.target.value)}
					/>
				</div>
				<div className='field mb-1 col-6'>
					<label for='nombre' className='font-medium text-900 text-xs'>
						Inicio obra
					</label>
					<Calendar
						className='w-full mb-1  p-1 text-sm'
						value={datos?.fechaInicio}
						locale='es'
						onChange={(e) => changeAttr('fechaInicio', e.target.value)}
					/>
				</div>
				<div className='field mb-1 col-6'>
					<label for='nombre' className='font-medium text-900  text-xs'>
						Final obra
					</label>
					<Calendar
						className=' mb-1 w-full  p-1 text-sm'
						value={datos?.fechaFin}
						locale='es'
						clearButton
						onChange={(e) => changeAttr('fechaFin', e.target.value)}
					/>
				</div>
				<div className='field mb-1 col-6'>
					<label for='nombre' className='font-medium text-900 text-xs'>
						Codigo Murano
					</label>
					<InputText className='p-inputtext w-full mb-1 p-1 text-xs' value={datos?.codMurano} disabled />
				</div>
				<div className='field mb-1 col-6'>
					<label for='nombre' className='font-medium text-900 text-xs'>
						Duración en días
					</label>
					<InputText className='p-inputtext w-full mb-1 p-1 text-sm' value={duracion} disabled />
				</div>
				<div className='col-12 align-content-end'>
					<Button label='Guardar' className='text-sm align-items-end' onClick={saveObra} />
				</div>
			</div>
		);
    };


ObraInfo.propTypes={
obra: PropTypes.object
}
export default memo(ObraInfo);