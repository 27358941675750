import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';
import useProveedoresFetch from '../../hooks/useProveedoresFetch';
import { memo, useCallback, useMemo, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { toast } from 'react-toastify';

const DialogoProveedores = ({seleccionados, onClose, onSave})=>{
    const {  proveedores } = useProveedoresFetch();
    const [searchT, setSearchT] = useState(null);
    const [activos, setActivos] = useState(true);
    const [misSeleccionados, setMisSeleccionados] = useState([]);

    const guardarProveedores = useCallback(()=>{
        if (onSave){
            onSave(misSeleccionados);
        }
    },[misSeleccionados, onSave]);
    
    const footerContent = (
			<div>
				<Button label='Cancelar' icon='pi pi-times' onClick={onClose} className='p-button-text' />
				<Button label='Guardar' icon='pi pi-check' onClick={guardarProveedores} autoFocus />
			</div>
		);

    const proveedoresFiltered = useMemo(() => (
                proveedores?.filter((p) => !seleccionados?.includes(p.id))
                ?.filter(p=>searchT ? p.nombre.toLowerCase().includes(searchT?.toLowerCase()): true)
                ?.filter(p=> activos? p.habilitado: true)
    ),	[proveedores, searchT, seleccionados, activos]);

    const seleccionarProveedor = useCallback(p=>{
        if (p.habilitado){
            if (misSeleccionados?.includes(p)) {
                setMisSeleccionados(misSeleccionados?.filter((m) => m.id !== p.id));
            } else {
                setMisSeleccionados([...misSeleccionados, p]);
            }
        }else{
            toast.info('No se pueden seleccionar proveedores inactivos.')
        }
    },[misSeleccionados]);

    return (
			<Dialog
				header='Seleccione proveedor'
				visible={true}
				style={{ width: '50vw', minHeight: '80vh' }}
				onHide={onClose}
				footer={footerContent}
			>
				<InputText className='text-sm w-80' value={searchT} onChange={(e) => setSearchT(e.target.value)} />
				<Checkbox checked={activos} onChange={(e) => setActivos(e.checked)} className='mb-2' /> Ver solo activos
				<ul style={{ maxHeight: '70vh' }} className='list-none p-0 m-0'>
					{proveedoresFiltered?.map((p) => (
						<li key={p.id} className='pb-1 border-bottom-1 surface-border'>
							<div
								className={`border-2 border-dashed surface-border border-round surface-0 p-1
                                ${!p.habilitado ? 'surface-100 line-through' : ''}
                                ${misSeleccionados?.includes(p) ? 'bg-green-200' : ''}
                            `}
								onClick={() => seleccionarProveedor(p)}
							>
								{p.nombre}
							</div>
						</li>
					))}
				</ul>
			</Dialog>
		);
}

DialogoProveedores.propTypes={
    seleccionados: PropTypes.array,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    multi: PropTypes.bool
}
export default memo(DialogoProveedores);
