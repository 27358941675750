import PropTypes from 'prop-types';
import { memo, useMemo, useRef } from 'react';
import { Menu } from 'primereact/menu';
import {Button} from 'primereact/button';
import { useLocation, useNavigate } from 'react-router';

const MenuItem = ({item})=>{
    const hasSubmenu = useMemo(() => item?.subitems?.length, [item?.subitems?.length]);
    const submenu = useRef();
	// selected bg-indigo-700
	
	const location = useLocation();
	const navigate = useNavigate();

	const selected = useMemo(() => (location?.pathname===item?.url
		|| item?.subitems?.find(i=>i.url === location?.pathname)
	),[item?.subitems, item?.url, location?.pathname]);

	return (
		<li className='mb-2'>
			{!hasSubmenu && (
				<Button
					tooltip={item?.label}
					icon={item?.icon}
					className={`mr-2 ${selected ? 'bg-indigo-700' : ''}`}
					onClick={() => {
						navigate(item.url);
					}}
				/>
			)}
			{hasSubmenu && (
				<>
					<Menu model={item?.subitems} popup ref={submenu} id='popup_menu_left' />
					<Button
						tooltip={item?.label}
						icon={item?.icon}
						className={`mr-2 ${selected ? 'bg-indigo-700' : ''}`}
						onClick={(event) => submenu.current.toggle(event)}
						aria-controls='popup_menu_left'
						aria-haspopup
					/>
				</>
			)}
		</li>
	);
};

MenuItem.propTypes={
    item: PropTypes.object
}

export default memo(MenuItem);