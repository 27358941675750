import { InputText } from "primereact/inputtext";
import { memo, useCallback,  useEffect,  useState } from "react";
import PropTypes from 'prop-types';

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { generarPassService, saveProveedorService } from "../../../services/reactivaService";
import { Dropdown } from "primereact/dropdown";

const ProveedorInfo=({proveedor})=>{
    const [datos, setDatos]= useState();
	const queryClient = useQueryClient();

	useEffect(()=>{
		setDatos({...proveedor});
	},[proveedor]);

    const changeAttr = useCallback((attr, val)=>{
		if (attr === 'alertaDocumentacion' && val && !datos.email && datos.userPassword) {
			toast.error('Para activar alertas de documentación, debe tener email configurado y generarle una contraseña.');
		}else{
			const newD={...datos};
			newD[attr]=val;
			setDatos(newD);
		}
    },[datos]);

    
	const saveProveedor = useCallback(async ()=>{
		if (datos.alertaDocumentacion && !datos.email){
			toast.error('Para activar alertas de documentación, debe tener email configurado y generarle una contraseña.');
		}else{
			try{
				const resp = await saveProveedorService(datos);
				if (!resp.error){
					queryClient.invalidateQueries({ queryKey: ['proveedores'] });
					toast.success('Datos guardados correctamente');
				}else{
					toast.error('Error guardando datos');
				}
			}catch(e){
				toast.error('Error guardando datos');
			}
		}
	},[datos, queryClient]);	

	const generarPass = useCallback(async ()=>{
		if (!datos?.email){
			toast.error('El proveedor debe tener e-mail asignado.');
		}else{
			const resp = await generarPassService(datos);
			if (!resp.error){
				queryClient.invalidateQueries({ queryKey: ['proveedores'] });
				toast.success(resp.mensaje);
			}else{
				toast.error(resp.mensaje);
			}
		}
	},[datos, queryClient]);

    return (
			<div className='grid formgrid'>
				<div className='field mb-1 col-6'>
					<label for='nombre' className='font-medium text-900 text-xs'>
						Nombre
					</label>
					<InputText
						className='p-inputtext w-full mb-1  p-1 text-sm'
						value={datos?.nombre}
						disabled
						onChange={(e) => changeAttr('nombre', e.target.value)}
					/>
				</div>

				<div className='field mb-1 col-12'>
					<label for='nombre' className='font-medium text-900 text-xs'>
						Dirección
					</label>
					<InputText
						className='p-inputtext w-full mb-1  p-1 text-sm'
						value={datos?.direccion}
						onChange={(e) => changeAttr('direccion', e.target.value)}
					/>
				</div>
				<div className='field mb-1 col-6'>
					<label for='nombre' className='font-medium text-900 text-xs'>
						CIF
					</label>
					<InputText
						className='p-inputtext w-full mb-1  p-1 text-sm'
						value={datos?.cif}
						disabled
						onChange={(e) => changeAttr('cif', e.target.value)}
					/>
				</div>
				<div className='field mb-1 col-6'>
					<label for='nombre' className='font-medium text-900 text-xs'>
						E-mail
					</label>
					<InputText
						className='p-inputtext w-full mb-1  p-1 text-sm'
						value={datos?.email}
						onChange={(e) => changeAttr('email', e.target.value)}
					/>
				</div>
				<div className='field mb-1 col-6'>
					<label for='nombre' className='font-medium text-900 text-xs'>
						Telefono 1
					</label>
					<InputText
						className='p-inputtext w-full mb-1  p-1 text-sm'
						value={datos?.telefono}
						onChange={(e) => changeAttr('telefono', e.target.value)}
					/>
				</div>
				<div className='field mb-1 col-6'></div>
				<div className='field mb-1 col-6'>
					<label for='nombre' className='font-medium text-900 text-xs'>
						UserName
					</label>
					<InputText className='p-inputtext w-full mb-1  p-1 text-sm' value={datos?.cif} disabled />
				</div>
				<div className='field mb-1 col-6'>
					<label for='nombre' className='font-medium text-900 text-xs'>
						Password
					</label>
					<br />
					<Button label='Generar contraseña' className='p-1' icon='pi pi-refresh' outlined onClick={generarPass} />
				</div>
				<div className='field mb-1 col-6'>
					<label for='nombre' className='font-medium text-900 text-xs'>
						Código Murano
					</label>
					<InputText
						className='p-inputtext w-full mb-1  p-1 text-sm'
						value={datos?.codMurano}
						disabled
						onChange={(e) => changeAttr('codMurano', e.target.value)}
					/>
				</div>
				<div className='field mb-1 col-1'>
					<label for='nombre' className='font-medium text-900 text-xs'>
						Activo
					</label>
					<br />
					<Checkbox checked={datos?.habilitado} onChange={(e) => changeAttr('habilitado', e.checked)} />
				</div>
				<div className='field mb-1 col-3'>
					<label for='nombre' className='font-medium text-900 text-xs'>
						Tipo
					</label>
					<br />
					<Dropdown
						value={datos?.tipo}
						onChange={(e) => changeAttr('tipo', e.value)}
						options={[{
							label:'Empresa',
							value:'EMPRESA'
						},{
							label:'Autonomo',
							value: 'AUTONOMO'
						},{
							label:'Autonomo con trabajadores',
							value: 'AUTONOMO_TRABAJADORES'
						},{
							label: 'Autonomo con autonomos',
							value: 'AUTONOMO_AUTONOMOS'
						}]}
						className=' w-full mb-1  p-1 text-sm'
					/>
				</div>
				<div className='field mb-1 col-2'>
					<label for='nombre' className='font-medium text-900 text-xs'>
						Alerta Documentación
					</label>
					<br />
					<Checkbox
						checked={datos?.alertaDocumentacion}
						onChange={(e) => changeAttr('alertaDocumentacion', e.checked)}
					/>
				</div>
				<div className='field mb-1 col-12'>
					<label for='nombre' className='font-medium text-900 text-xs'>
						Observaciones
					</label>
					<InputText
						className='p-inputtext w-full mb-1  p-1 text-sm'
						value={datos?.observaciones}
						onChange={(e) => changeAttr('observaciones', e.target.value)}
					/>
				</div>
				<div className='col-12 align-content-end'>
					<Button label='Guardar' className='text-sm align-items-end' onClick={saveProveedor} />
				</div>
			</div>
		);
    };


ProveedorInfo.propTypes = {
	obra: PropTypes.object,
};
export default memo(ProveedorInfo);